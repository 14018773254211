import React from "react";
import BenefitSubscribeButtonsContainer from "./BenefitSubscribeButtons/BenefitSubscribeButtonsContainer";
import { isEmpty } from "../../../../services/general.utils";
import { useTranslation } from "react-i18next";
import {
  transformPriceRangeTokensToPriceRangeRsd,
  transformTokenCurrencyIcons
} from "../../../../utils/transformTokensToRsd";

export const BenefitSubscribeItem = props => {
  const {
    benefit,
    getBenefitPrice,
    getVoucherPrices,
    renderBenefitStatus,
    benefitGroup,
    getClassNameForBenefitCard,
    user,
    setShowPDFBill,
    doesDescriptionExist,
    ReactHtmlParser,
    isPreview,
    setIsLoading,
    setVoucherReimbursementData
  } = props;

  const { t } = useTranslation();

  return (
    <div className={getClassNameForBenefitCard(benefit)}>
      {
        <div className="benefitStatusWrap">
          {renderBenefitStatus(
            benefitGroup.status,
            benefitGroup.statusPreview,
            benefit.shouldBeIndicatedAsActive,
            benefit.isSubscribed
          )}
          {user.companyVatEnabled && !isEmpty(benefit.vat) && (
            <div className="vatDisclaimer">
              {t("Price_includes_VAT")} {benefit.vat}%.
            </div>
          )}
        </div>
      }
      <div className="labelValues">
        <p className="benefitName">{benefit.title}</p>
        {!isEmpty(benefit.quantity) &&
          !isEmpty(benefit.totalQuantityRemaining) &&
          !benefit.isSubscribed && (
            <div className="remainingQuantity">
              {benefit.totalQuantityRemaining} {t("remaining")}
            </div>
          )}
        {!isEmpty(benefit.quantity) &&
          isEmpty(benefit.totalQuantityRemaining) &&
          !benefit.isSubscribed && (
            <div className="remainingQuantity">
              {benefit.quantity} {t("remaining")}
            </div>
          )}
      </div>
      {doesDescriptionExist(benefit.description) ? (
        <div className="benefitDescription">
          {ReactHtmlParser(benefit.description)}
        </div>
      ) : (
        <p className="benefitSummary">{benefit.summary}</p>
      )}
      <div className="benefitExpirationAndTokens">
        <p className="duration">{t(benefit.expiration)}</p>
        <div className="tokenContainer">
          {transformTokenCurrencyIcons()}
          <p className="tokens">
            {benefit.expiration === "voucher"
              ? transformPriceRangeTokensToPriceRangeRsd(
                  getVoucherPrices(benefit)
                )
              : transformPriceRangeTokensToPriceRangeRsd(
                  getBenefitPrice(benefit)
                )}
          </p>
        </div>
      </div>
      <BenefitSubscribeButtonsContainer
        benefit={benefit}
        status={benefitGroup.status}
        benefitGroupType={benefitGroup.type}
        isPreview={isPreview}
        setIsLoading={setIsLoading}
        enableInstalments={benefitGroup.enableInstalments}
        setShowPDFBill={setShowPDFBill}
        setVoucherReimbursementData={setVoucherReimbursementData}
        benefitGroup={benefitGroup}
      />
    </div>
  );
};
