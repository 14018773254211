import React from "react";
import { Header } from "../../../components";
import {
  AcceptedIconSVG,
  EmployeesIcon,
  PendingIconSVG,
  RejectedIconsSVG
} from "../../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { apiService } from "../../../services/api/api.service";
import { LoadingHistoryItems } from "../../UserPages/HistoryList/LoadingHistoryItems";
import { EmptyRefunds } from "./EmptyRefunds";
import {
  setBranchCodes,
  setCheckedPendingItems,
  setRefund,
  setRefunds,
  setToggleReimbursement,
  setTotalCount
} from "../../../actions/hr/refunds.actions";
import RefundStyles from "./RefundStyles";
import { useCallback } from "react";
import { ShowRefundModal } from "./RefundModal/ShowRefundModal";
import { RefundPagination } from "./RefundPagination";
import { toggleSidebarMenu } from "../../../actions/hr/employees.actions";
import { RefundListIndex } from "./RefundsList/RefundListIndex";
import bucketsSwap from "../../../utils/supabaseUtils/BucketsSwap/bucketsSwap";
import { queryBuilder } from "../../../utils/queryBuilder";
import { getCompanyBranches } from "../../../services/api/admin/users.service";
import { RefundListSecondLayer } from "./RefundsList/Desktop/RefundListSecondLayer";
import { ListOfRefundsDesktopHeader } from "./RefundsList/Desktop/ListOfRefundsDesktopHeader";
import { RefundMobileHeader } from "./RefundsList/Mobile/RefundMobileHeader/RefundMobileHeader";
import { useTranslation } from "react-i18next";

const Refunds = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    filters,
    singleRefund,
    listOfRefunds,
    checkedPendingItems
  } = useSelector(state => state.refundsReducer);

  const { isSidebarMenuOpen } = useSelector(state => state.employees);
  const { companyId } = useSelector(state => state.app.user);

  const fetchRefunds = async filter => {
    try {
      setLoading(true);

      const query = queryBuilder({
        baseQuery: "/refunds",
        queryObject: {
          limit: filter.perPage,
          page: filter.page,
          status: filter.status,
          branchCode: filter.branchCode
        }
      });

      const refundsResponse = await apiService.get(query);

      dispatch(setRefunds(refundsResponse.data?.refunds || []));
      dispatch(setTotalCount(refundsResponse.data?.count || 1));
    } catch (error) {
      toast.error(t("failed_to_get_refund_list"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRefunds(filters);
  }, [filters]);

  const getAllBranchesInCompany = async () => {
    const listOfCompanyBranchCodes = await getCompanyBranches(companyId);

    dispatch(setBranchCodes(listOfCompanyBranchCodes.branches));
  };

  useEffect(() => {
    getAllBranchesInCompany();

    return () => {
      dispatch(setCheckedPendingItems([]));
    };
  }, []);

  const handleStatus = useCallback(status => {
    const statusObject = {
      null: <PendingIconSVG />,
      true: <AcceptedIconSVG />,
      false: <RejectedIconsSVG />
    };
    return statusObject[status];
  }, []);

  const handleRefundOpen = useCallback(refundItem => {
    dispatch(setRefund(refundItem));
  }, []);

  const handleCheckedOnChange = useCallback(
    refundId => {
      let payload = [];

      if (checkedPendingItems.includes(refundId)) {
        payload = checkedPendingItems.filter(id => id !== refundId);
      } else {
        payload = [...checkedPendingItems, refundId];
      }

      dispatch(setCheckedPendingItems(payload));
    },
    [checkedPendingItems]
  );

  const handleCheckAll = useCallback(() => {
    let payload = listOfRefunds
      .filter(refund => refund.status === null)
      .map(x => x.id);

    if (checkedPendingItems.length > 0) {
      payload = [];
    }

    dispatch(setCheckedPendingItems(payload));
  }, [listOfRefunds, checkedPendingItems]);

  const handleBulkAcceptOrDecline = useCallback(
    async acceptOrDecline => {
      try {
        const response = await apiService.post(`/refunds/bulk`, {
          description: "",
          ids: checkedPendingItems,
          status: acceptOrDecline
        });

        toast.success(t(response.data.message));

        for (const refund of response.data.refunds) {
          await bucketsSwap({
            oldStatus: null,
            status: refund.status,
            fileName: refund.File.name,
            companyId: companyId
          });
        }

        fetchRefunds(filters);
        dispatch(setCheckedPendingItems([]));
      } catch (error) {
        toast.error(
          `Failed to get bulk ${acceptOrDecline ? "accept" : "reject"} refunds.`
        );
      }
    },
    [checkedPendingItems]
  );

  const refundListSecondLayerProps = {
    handleCheckAll,
    checkedPendingItems,
    handleBulkAcceptOrDecline
  };

  const ShownContent = () => {
    if (loading) {
      return <LoadingHistoryItems isLoading={loading} />;
    }

    return (
      <>
        {listOfRefunds.length === 0 && (
          <RefundMobileHeader handleStatus={handleStatus} />
        )}
        <RefundListSecondLayer {...refundListSecondLayerProps} />
        <ListOfRefundsDesktopHeader handleStatus={handleStatus} />
        {listOfRefunds.length > 0 ? (
          <RefundListIndex
            handleRefundOpen={handleRefundOpen}
            handleStatus={handleStatus}
            listOfRefunds={listOfRefunds}
            handleCheckedOnChange={handleCheckedOnChange}
            checkedPendingItems={checkedPendingItems}
            handleCheckAll={handleCheckAll}
            handleBulkAcceptOrDecline={handleBulkAcceptOrDecline}
          />
        ) : (
          <EmptyRefunds />
        )}
      </>
    );
  };

  const handleHamburger = () => {
    dispatch(toggleSidebarMenu(!isSidebarMenuOpen));
  };

  return (
    <RefundStyles>
      <div className="refundContainer">
        <ShowRefundModal
          handleStatus={handleStatus}
          singleRefund={singleRefund}
        />
        <Header
          headerTitle={t("Refunds")}
          toggleSidebarMenu={handleHamburger}
        />
        <div className="refundContentWrapper">
          <section className="refundContent">{ShownContent()}</section>
          <RefundPagination filters={filters} />
        </div>
      </div>
    </RefundStyles>
  );
};

export default Refunds;
