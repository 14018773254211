import i18next from "i18next";
import { toast } from "react-toastify";

export const handleCompanyThemeFormError = ({ error }) => {
  const { data } = error;
  if (data.errors) {
    if (data.errors.photo) {
      toast.error(`${i18next.t(data.errors.photo.keys[0])}`);
    }
  } else {
    toast.error(i18next.t(data.error.message));
  }
};
