import React, { useContext } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";

import Form from "./Form";

import { CompanyTag } from "../../../../../../modules/superAdmin/settings/settings.entity";
import { companyTagValidations } from "../../../../../../services/validations/superAdmin/companyTag.validations";
import * as companyTagsService from "../../../../../../services/api/admin/companyTags.service";

import * as actionCreators from "../../../../../../actions/admin/companyTags.actions";
import { useTranslation } from "react-i18next";

const AddCompanyTagStyles = styled.div`
  padding: 20px;
  width: 400px;

  .title {
    margin-bottom: 20px;
  }
`;

const AddCompanyTag = ({ closeModal, addCompanyTag }) => {
  const { t } = useTranslation();

  const handleCreateBenefitTagError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${t(data.error.message)}: ${t(data.errors.name.keys[0])}`);
      }
    }
  };

  const createCompanyTag = async values => {
    const response = await companyTagsService.addCompanyTag(values);
    if (response.hasError) {
      return handleCreateBenefitTagError(response);
    }

    addCompanyTag(response);
    toast.success(t("success_add_benefit_category"));
    closeModal();
  };

  const formik = useFormik({
    initialValues: new CompanyTag(),
    validate: companyTagValidations,
    onSubmit: createCompanyTag
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddCompanyTagStyles>
      <h2 className="title">{t("Add Company tag")}</h2>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Form
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          buttonText="Create"
        />
      </form>
    </AddCompanyTagStyles>
  );
};

AddCompanyTag.propTypes = {
  closeModal: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    addCompanyTag: companyTags =>
      dispatch(actionCreators.addCompanyTag(companyTags))
  };
};

export default connect(undefined, mapDispatchToProps)(AddCompanyTag);
