const ENG = {
  /* AUTH */
  LWE: "Log in with email",
  LWM: "Log in with Microsoft",
  Password: "Password",
  remember_me: "Remember me",
  forgot_password: "Forgot Password",
  Login: "Login",
  go_back: "Go back",
  "404_desc": "Sorry we could't find this page",
  submit_1: "Submit",
  required_field: "* Required",
  logout: "Logout",
  privacy_policy: "Privacy policy",
  terms: "Terms & conditions",

  /* MEMBER */
  hi: "Hi",
  choose_benefits_message: "choose the benefits for",
  rt_tt_ratio: "Remaining budget / Total budget",
  history: "History",
  BTD: "Back to Dashboard",
  user_did_not_choose: "User did not choose any benefit.",
  time_to_pick: "Time to pick benefits",
  time_to_pick_locked:
    "The platform for selecting benefits will be unlocked from:",
  Member: "Member",
  available_prov: "Available Providers",
  Search: "Search",
  Price: "Price",
  SBG: "Sort Benefit Groups",
  sort_by: "Sort By",
  filter_by: "Filter By",
  locations: "Locations",
  SL: "Select locations",
  FBT: "Filter Benefit Type",
  reset_filter: "Reset Filter",
  close: "Close",
  Category: "Category",
  open_filters: "Open Filters",
  Browse_Benefits: "Browse Benefits",
  Company_Benefits: "Company Benefits",
  My_Benefits: "My Benefits",
  Favorite_Benefits: "Favorite Benefits",
  NFB: "Currently there are no favorite benefits.",
  TM: "This month",
  NM: "Next month",
  DEF: "Default",
  SAB: "Show all Benefits",
  SOC: "Show only Changes",
  NABTM: "There are no active benefits in this month",
  NABNM: "There are no chosen benefits for next month",
  NADB: "There are no default benefits",
  add_favorites: "Add to favorites",
  remove_favorites: "Remove from favorites",
  rate_the_benefit: "Rate this benefit",
  rating: "ratings",
  Price_includes_VAT: "Price includes VAT of",
  remaining: "Remaining",
  enter_the_value: "Enter value in currency",
  Subscribe: "Subscribe",
  Unsubscribe: "Unsubscribe",
  Redeem: "Redeem",
  Return_voucher: "Return voucher",
  upload: "Upload",
  Unsubscribe: "Unsubscribe",
  Subscribed: "Subscribed",
  Resubscribe: "Resubscribe",
  min_voucher_price: "Minimum voucher price is {{price}} in currency",
  tokens_shortage_sub: "You don’t have enough budget to subscribe.",
  tokens_shortage: "You don’t have enough budget to create voucher.",
  choose_benefits_message: "Hi {{name}}, choose your benefits for",

  /* HR */
  benefit_group_management: "Benefit Group Management",
  save: "Save",
  cancel: "Cancel",
  preview: "Preview",
  disable_benefit_warning:
    "If you disable benefit, all users will lose access automatically.",
  tiers_info:
    "You need to create tiers to manage accessibility of benefit groups",
  on: "ON",
  off: "OFF",
  bg_disabled: "Benefit group is disabled",
  bg_enabled: "Benefit group is enabled",
  access: "Access",
  uncheck_all: "Uncheck all",
  check_all: "Check all",
  disable_installments_warning:
    "If you disable this, users will not be able to get one time benefits with installments.",
  installments_enabled: "Installments are enabled",
  installments_disabled: "Installments are disabled",
  download: "Download",
  active_users: "Active users",
  Benefits: "Benefits",
  filter_by_group_category: "Filter by group category",
  filter_by_benefit_tags: "Filter by benefit tags",
  filter_by_status: "Filter by status",
  enabled: "Enabled",
  disabled: "Disabled",
  Dashboard: "Dashboard",
  budget_spent_tiers: "Budget spent per tiers",
  chosen_month_budget: "Chosen month budget",
  previous_month_budget: "Previous month budget",
  total_budget: "Total Budget",
  chosen_month: "Chosen month",
  previous_month: "Previous month",
  budget_spent: "Budget Spent",
  budget_spent_by_category: "Budget spent per category",
  tokens_used: "Budget used",
  spent_budget: "Spent budget",
  budget_unused: "Budget unused",
  usage_statistics: "Usage Statistics",
  total_users: "Total users",
  employees_using_the_system: "Employees using the system",
  employees_not_using_the_system: "Employees not using the system",
  Employees: "Employees",
  gen_usr_rep: "Generate usage report",
  select_report_type: "Select report type",
  chosen_for_this_month: "Chosen for this month",
  chosen_for_next_month: "Chosen for next month",
  "Select Item": "Select Item",
  downloading: "Downloading",
  gen_ref_rep: "Generate refund report",
  sync_widget: "Sync Widget",
  sync_title: "Synced Employees",
  top_benefits: "Top Benefits",
  combination_statistics: "Combination Statistics",
  uniquely_chosen_combinations_title:
    "uniquely chosen combinations of benefits",
  over_2_mil: "Over 2 million possible combinations",
  Refunds: "Refunds",
  Settings: "Settings",
  "Company Benefits": "Company Benefits",
  Logout: "Logout",
  left: "left",
  benefit_has_installments:
    "This benefit group has a price split in installments.",
  decline_unsub_warning:
    "You won't be able to unsubscribe until the commitment period ends.",
  unsub_explain:
    "Once the commitment period ends you will be automatically unsubscribed.",
  company_benefit_groups: "Company Benefit Groups",
  add_local_benefit_groups: "Add Company Benefit Group",
  edit_local_benefit_group: "Edit Company Benefit Group",
  please_wait: "Please wait",
  basic_info: "Basic Info",
  company_name: "Company name",
  Name: "Name",
  Description: "Description",
  Summary: "Summary",
  Photo: "Photo",
  Taxes: "Taxes",
  Update: "Edit",
  add_benefit: "Add Benefit",
  edit_local_benefit: "Edit Benefit",
  title: "title",
  expiration_type: "Expiration type",
  min_contract_period: "Minimum contract period",
  ben_price_in_tokens: "Benefit price in tokens",
  save_as_draft: "Save benefit as draft",
  publish_benefit: "Publish benefit",
  Create: "Add",
  select_categories: "Select categories",
  benefits: "Benefits",
  Vouchers: "Vouchers",
  Options: "Options",
  Tokens: "Tokens",
  only_predefined_values: "Only predefined values",
  refund_voucher: "Refund voucher",
  Publish: "Publish",
  Edit: "Edit",
  duration_type: "Duration type",
  add_local_benefit: "Add Benefit",
  search_employees: "Search employees",
  add_employee: "Add Employee",
  add_employees_csv: "Add Employees CSV",
  add_employees: "Add Employees",
  update_employees_csv: "Update Employees CSV",
  add_bonus: "Add bonus",
  filter_by_tiers: "Filter by tiers",
  filter_by_budget_percentage: "Filter by spent budget",
  Sort: "Sort",
  sort_by: "Sort by",
  Selected: "Selected",
  Delete: "Delete",
  no_result_found: "No results found",
  Tier: "Tier",
  Budget: "Budget",
  "Spent(%)": "Spent(%)",
  Verified: "Verified",
  download_template: "Download Template",
  download_template_info:
    "In order to add employees you must first download the .csv template file by clicking the button below.",
  submit_the_file: "Submit the file",
  upload_the_file: "Upload the file",
  step2_title_employees: "Populate the file with employees",
  step2_text_1:
    "After you have downloaded the .csv template, open the file and populate it with employees. Save the document as the CSV file and upload it.",
  step2_text_2:
    "Inside the .csv template, you will find examples of how you should add your employees.",
  required_fields_employees:
    "Fields: first name, last name, email, tier and locations are required and cannot be left empty.",
  step2_title_bonuses: "Populate the file with bonuses",
  step2_text_3:
    "After you populate the .csv template with employees, you can populate it with bonuses.",
  bonus_type_info:
    "BonusType can have one of the following values: monthly, three_months, six_months or yearly.",
  bonus_start_date_info:
    "If you enter the BonusAmount field and the BonusStartDate is empty, we put the default date when the CSV was uploaded.",
  bonus_end_date_info:
    "If you enter the BonusAmount field and the BonusEndDate is empty, we put the default date to 3 months from the day the CSV was uploaded.",
  bonus_type_default_info:
    "If you enter the BonusAmount field and the BonusType is empty, we set the default type as one-time and not renewable.",
  required_fields_bonuses:
    "If you enter the BonusStartDate, BonusEndDate or BonusType, the required field is BonusAmount.",
  update_employees: "Update Employees",
  update_employees_info:
    "In order to update employees you must first download the .csv template file by clicking the button below.",
  "Add Bonuses": "Add Bonuses",
  of_tokens: "of tokens",
  "Bonus Start Date": "Bonus Start Date",
  subject: "subject",
  message: "message",
  "User is disabled": "User is disabled",
  resend_ver_link: "Resend verification link",
  add_bonus_budget: "Add bonus budget",
  enable_user: "Enable user",
  Gender: "Gender",
  "Birth date": "Birth date",
  "Enroll date": "Enroll date",
  Relationship: "Relationship",
  Team: "Team",
  Bonuses: "Bonuses",
  Contact: "Contact",
  Details: "Details",
  Rank: "Rank",
  Surname: "Surname",
  Birthday: "Birthday",
  "Unique ID": "Unique ID",
  "Branch Code": "Branch Code",
  disabled_user_info: "This user is currently disabled.",
  Info: "Info",
  Position: "Position",
  Family: "Family",
  "Monthly tokens": "Monthly budget",
  "Enable HR Role": "Enable HR Role",
  "Edit Employee": "Edit Employee",
  "Benefit Group": "Benefit Group",
  Expiration: "Expiration",
  Provider: "Provider",
  "Disable User": "Disable User",
  choose_disabling_date: "Choose when you want the user to be deleted.",
  immediate_delete:
    "If you wish to delete him immediately, select today's date.",
  Disable: "Disable",
  employees: "employees",
  delete_employees: "Choose when you want the",
  to_be_deleted: "to be deleted.",
  "Rollover duration (cycles)": "Rollover duration (cycles)",
  "Rollover resets on": "Rollover resets on",
  turned_on_rollover_info:
    "If this is turned on it will enable transfer of all the unspent budget of each user to the next month.",
  max_budget_info: "This way you can maximize usage of your benefit budget.",
  "Scheduled rollover deletion": "Scheduled rollover deletion",
  "Add Tier": "Add Tier",
  "Rollover is enabled": "Rollover is enabled",
  "Rollover is disabled": "Rollover is disabled",
  "tier settings": "tier settings",
  "company brand settings": "company brand settings",
  "Add logo": "Add logo",
  Remove: "Remove",
  "Company logo": "Company logo",
  upload_logo_info: "Upload logo with a landscape orientation (min 100x100px)",
  benefiti_welcome: "Welcome to Benefiti.rs platform!",
  first_time_setup: "This is your first time company setup.",
  add_tiers_info:
    "Add as many tiers as you need, name them and decide how budget are assigned monthly to each tier. You can edit this later!",
  empty_refunds: "There are no refunds yet.",
  Rejected: "Rejected",
  Accepted: "Accepted",
  Pending: "Pending",
  "Show All": "Show All",
  Status: "Status",
  Branch: "Branch",
  selected: "selected",
  Accept: "Accept",
  or: "or",
  Decline: "Decline",
  Price: "Price",
  Date: "Date",
  "Auto Accept": "Auto Accept",
  "Auto Decline": "Auto Decline",
  Type: "Type",
  "Single Refund": "Single Refund",
  Image: "Image",
  Activity: "Activity",
  Companies: "Companies",
  Users: "Users",
  Notifications: "Notifications",
  show_rollover_dates: "Show Rollover Reset Dates",
  rollover_resets: "Rollover resets",
  no_rollover: "This company has no rollover",
  show_usage_report: "Show Usage Report",
  "Select company": "Select company",
  unique_user_sub: "Unique User Subscriptions",
  Subscriptions: "Subscriptions",
  "Select Date": "Select Date",
  "Choose month": "Choose month",
  filter_by_companies: "Filter by companies",
  filter_by_roles: "Filter by roles",
  prev_day: "Previous Day",
  next_day: "Next Day",
  no_activity_logs: "No logs for ",
  "Company tags": "Company tags",
  "Benefit tags": "Benefit tags",
  "Add Company tag": "Add Company tag",
  benefit_group_cat: "Benefit group categories",
  add_benefit_group_cat: "Add Benefit group category",
  add_cities: "Add cities",
  Cities: "Cities",
  edit_company_tag: "Edit Company tag",
  add_benefit_tag: "Add Benefit tag",
  edit_benefit_tag: "Edit Benefit tag",
  add_category: "Add Category",
  edit_category: "Edit Category",
  "Reminders & Instructions": "Reminders & Instructions",
  send_budget_remainders: "Send Budget Reminders",
  send_usage_instructions: "Send Usage Instructions",
  custom_email_notif: "Custom Email Notifications",
  send_message_to_other_usr: "Send message to users of chosen companies",
  choose_comp_as_receivers: "Choose companies as receivers",
  send_message: "Send message",
  Providers: "Providers",
  add_provider: "Add Provider",
  download_vendor_report: "Download Vendor Report",
  provider_enabled: "Provider is enabled",
  provider_disabled: "Provider is disabled",
  edit_provider: "Edit Provider",
  add_benefit_group: "Add Benefit Group",
  Company: "Company",
  Remote: "Remote",
  publish_ben_group: "Publish benefit group",
  save_ben_gr_as_draft: "Save benefit group as draft",
  Draft: "Draft",
  vouch_price: "Vouchers price",
  edit_bg: "Edit Benefit Group",
  add_benefit: "Add Benefit",
  Quantity: "Quantity",
  short_name: "Short name",
  short_name_info: "Short name will be used as display name in the statistics.",
  num_of_installments: "Number of installments",
  VAT: "PDV",
  usg_instr: "Usage Instructions",
  add_tag: "Add tag",
  submit_tag: "Submit tag",
  edit_benefit: "Edit Benefit",
  rs: "SR",
  en: "EN",
  sub_monthly: "Subscribed monthly",
  sub_one_time: "Subscribed one-time",
  sub_vouchers: "Subscribed vouchers",
  Installments: "Installments",
  MCP: "MCP",
  total_spent: "Total Spent",
  benefit_preview: "Benefit Group {{name}} Preview",
  NEW: "NEW",
  choosen_ben: "Choosen benefit",
  price_in: "Price",
  dinars: "RSD",
  tokens: "tokens",
  global_company_settings: "Global Company Settings",
  Hr: "HR",

  HEALTH: "HEALTH",
  FAMILY: "FAMILY",
  "FOOD & DRINK": "FOOD & DRINK",
  HOME: "HOME",
  LEISURE: "LEISURE",
  "WELLNESS & WELLBEING": "WELLNESS & WELLBEING",
  "CHARITY & FOUNDATIONS": "CHARITY & FOUNDATIONS",
  "LEARNING & DEVELOPMENT": "LEARNING & DEVELOPMENT",
  COWORKING: "COWORKING",
  PETS: "PETS",
  "SPORT & RECREATION": "SPORT & RECREATION",
  MOBILITY: "MOBILITY",
  DISCOUNTS: "DISCOUNTS",
  SAVINGS: "SAVINGS",
  TRAVEL: "TRAVEL",
  GIFT: "GIFT",
  "IT EQUIPMENT": "IT EQUIPMENT",
  "AVAILABLE IN ENGLISH": "AVAILABLE IN ENGLISH",
  "LOKALNI BENEFITI": "LOCAL BENEFITS",
  REFUNDACIJA: "REFUND",
  "**PARTNER NAGRAĐUJE**": "**PARTNER REWARDS**",
  dnd_or: "Drag and drop image or",
  click_to_upload: "click here to upload",
  supported_formats: "Supported formats .png, .jpg, .jpeg",
  Draft: "Draft",
  delete_benefit_group_alert:
    "Are you sure you want to delete this benefit group?",
  delete_benefit_alert: "Are you sure you want to delete benefit?",
  benefit_name: "Benefit name",
  delete_tier_alert: "Are you sure you want to delete this tier?",
  "Total Benefits": "Total Benefits",
  Show: "Show",
  delete_company_alert: "Are you sure you want to delete company?",

  /* Toast Notifications */
  failed_to_get_favorite_benefits: "Failed to get favorite benefits.",
  failed_to_remove_from_favorites: "Failed to remove benefit from favorites.",
  failed_to_add_to_favorites: "Failed to add benefit to favorites.",
  failed_to_delete_company: "Deleting Company failed.",
  failed_to_send_ver_link:
    "Verification link was not sent, please contact support.",
  failed_to_enable_user: "Enabling user failed.",
  failed_to_login: "Login failed.",
  failed_to_reset_password: "Reset password failed.",
  add_rating: "You must select rating!",
  failed_to_leave_rating: "Leaving Review failed.",
  failed_to_get_benefit_groups: "Fetching Benefit groups failed.",
  failed_to_get_used_benefits: "Fetching Used Benefits failed.",
  failed_to_get_sub_benefit_groups:
    "Fetching Subscribed Benefit groups failed.",
  success_review: "Review successfully sent!",
  failed_to_create_user: "Creating user failed.",
  success_user_create: "User successfully created!",
  failed_to_get_companies: "Fetching companies failed.",
  failed_to_get_roles: "Fetching roles failed.",
  failed_to_edit_user: "Edit user failed.",
  failed_to_get_users: "Fetching users failed",
  failed_to_accept_policy: "Accepting privacy policy failed.",
  failed_to_delete_user: "Deleting User failed.",
  failed_to_get_popular_benefit: "Unable to get most popular benefit.",
  failed_to_get_notifications: "Fetching notifications failed.",
  failed_to_get_categories: "Fetching Categories failed.",
  failed_to_benefit_subscribe: "Subscribe to benefit failed.",
  failed_to_get_employees_tokens: "Fetching employee budget failed.",
  failed_to_redeem_voucher: "Redeem voucher failed.",
  failed_to_set_valid_file_format:
    "Invalid file format. Please select a valid file.",
  failed_to_mark_as_read: "Marking as read failed.",
  failed_to_onboard: "Onboarding failed.",
  failed_to_get_single_benefit_info:
    "Unable to get information about single benefit.",
  failed_to_get_tiers: "Fetching tiers failed.",
  select_tiers_warning: "Please select employee tiers to enable benefit group",
  failed_to_update_benefit_group: "Update benefit group failed.",
  failed_to_get_benefit_group_users: "Fetching Benefit group users failed.",
  success_employee_edit: "Employee successfully edited!",
  success_add_bonus: "Bonus successfully created!",
  success_add_employee: "Employee successfully added!",
  select_users_warning: "Please select users.",
  success_employees_add: "Employees created",
  success_employees_edit: "Employees updated",
  failed_to_get_benefit_tags: "Fetching benefit tags failed.",
  failed_to_get_budget_spending: "Fetching Budget Spending failed.",
  failed_to_get_budget_per_categories: "Fetching Budget per categories failed.",
  failed_to_get_synced_employees: "Fetching Synced Employees failed.",
  failed_to_get_tier_budgets: "Fetching Tier Budgets failed.",
  failed_to_get_top_benefits: "Fetching Top Benefits failed.",
  failed_to_get_total_budgets: "Fetching Total Budgets failed.",
  failed_to_get_statistics: "Fetching Statistics failed.",
  failed_to_get_company_usage: "Fetching Company Usage failed.",
  delete_benefit_group_info:
    "In order to delete this benefit group please first delete all benefits belonging to this benefit group.",
  success_add_benefit: "Benefit successfully added!",
  failed_to_publish_benefit: "Benefit publishing failed.",
  success_publish_benefit: "Successfully published benefit.",
  success_edit_benefit: "Benefit successfully edited!",
  success_add_company_benefit_group:
    "Company benefit group successfully added!",
  success_edit_company_benefit_group:
    "Company benefit group successfully edited!",
  success_onboarding_finish: "Onboarding is already finished.",
  fill_form_warning: "Please fill form properly.",
  step_1_must_be_filled_warning: "First step must be filled correctly!",
  failed_to_finish_onboarding: "Finish onboarding failed.",
  failed_to_get_token_value: "Fetching budget value failed.",
  failed_to_get_refund_list: "Failed to get list of refunds.",
  failed_to_accept_refund: "Failed to accept the refund.",
  failed_to_get_company_reimbursement_state:
    "Unable to get company reimbursement state.",
  success_tier_create: "Tier successfully created!",
  success_tier_edit: "Tier successfully edited!",
  failed_to_currency_change: "Failed to change tokens in to the currency.",
  failed_to_get_additional_logs: "Fetching additional logs failed",
  failed_to_get_user_info: "Unable to fetch info for the user",
  the_company_is_not_in_bonus_budget_warning:
    "WARNING: The company is no longer in the bonus budget. Bonuses for all employees last until the end of this month!",
  the_company_is_not_in_sso_mode_warning:
    "WARNING: The company is no longer in the ONLY SSO Login mode!",
  failed_to_get_admin_statistics: "Unable to fetch admin statistics.",
  failed_to_send_emails: "Sending emails failed.",
  failed_to_get_providers: "Fetching providers failed.",
  failed_to_get_report: "Fetching report failed.",
  provider_delete_info:
    "In order to delete this provider please first delete all benefit groups and benefits belonging to the provider.",
  proved_delete_question: "Are you sure you want to delete this provider?",
  success_add_benefit_group: "Benefit group successfully created!",
  success_edit_benefit_group: "Benefit group successfully updated!",
  success_create_provider: "Provided successfully created!",
  success_update_provider: "Provided successfully updated!",
  select_benefit_group_warning: "You need to select benefit group!",
  delete_benefit_group_question:
    "Are you sure you want to delete this benefit?",
  delete_benefit_group_description:
    "There might be users that are subscribed to this benefit. If you delete it, they will be automatically unsubscribed from the benefit.",
  delete_company_tag_question:
    "Are you sure you want to delete this company tag?",
  failed_to_delete_company_tag: "Deleting company tag failed.",
  success_delete_company_tag: "Company tag successfully deleted!",
  failed_to_delete_benefit_tag: "Deleting benefit tag failed.",
  success_delete_benefit_tag: "Benefit tag successfully deleted!",
  delete_benefit_group_category_question:
    "Are you sure you want to delete this benefit group category?",
  failed_to_delete_benefit_group_category:
    "Deleting benefit group category failed.",
  success_delete_benefit_group_category: "Category successfully deleted!",
  delete_city_question: "Are you sure you want to delete this city?",
  failed_to_delete_city: "Failed to delete the city.",
  success_add_tag: "Benefit tag successfully created!",
  success_update_tag: "Benefit tag successfully updated!",
  success_add_category: "Category successfully created!",
  success_update_category: "Category successfully updated!",
  success_add_benefit_category: "Company tag successfully created!",
  success_update_company_tag: "Company tag successfully updated!",
  failed_to_get_user_history: "Unable to fetch user history.",
  bonus_start_date_disclaimer:
    "If you don't select a start date, bonus will be active from the first day of next month.",
  bonus_end_date_disclaimer:
    "If you don't select an end date, bonus will be active for a period of three months by default.",
  "Recurring Bonus": "Recurring Bonus",
  mcp_period_info: "*Minimum contract period: {{month}} months",
  mcp_take_warning:
    "Unsubscribing will not be possible until the commitment period ends, and once it ends, the subscription will be automatically canceled.",
  mcp_take_info: "*Price is split in installments as shown above.",
  add_company: "Add Company",
  id_num: "Identification number",

  inv_email: "Invoice email",
  Address: "Address",
  enable_vat: "Enable VAT",
  vat_enable_desc:
    "When you check the box, the company will be able to use VAT",
  only_bonus_budget: "Only bonus budget",
  bonus_budget_desc:
    "When you check the box, the company have only bonus budget",
  only_sso_login: "Only SSO login",
  sso_login_desc:
    "When you check the box, the companies HR have only SSO login option",
  test_phase: "Test phase",
  test_phase_desc: "When you check the box, the company is in the test phase",
  enable_reimbursement: "Enable reimbursement",
  enable_reimbursement_desc:
    "When you check the box, the company will be able to use reimbursement",
  enable_currency: "Enable Currency",
  enable_currency_desc:
    "When you check the box, the company will see the values in currency",
  payment_deadline: "Payment deadline",
  company_tags: "Company tags",
  tag_name: "Tag name",
  add_tag: "Add tag",
  upload_logo: "Upload logo as png file",
  no_image: "No image to display",
  upload_image: "Upload image",
  Tags: "Tags",
  edit_company: "Edit Company",
  search_users: "Search users",
  add_user: "Add User",
  clear_all: "Clear all",
  Selected: "Selected",
  user_info: "User info",
  Roles: "Roles",
  first_name: "First name",
  last_name: "Last name",
  formal_mail_flag: "Formal email flag",
  additional_members_fields: "Additional Member fields",
  add_user: "Add user",
  Enroll: "Enroll",
  Phone: "Phone",
  Locations: "Locations",
  user_disabled: "User is disabled",
  enable_user: "Enable user",
  Role: "Role",
  "Is Verified": "Is Verified",
  edit_user: "Edit User",
  voc_name: "Vocative name",
  disabled_usr_desc: "This user is currently disabled.",
  remaining_tokens_total_tokens: "Remaining budget / Total budget",
  number_of_different_benefits:
    "Total number of different benefits user is subscribed to",
  month: "Month",
  "one time": "One time",
  voucher: "Voucher",
  subscribed_success_to_mcp_title:
    "You have successfully subscribed to a benefit that has a minimum contract period.",
  subscribed_success_to_mcp_info:
    "Once it becomes active, you cannot unsubscribe from it until the minimum contract period expires. Then you will be unsubscribed automatically.",
  subscribed_success_to_mcp_info2:
    "Your subscription will last for {{minimumContractPeriod}} months, and will expire on",
  Male: "Male",
  Female: "Female",
  Other: "Other",
  male: "Male",
  female: "Female",
  other: "Other",
  Married: "Married",
  Single: "Single",
  Divorced: "Divorced",
  married: "Married",
  single: "Single",
  divorced: "Divorced",
  ben_group_no_ben: "Benefit Group currently does not have benefits.",
  empty_list: "List is empty",

  file_to_large: "File size too large",
  upload_supported_type: "Please upload supported image type",
  invalid_file_format: "File format invalid",
  invalid_image_mime: "Invalid image mimetype",
  internal_server_error: "Internal Server Error",
  not_found_error: "Not Found Error",
  token_invalid: "Token Invalid",
  unauthorized: "Unauthorized",
  forbidden: "Forbidden",
  sign_in_with_sso: "You must sign in using Microsoft",
  conflict: "Conflict",
  invalid_file_field: "Check if your filed for file is correct",
  foreign_key_out_bound: "Provided foreign key is out of bound",
  something_went_wrong: "Something went wrong please refresh page",
  something_went_wrong_csv: "Something went wrong with csv upload",
  email_confirmation: "You need to verify your email if you want to proceed!",
  account_disabled: "Your account has been disabled.",
  link_expired: "Link has expired",
  user_exist: "User already exists",
  cannot_delete_yourself: "You cannot delete yourself",
  email_is_taken: "Email already taken",
  email_does_not_exist: "Email does not exist",
  user_not_found: "User Not Found",
  user_details_is_provided: "User details are already provided",
  user_has_been_deleted: "User has been deleted",
  incorrect_credentials: "Incorrect credentials",
  incorrect_password: "Incorrect password",
  email_already_verified: "Email already verified",
  email_verification_failed: "Email verification failed",
  invalid_email_address: "Invalid email address",
  cannot_upload_file: "File can not be uploaded",
  could_not_create_directory: "Could not create the needed directory",
  cannot_find_tag: "Tag can not be found",
  cannot_find_company: "Company cannot be found",
  cannot_find_tier: "Tier can not be found",
  cannot_find_company_id: "Company ID can not be found",
  must_assign_employees_to_new_tiers:
    "Tier contains employees. Employees must be assigned to new tiers first",
  pib_must_be_unique: "PIB must be unique",
  id_must_be_unique: "ID Number must be unique",
  tier_name_already_exist: "Tier name already exists",
  cannot_find_tier: "Provider can not be found",
  failed_update_provider: "Provider update failed",
  failed_delete_provider:
    "You need to delete all benefit groups within this provider first",
  benefit_category_not_found: "Benefit Category can not be found",
  cannot_find_benefit_group: "Benefit Group can not be found",
  failed_delete_benefit_group:
    "You need to delete all benefits within this benefit group first",
  failed_delete_benefit_category:
    "You need to delete all benefit groups within this category first",
  cannot_find_benefit_tag: "Benefit Tag can not be found",
  cannot_find_benefit: "Benefit can not be found",
  failed_delete_benefit:
    "This benefit can not be deleted as long as it has subscriptions.",
  cannot_find_employee: "Employee can not be found",
  is_not_employee: "Only employees have tokens available",
  already_subscribed: "You are already subscribed to this benefit",
  already_unsubscribed: "You are already unsubscribed from this benefit",
  benefit_unavailable_for_user_location:
    "This benefit is not available for your location.",
  company_blocked: "Company temporarily blocked. Please contact support",
  company_disabled: "Company disabled. Please contact support",
  company_test_phase: "Company in test phase. Please contact support",
  token_restriction: "You do not have enough tokens to perform this action",
  base_budget_token_restriction:
    "You do not have enough base budget to cover the price of this benefit",
  employee_end_of_month_restriction:
    "Making changes at the end of the month is currently not allowed. You can make changes at the start of the next month",
  employee_restriction:
    "There are no available benefits for this benefit group.",
  sync_enabled:
    "Users are not allowed to make any changes during the sync period.",
  subscribe_on_time: "You can not subscribe till you in deactivation process.",
  benefit_removed_restriction:
    "The benefit was removed, or the tier you belong to has lost the access to this benefit.",
  benefit_inactive: "This benefit is inactive",
  benefit_subscribe_restriction:
    "You can’t be subscribed to more than one benefit/voucher within the same benefit group. You would have to unsubscribe first, and then choose another benefit/voucher to subscribe to.",
  unsubscribe_from_benefit_with_mcp_restriction:
    "You can’t unsubscribe from a benefit that has a minimum contract period, until it expires.",
  remove_city_from_employee_restriction:
    "Cannot remove employee locations in the second half of the month.",
  failed_create_employee:
    "Something went wrong while creating employee. Please try again",
  hr_time_restriction:
    "This action is not available in the second half of the month",
  company_blocked_hr: "Company blocked. Please contact support",
  cannot_find_token: "Token does not exist",
  failed_remove_employee:
    "Employee cannot be removed because of active benefits!",
  change_tier_budget_restriction:
    "Cannot change tier budget in the second half of the month.",
  decrease_benefit_group_capacity_restriction:
    "You cannot decrease benefit group capacity because the amount of taken benefits will be greater than group capacity.",
  change_benefit_group_access_to_tier_restriction:
    "Cannot change access to tier in the second half of the month.",
  disable_benefit_group_access_to_tier_restriction:
    "Cannot disable access to tier in the second half of the month.",
  change_employee_tier_restriction:
    "Cannot change employee tier in the second half of the month.",
  can_employee_be_changed_to_tier_based_on_active_benefits_with_mcp_error:
    "The user cannot be moved to this tier, because the user is subscribed to the benefit with minimal contract period and will not have enough tokens if moved. You can move the user once the minimal contract period expires.",
  tier_budget_decrease_restriction:
    "There are users in this tier with current subscriptions to benefits with minimal contract period whose price in tokens is greater than the budget amount you have entered.",
  report_statistic_is_empty: "Report statistics is empty for this company!",
  local_benefits_restriction_error:
    "You can create only one company benefit within the company benefit group.",
  subscribe_to_disabled_benefit_error:
    "This benefit group has been disabled so you can’t subscribe to its benefits.",
  benefit_price_change_restriction:
    "Cannot change benefit price in the second half of the month.",
  benefit_expiration_type_change_restriction:
    "Cannot change benefit expiration type except while in sync period.",
  benefit_quantity_error: "Not enough items available.",
  remove_city_from_benefit_group_restriction:
    "Cannot remove benefit group locations in the second half of the month.",
  employee_tier_change_with_smaller_budget:
    "This user has active active minimum contract period. This budget is not sufficient for MCP to be fully paid. Please choose tier with higher budget until MCP ends.",
  provider_enabled_status_restriction:
    "Cannot change provider status except while in sync period.",
  benefit_group_review_not_found_error: "Review can not be found",
  benefit_group_review_delete_error:
    "This review can not be deleted as long as it has subscriptions.",
  company_theme_exists_error: "Company theme already exists",
  company_theme_not_found_error: "Company theme does not exist",
  company_theme_delete_error: "Company theme has not been deleted",
  budget_reminders_email_restriction:
    "Unspent budget reminders can only be sent between the 12th and 24th of the month",
  usage_instructions_restriction:
    "Usage instructions can only be sent before the 7th of the month",
  invalid_email: "Invalid email format",
  password_required: "Password required",
  invalid_input: "Input invalid",
  invalid_type: "Invalid type",
  required_first_name: "First name required",
  required_last_name: "Last name required",
  invalid_password: "Invalid password",
  password_must_contain_digits: "Password must contains digits",
  password_must_contain_non_digits: "Password must contain non digits",
  password_min_length: "Password minimum length is 8 characters",
  invalid_first_name: "Invalid first name",
  invalid_last_name: "Invalid last name",
  old_password_required: "Old password required",
  message_required: "Message required",
  message_must_contains_digits: "Message must contains digits",
  message_must_contains_non_digit: "Message must contains non digit",
  message_min_length_8: "Message minimum length is 8 characters",
  hash_required: "Hash required",
  email_required: "Email required",
  invalid_email: "invalid_email",
  perm_must_be_bool: "Permanent should be true or false",
  name_required: "Name required",
  invalid_summary: "Invalid summary",
  invalid_name: "Invalid name",
  invalid_description: "Invalid description",
  invalid_photo: "Invalid photo",
  invalid_company: "Invalid company",
  invalid_tiers: "Invalid tiers",
  invalid_token: "Invalid token",
  first_name_should_not_be_empty: "First name should not be empty",
  last_name_should_not_be_empty: "Last name should not be empty",
  tier_required: "Tier required",
  invalid_gender: "Invalid gender",
  invalid_team: "Invalid team",
  invalid_relationship: "Invalid relationship",
  invalid_contact: "Invalid contact",
  search_must_be_alphanumeric_string: "Search must be an alphanumeric string",
  employee_required: "Employee required",
  company_required: "Company required",
  tokens_required: "Tokens required",
  user_required: "User required",
  benefit_required: "Benefit required",
  benefit_group_required: "Benefit group required",
  status_required: "Status required",
  file_required: "File required",
  invalid_bg: "Invalid benefit group",
  invalid_voucher: "Invalid voucher",
  invalid_date_format: "Date is not in valid format",
  invalid_start_date_format: "Start date is not in valid format",
  invalid_end_date_format: "End date is not in valid format",
  address_required: "Address required",
  pib_required: "PIB required",
  id_number_required: "Identification number required",
  contact_required: "Contact required",
  payment_date_required: "Payment date required",
  invalid_tags: "Invalid tags",
  invalid_address: "Invalid address",
  invalid_pib: "Invalid PIB",
  invalid_id_number: "Invalid identification number",
  invalid_invoice_email: "Invalid invoice email",
  invalid_payment_date: "Invalid payment date",
  title_required: "Title required",
  expiration_required: "Expiration required",
  token_price_required: "Token price required",
  invalid_title: "Invalid title",
  invalid_expiration: "Invalid expiration",
  invalid_token_price: "Invalid token price",
  description_required: "Description required",
  invalid_description_name: "Invalid description name",
  invalid_category_name: "Invalid category name",
  invalid_category_description: "Invalid category description",
  invalid_provider: "Invalid provider",
  photo_required: "Photo is required",
  invalid_category: "Invalid category",
  rating_required: "Rating required",
  bg_id_must_be_string: "Benefit Group ID must be string",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  m_users: "users",
  j_users: "user",
  m_sub: "subscribed",
  j_sub: "subscribed",
  earlier: "Recently",
  on: "on",
  active_until: "Active until",
  active_from: "Active from",
  Active: "Active",
  bg_successfully_deleted: "Benefit Group successfully deleted!",
  tier_successfully_deleted: "Tier successfully deleted!",
  bg_status_changed: "Benefit group status changed!",
  mark_as_read_successfully: "Successfully marked as read!",
  invalid_file_name: "Invalid file name",
  published_successfully_bg: "Successfully published benefit group!",
  ben_successfully_deleted: "Benefit successfully deleted!",
  ben_tag_successfully_deleted: "Benefit Tag successfully deleted!",
  published_successfully_ben: "Successfully published benefit!",
  deleted_successfully_company: "Company successfully deleted!",
  deleted_successfully_tag: "Tag successfully deleted!",
  saved_successfully_changes: "Changes successfully saved!",
  saved_successfully_rollover_budget: "Successfully saved budget rollover!",
  deleted_successfully_company_theme: "Company theme successfully deleted!",
  request_submitted: "Request submitted!",
  reset_successfully_info: "Information reset successfully!",
  sent_successfully_verification_link: "Verification link successfully sent!",
  email_verified: "Email verified!",
  sync_successfully_benefit: "Benefit successfully sync!",
  upload_successful: "Upload successful!",
  add_successful_to_fav: "Successfully added benefit to favorites!",
  removed_successful_from_fav: "Successfully removed benefit from favorites",
  delete_successfully_employee: "Employee successfully deleted!",
  delete_successfully_provider: "Provider successfully deleted!",
  accept_successfully_refund: "Successfully accepted refund!",
  rejected_successfully_refund: "Successfully rejected refund!",
  accept_reject_fail_refund:
    "Could not accept some reimbursements because of the inefficient funds of some users.",
  all_notif_read: "All notifications read",
  assign_failed_user_to_role_nonexisting:
    "Cannot assign user to role he does not have",
  role_nonexisting: "Role does not exist",
  accepted_successfully_privacy_policy: "Successfully accepted privacy policy!",
  check_email: "Check your email",
  password_changed_successfully: "Password changed successfully",
  email_sent: "Emails sent!",
  onboarding_finished: "Onboarding finished!",
  user_deleted: "User deleted!",
  user_disabled: "User disabled!",
  user_enabled: "User enabled!",
  user_no_role: "User has no role",

  subj_min_5_char: "Subject must be at least 5 characters long",
  message_min_5_char: "Message must be at least 5 characters long",
  recipient_min_1_company: "Recipient must be at least 1 company",
  name_too_long: "Name too long",
  html_tag_ban: "HTML tag is not allowed",
  too_long: "Too long",
  too_short: "Too short",
  all_caps_ban: "All caps not allowed",
  add_voucher_please: "Please add voucher",
  please_fill_all_fields: "You need to fill all the fields",
  minimum_token_value: "Minimum value for token price is 1",
  token_price_should_be_divisible_by_installments:
    "Token price should be divisible by the number of installments",
  must_have_9_digits: "Must have 9 digits",
  must_have_8_digits: "Must have 8 digits",
  min_8_chars: "At least 8 characters",
  must_contain_a_number: "Must contain number",
  must_contain_letters: "Must contain letters",
  no_match: "No match",
  minimum_1_tier_creation: "There must be at least 1 tier created",
  tier_is_created: "Tier name is taken",
  must_be_divisible_by_20: "Must be divisible by 20",
  file_required: "A file is required",
  only_csv_upload: "You can upload only .csv file format",
  min_quantity_requirement: "Minimum value for quantity is 1",
  popup_window_error: "Popup window error! Please retry",
  user_cancelled: "User cancel error! Please retry",
  token_renewal_error: "Token renewal error! Please reauthenticate",
  login_hint_error: "Login hint error! Please reauthenticate",
  interaction_in_progress:
    "Interaction in progress! Please make sure you do not down the popup window",
  browser_auth_error: "Browser Auth error! Please retry",
  unexpected_error_message: "An unexpected error occurred! Please retry",
  no_uploaded_images: "No images uploaded.",
  loading_images_error: "Error with loading images.",
  images_are_loading_info:
    "Images are downloading please be patient, it can take up to 15-20 minutes depending on file size."
};

const RS = {
  /* AUTH */
  LWE: "Uloguj se sa email adresom",
  LWM: "Uloguj se sa Microsoft nalogom",
  Password: "Lozinka",
  remember_me: "Ostani upamćen",
  forgot_password: "Zaboravljena lozinka",
  Login: "Uloguj se",
  go_back: "Vrati se korak nazad",
  "404_desc": "Izvini, stranica nije pronađena",
  submit_1: "Pošalji",
  required_field: "* Obavezno polje",
  logout: "Odjavi se",
  privacy_policy: "Politika privatnosti",
  terms: "Uslovi korišćenja",

  /* MEMBER */
  hi: "Zdravo",
  choose_benefits_message: "odaberi svoje benefite za",
  rt_tt_ratio: "Preostali budžet / Ukupan budžet",
  history: "Istorija",
  BTD: "Povratak na panel",
  user_did_not_choose: "Korisnik nije odabrao nijedan benefit.",
  time_to_pick: "Preostalo za biranje benefita",
  time_to_pick_locked: "Platforma za odabir benefita biće otključana od:",
  Member: "Zaposleni",
  available_prov: "Dostupni provajderi",
  Search: "Pretraži",
  Price: "Cena",
  SBG: "Sortiraj benefit grupe",
  sort_by: "Sortiraj po",
  filter_by: "Filtriraj po",
  locations: "Lokacije",
  SL: "Odaberi lokacije",
  FBT: "Filtriraj tip benefita",
  reset_filter: "Resetuj filter",
  close: "Zatvori",
  Category: "Kategorija",
  open_filters: "Otvori filtere",
  Browse_Benefits: "Pretraži benefite",
  Company_Benefits: "Kompanijski benefiti",
  My_Benefits: "Moji benefiti",
  Favorite_Benefits: "Omiljeni benefiti",
  NFB: "Trenutno nema omiljenih benefita.",
  TM: "Ovaj mesec",
  NM: "Sledeći mesec",
  DEF: "Kompanijski benefiti",
  SAB: "Prikaži sve benefite",
  SOC: "Prikaži samo izmene",
  NABTM: "Nemaš odabranih benefita ovog meseca",
  NABNM: "Nemaš odabranih benefita za sledeći mesec",
  NADB: "Nemaš podrazumevane benefite",
  add_favorites: "Dodaj u omiljene",
  remove_favorites: "Ukloni iz omiljenih",
  rate_the_benefit: "Oceni ovaj benefit",
  rating: "ocene",
  Price_includes_VAT: "U cenu je uračunat PDV od",
  remaining: "Preostalo",
  enter_the_value: "Unesi vrednost u valuti",
  Subscribe: "Pretplati se",
  Redeem: "Odaberi",
  Redeemed: "Odabrano",
  Return_voucher: "Vrati vaučer",
  upload: "Otpremiti",
  Unsubscribe: "Otkaži pretplatu",
  Subscribed: "Pretplaćen",
  Resubscribe: "Ponovi pretplatu",
  min_voucher_price: "Minimalna cena vaučera je {{price}} u valuti",
  tokens_shortage: "Nemaš dovoljno budžeta da odabereš vaučer",
  tokens_shortage_sub: "Nemaš dovoljno budžeta da se pretplatiš.",
  choose_benefits_message: "Zdravo {{name}}, odaberi svoje benefite za",

  /* HR */
  benefit_group_management: "Upravljanje benefit grupom",
  save: "Sačuvaj",
  cancel: "Otkaži",
  preview: "Pregled",
  disable_benefit_warning:
    "Ako onemogućiš benefit, svi korisnici će automatski izgubiti pristup.",
  tiers_info:
    "Potrebno je da kreiraš razrede kako bi upravljali pristupom grupama benefita.",
  on: "UKLJUČENO",
  off: "ISKLJUČENO",
  bg_disabled: "Benefit grupa je onemogućena",
  bg_enabled: "Benefit grupa je omogućena",
  access: "Pristup",
  uncheck_all: "Poništi sve",
  check_all: "Odaberi sve",
  disable_installments_warning:
    "Ako ovo onemogućiš, korisnici neće moći da dobiju jednokratne benefite na rate.",
  installments_enabled: "Plaćanje na rate omogućeno",
  installments_disabled: "Plaćanje na rate onemogućeno",
  download: "Preuzmi",
  active_users: "Aktivni korisnici",
  Benefits: "Benefiti",
  filter_by_group_category: "Filtriraj po kategoriji grupe",
  filter_by_benefit_tags: "Filtriraj po tagovima benefita",
  filter_by_status: "Filtriraj po statusu",
  enabled: "Omogućeno",
  disabled: "Onemogućeno",
  Dashboard: "Kontrolni panel",
  budget_spent_tiers: "Budžet potrošen po razredima",
  chosen_month_budget: "Budžet za odabrani mesec",
  previous_month_budget: "Budžet za prethodni mesec",
  total_budget: "Ukupan budžet",
  chosen_month: "Odabrani mesec",
  previous_month: "Prethodni mesec",
  budget_spent: "Potrošen budžet",
  budget_spent_by_category: "Budžet potrošen po kategorijama",
  tokens_used: "Iskorišćeni budžet",
  spent_budget: "Potrošen budžet",
  budget_unused: "Nepotrošeni budžet",
  usage_statistics: "Statistika korišćenja",
  total_users: "Ukupno korisnika",
  employees_using_the_system: "Zaposleni koji koriste sistem",
  employees_not_using_the_system: "Zaposleni koji ne koriste sistem",
  Employees: "Zaposleni",
  gen_usr_rep: "Generiši izveštaj o korišćenju",
  gen_ref_rep: "Generiši izveštaj o refundaciji",
  select_report_type: "Odaberi tip izveštaja",
  chosen_for_this_month: "Odabrano za ovaj mesec",
  chosen_for_next_month: "Odabrano za naredni mesec",
  "Select Item": "Odaberi",
  downloading: "Preuzimanje",
  sync_widget: "Sinhronizuj widget",
  sync_title: "Sinhronizovani zaposleni",
  top_benefits: "Najbolji benefiti",
  combination_statistics: "Statistika kombinacija",
  uniquely_chosen_combinations_title:
    "jedinstveno odabrane kombinacije benefita",
  over_2_mil: "Preko 2 miliona mogućih kombinacija",
  Refunds: "Refundacija",
  Settings: "Podešavanja",
  "Company Benefits": "Kompanijski benefiti",
  Logout: "Odjavi se",
  left: "preostalo",
  benefit_has_installments: "Ova grupa benefita ima cenu podeljenu na rate.",
  decline_unsub_warning:
    "Otkazivanje pretplate neće biti moguće dok ne istekne minimalni period ugovora, a nakon isteka, pretplata će automatski biti ukinuta",
  company_benefit_groups: "Kompanijske benefit grupe",
  add_local_benefit_groups: "Dodaj kompanijsku benefit grupu",
  edit_local_benefit_group: "Ažuriraj kompanijsku benefit grupu",
  please_wait: "Molimo sačekaj",
  basic_info: "Osnovne informacije",
  Name: "Ime",
  Description: "Opis",
  Summary: "Rezime",
  Photo: "Fotografija",
  Taxes: "Porezi",
  Update: "Ažuriraj",
  add_benefit: "Dodaj benefit",
  edit_local_benefit: "Ažuriraj benefit",
  title: "naslov",
  expiration_type: "Tip isteka",
  min_contract_period: "Minimalni period ugovora",
  ben_price_in_tokens: "Cena benefita u tokenima",
  save_as_draft: "Sačuvaj benefit kao nacrt",
  publish_benefit: "Objavi benefit",
  Create: "Dodaj",
  select_categories: "Odaberi kategoriju",
  benefits: "Benefiti",
  Vouchers: "Vaučeri",
  Options: "Opcije",
  Tokens: "Tokeni",
  only_predefined_values: "Samo unapred definisane vrednosti",
  refund_voucher: "Vaučer za refundaciju",
  Publish: "Objavi",
  Edit: "Ažuriraj",
  duration_type: "Tip trajanja",
  add_local_benefit: "Dodaj benefit",
  search_employees: "Pretraži zaposlene",
  add_employee: "Dodaj zaposlenog",
  add_employees_csv: "Dodaj CSV zaposlenih",
  update_employees_csv: "Ažuriraj CSV zaposlenih",
  add_bonus: "Dodaj bonus",
  filter_by_tiers: "Filtriraj po razredima",
  filter_by_budget_percentage: "Filtriraj po potrošenom budžetu",
  Sort: "Sortiraj",
  sort_by: "Sortiraj po",
  Selected: "Odabrano",
  Delete: "Obriši",
  no_result_found: "Nema rezultata",
  Tier: "Razred",
  Budget: "Budžet",
  "Spent(%)": "Potrošeno(%)",
  Verified: "Verifikovano",
  add_employees: "Dodaj zaposlene",
  download_template: "Preuzmi šablon",
  download_template_info:
    "Da bi dodao zaposlene, prvo moraš preuzeti .csv šablon klikom na dugme ispod.",
  submit_the_file: "Pošalji datoteku",
  upload_the_file: "Otpremi datoteku",
  step2_title_employees: "Popuni fajl sa zaposlenima",
  step2_text_1:
    "Nakon što preuzmeš .csv šablon, otvori fajl i popuni ga sa zaposlenima. Sačuvaj dokument kao CSV fajl i otpremi ga.",
  step2_text_2:
    "Unutar .csv šablona naći ćeš primere kako treba da dodaš svoje zaposlene.",
  required_fields_employees:
    "Polja: ime, prezime, email, razred i lokacije su obavezna i ne smeju biti prazna.",
  step2_title_bonuses: "Popuni fajl sa bonusima",
  step2_text_3:
    "Nakon što popuni .csv šablon sa zaposlenima, možete ga popuniti bonusima.",
  bonus_type_info:
    "BonusType može imati jednu od sledećih vrednosti: mesečno, tri_meseca, šest_meseci ili godišnje.",
  bonus_start_date_info:
    "Ako uneseš polje BonusAmount, a BonusStartDate je prazan, stavićemo podrazumevani datum kada je CSV otpremljen.",
  bonus_end_date_info:
    "Ako uneseš polje BonusAmount, a BonusEndDate je prazan, stavićemo podrazumevani datum na 3 meseca od dana kada je CSV otpremljen.",
  bonus_type_default_info:
    "Ako uneseš polje BonusAmount, a BonusType je prazan, postavićemo podrazumevani tip kao jednokratni i neobnovljiv.",
  required_fields_bonuses:
    "Ako uneseš BonusStartDate, BonusEndDate ili BonusType, obavezno polje je BonusAmount.",
  update_employees: "Ažuriraj Zaposlene",
  update_employees_info:
    "Da bi ažurirao zaposlene, prvo moraš preuzeti .csv šablon klikom na dugme ispod.",
  "Add Bonuses": "Dodaj bonus",
  of_tokens: "u tokenima",
  subject: "Tema",
  message: "Poruka",
  "Bonus Start Date": "Datum početka bonusa",
  "Bonus End Date": "Datum završavanja bonusa",
  "User is disabled": "Korisnik je onemogućen",
  resend_ver_link: "Pošalji verifikacioni link",
  add_bonus_budget: "Dodaj bonus budžet",
  enable_user: "Omogući korisnika",
  Gender: "Pol",
  "Birth date": "Datum rođenja",
  "Enroll date": "Datum upisa",
  Relationship: "Status veze",
  Team: "Tim",
  Bonuses: "Bonusi",
  Contact: "Kontakt",
  Details: "Detalji",
  Rank: "Rang",
  Surname: "Prezime",
  Birthday: "Datum rođenja",
  "Unique ID": "Jedinstveni ID",
  "Branch Code": "Šifra filijale",
  disabled_user_info: "Ovaj korisnik je trenutno onemogućen.",
  Info: "Informacije",
  Position: "Pozicija",
  Family: "Porodica",
  "Monthly tokens": "Mesečni budžet",
  "Enable HR Role": "Omogući HR ulogu",
  "Edit Employee": "Ažuriraj zaposlenog",
  "Benefit Group": "Benefit grupa",
  Expiration: "Istek",
  Provider: "Provajder",
  "Disable User": "Onemogući korisnika",
  choose_disabling_date: "Odaberi kada želiš da korisnik bude obrisan.",
  immediate_delete: "Ako želiš da ga obrišeš odmah, odaberi današnji datum.",
  Disable: "Onemogući",
  employees: "zaposlene",
  delete_employees: "Odaberi kada želite da",
  to_be_deleted: "budu obrisani.",
  "Rollover duration (cycles)": "Trajanje prenosa (ciklusi)",
  "Rollover resets on": "Prenos se obnavlja na",
  turned_on_rollover_info:
    "Ako je ovo uključeno, omogućava prenosenje neiskorišćenog budžeta svakog korisnika u naredni mesec.",
  max_budget_info:
    "Na ovaj način možeš maksimizirati korišćenje vašeg budžeta za beneficije.",
  "Scheduled rollover deletion": "Zakazano brisanje prenosa",
  "Add Tier": "Dodaj razred",
  "Rollover is enabled": "Prenos je omogućen",
  "Rollover is disabled": "Prenos je onemogućen",
  "tier settings": "Podešavanja razreda",
  "company brand settings": "Podešavanja brenda kompanije",
  "Add logo": "Dodaj logo",
  Remove: "Ukloni",
  "Company logo": "Logo kompanije",
  upload_logo_info: "Postavi logo sa pejzažnom orijentacijom (min 100x100px)",
  benefiti_welcome: "Dobrodošli na platformu Benefiti.rs!",
  first_time_setup: "Ovo je vaše prvo podešavanje kompanije.",
  add_tiers_info:
    "Dodaj onoliko razreda koliko ti je potrebno, imenuj ih i odluči koliko tokina se mesečno dodeljuje svakom razredu. Ovo možeš kasnije izmeniti!",
  "Edit Tier": "Ažuriraj razred",
  empty_refunds: "Još uvek nema refundacija.",
  Rejected: "Odbijeno",
  Accepted: "Prihvaćeno",
  Pending: "Na čekanju",
  "Show All": "Prikaži sve",
  Status: "Status",
  Branch: "Filijala",
  selected: "odabrano",
  Accept: "Prihvati",
  or: "ili",
  Decline: "Odbij",
  employee: "Zaposleni",
  Price: "Cena",
  Date: "Datum",
  "Auto Accept": "Automatski prihvati",
  "Auto Decline": "Automatski odbij",
  Type: "Tip",
  "Single Refund": "Jedna refundacija",
  Image: "Slika",
  Activity: "Aktivnost",
  Companies: "Kompanije",
  Users: "Korisnici",
  Notifications: "Obaveštenja",
  show_rollover_dates: "Prikaži datume resetovanja prenosa",
  rollover_resets: "Resetovanje prenosa",
  no_rollover: "Ova kompanija nema prenos",
  show_usage_report: "Prikaži izveštaj o korišćenju",
  "Select company": "Odaberi kompaniju",
  unique_user_sub: "Jedinstvene pretplate korisnika",
  "Select Date": "Odaberi datum",
  "Choose month": "Odaberi mesec",
  filter_by_companies: "Filtriraj po kompanijama",
  filter_by_roles: "Filtriraj po ulogama",
  prev_day: "Prethodni dan",
  next_day: "Sledeći dan",
  no_activity_logs: "Nema zapisa za ",
  "Company tags": "Oznake kompanije",
  "Benefit tags": "Oznake benefita",
  "Add Company tag": "Dodaj oznaku kompanije",
  benefit_group_cat: "Kategorije grupa benefita",
  add_benefit_group_cat: "Dodaj kategoriju grupe benefita",
  add_cities: "Dodaj gradove",
  Cities: "Gradovi",
  edit_company_tag: "Izmeni oznaku kompanije",
  add_benefit_tag: "Dodaj oznaku benefita",
  edit_benefit_tag: "Izmeni oznaku benefita",
  add_category: "Dodaj kategoriju",
  edit_category: "Izmeni kategoriju",
  "Reminders & Instructions": "Podsetnici i uputstva",
  send_budget_remainders: "Pošalji podsetnike za budžet",
  send_usage_instructions: "Pošalji uputstva za korišćenje",
  custom_email_notif: "Prilagođena e-mail obaveštenja",
  send_message_to_other_usr: "Pošalji poruku korisnicima odabranih kompanija",
  choose_comp_as_receivers: "Odaberi kompanije kao primaoce",
  send_message: "Pošalji poruku",
  Providers: "Provajderi",
  add_provider: "Dodaj provajdera",
  download_vendor_report: "Preuzmi izveštaj provajdera",
  provider_enabled: "Provajder je omogućen",
  provider_disabled: "Provajder je onemogućen",
  edit_provider: "Ažuriraj provajdera",
  add_benefit_group: "Dodaj benefit grupu",
  Company: "Kompanija",
  Remote: "Remote",
  publish_ben_group: "Objavi grupu benefita",
  save_ben_gr_as_draft: "Sačuvaj grupu benefita kao nacrt",
  Draft: "Nacrt",
  vouch_price: "Cena vaučera",
  edit_bg: "Ažuriraj benefit grupu",
  add_benefit: "Dodaj benefit",
  Quantity: "Količina",
  short_name: "Kratko ime",
  short_name_info: "Kratko ime će se koristiti kao prikazno ime u statistici.",
  num_of_installments: "Broj rata",
  VAT: "PDV",
  usg_instr: "Uputstvo za upotrebu",
  add_tag: "Dodaj oznaku",
  submit_tag: "Pošalji oznaku",
  edit_benefit: "Ažuriraj benefit",
  rs: "SR",
  en: "EN",
  sub_monthly: "Pretplaćeno mesečno",
  sub_one_time: "Pretplaćeno jednokratno",
  sub_vouchers: "Pretplaćeni vaučeri",
  Installments: "Rata",
  MCP: "MPU",
  total_spent: "Ukupno potrošeno",
  benefit_preview: "Pregled benefit grupe {{name}}",
  NEW: "NOVO",
  choosen_ben: "Odabrani benefit",
  price_in: "Cena",
  dinars: "RSD",
  tokens: "tokenima",
  global_company_settings: "Globalna podešavanja kompanije",
  "currency is enabled": "Prebacivanje tokena u valutu je uključeno",
  "currency is disabled": "Prebacivanje tokena u valutu je isključeno",
  Hr: "HR",
  HEALTH: "ZDRAVLJE",
  FAMILY: "PORODICA",
  "FOOD & DRINK": "HRANA I PIĆE",
  HOME: "DOM",
  LEISURE: "SLOBODNO VREME",
  "WELLNESS & WELLBEING": "WELLNESS I DOBROBIT",
  "CHARITY & FOUNDATIONS": "DOBROTVORNE ORGANIZACIJE I FONDACIJE",
  "LEARNING & DEVELOPMENT": "UČENJE I RAZVOJ",
  COWORKING: "COWORKING",
  PETS: "KUĆNI LJUBIMCI",
  "SPORT & RECREATION": "SPORT I REKREACIJA",
  MOBILITY: "MOBILNOST",
  DISCOUNTS: "POPUSTI",
  SAVINGS: "ŠTEDNJA",
  TRAVEL: "PUTOVANJA",
  GIFT: "POKLON",
  "IT EQUIPMENT": "IT OPREMA",
  "AVAILABLE IN ENGLISH": "DOSTUPNO NA ENGLESKOM",
  "LOKALNI BENEFITI": "LOKALNI BENEFITI",
  REFUNDACIJA: "REFUNDACIJA",
  "**PARTNER NAGRAĐUJE**": "**PARTNER NAGRAĐUJE**",
  dnd_or: "Prevuci i otpusti sliku ili",
  click_to_upload: "klikni ovde da je otpremiš",
  supported_formats: "Podržani formati su .png, .jpg, .jpeg",
  Draft: "Nacrt",
  delete_benefit_group_alert:
    "Da li sigurno želiš da obrišeš ovu benefit grupu?",
  delete_benefit_alert: "Da li sigurno želiš da obrišeš benefit?",
  benefit_name: "Ime benefita",
  delete_tier_alert: "Da li sigurno želiš da obrišeš ovaj razred?",
  "Total benefits": "Ukupno benefita",
  delete_company_alert: "Da li si siguran da želiš da obrišeš kompaniju?",

  /* Toast Notifications */
  failed_to_get_favorite_benefits: "Neuspešno dobijanje omiljenih benefita.",
  failed_to_remove_from_favorites:
    "Neuspešno uklanjanje iz omiljenih benefita.",
  failed_to_add_to_favorites: "Neuspešno dodavanje u omiljene benefite.",
  failed_to_delete_company: "Brisanje kompanije nije uspelo.",
  failed_to_send_ver_link:
    "Link za verifikaciju nije poslat, kontaktiraj podršku.",
  failed_to_enable_user: "Omogućavanje korisnika nije uspelo.",
  failed_to_login: "Prijava nije uspela.",
  failed_to_reset_password: "Resetovanje lozinke nije uspelo.",
  add_rating: "Moraš izabrati ocenu!",
  failed_to_leave_rating: "Ostavljanje recenzije nije uspelo.",
  failed_to_get_benefit_groups: "Preuzimanje benefit grupa nije uspelo.",
  failed_to_get_used_benefits: "Preuzimanje korišćenih benefita nije uspelo.",
  failed_to_get_sub_benefit_groups:
    "Preuzimanje pretplaćenih benefit_grupa nije uspelo.",
  success_review: "Recenzija uspešno poslata!",
  failed_to_create_user: "Kreiranje korisnika nije uspelo.",
  success_user_create: "Korisnik uspešno kreiran!",
  failed_to_get_companies: "Preuzimanje kompanija nije uspelo.",
  failed_to_get_roles: "Preuzimanje uloga nije uspelo.",
  failed_to_edit_user: "Izmena korisnika nije uspela.",
  failed_to_get_users: "Preuzimanje korisnika nije uspelo.",
  failed_to_accept_policy: "Prihvatanje politike privatnosti nije uspelo.",
  failed_to_delete_user: "Brisanje korisnika nije uspelo.",
  failed_to_get_popular_benefit:
    "Neuspešno dobijanje najpopularnijih benefita.",
  failed_to_get_notifications: "Preuzimanje obaveštenja nije uspelo.",
  failed_to_get_categories: "Preuzimanje kategorija nije uspelo.",
  failed_to_benefit_subscribe: "Pretplata na benefit nije uspela.",
  failed_to_get_employees_tokens: "Preuzimanje budžeta zaposlenih nije uspelo.",
  failed_to_redeem_voucher: "Iskorišćavanje vaučera nije uspelo.",
  failed_to_set_valid_file_format:
    "Nevalidan format datoteke. Molimo da odabereš važeći format.",
  failed_to_mark_as_read: "Označavanje kao pročitano nije uspelo.",
  failed_to_onboard: "Onboarding nije uspeo.",
  failed_to_get_single_benefit_info:
    "Nije moguće preuzeti informacije o pojedinačnom benefitu.",
  failed_to_get_tiers: "Preuzimanje nivoa nije uspelo.",
  select_tiers_warning:
    "Molimo da odabereš razrede zaposlenih za omogućavanje benefit grupe.",
  failed_to_update_benefit_group: "Ažuriranje benefit grupe nije uspelo.",
  failed_to_get_benefit_group_users:
    "Preuzimanje korisnika benefit grupe nije uspelo.",
  success_employee_edit: "Zaposleni uspešno ažuriran!",
  success_add_bonus: "Bonus uspešno kreiran!",
  success_add_employee: "Zaposleni uspešno dodat!",
  select_users_warning: "Molimo vas da odaberete korisnike.",
  success_employees_add: "Zaposleni uspešno kreirani.",
  success_employees_edit: "Zaposleni uspešno ažurirani.",
  failed_to_get_benefit_tags: "Preuzimanje oznaka beneficija nije uspelo.",
  failed_to_get_budget_spending: "Preuzimanje budžeta nije uspelo.",
  failed_to_get_budget_per_categories:
    "Preuzimanje budžeta po kategorijama nije uspelo.",
  failed_to_get_synced_employees:
    "Preuzimanje sinhronizovanih zaposlenih nije uspelo.",
  failed_to_get_tier_budgets: "Preuzimanje budžeta po razredima nije uspelo.",
  failed_to_get_top_benefits: "Preuzimanje top benefita nije uspelo.",
  failed_to_get_total_budgets: "Preuzimanje ukupnih budžeta nije uspelo.",
  failed_to_get_statistics: "Preuzimanje statistika nije uspelo.",
  failed_to_get_company_usage: "Preuzimanje korišćenja kompanije nije uspelo.",
  delete_benefit_group_info:
    "Kako bi obrisao/obrisala ovu benefit grupu, prvo moraš obrisati sve benefite u toj grupi.",
  success_add_benefit: "Benefit uspešno kreiran!",
  failed_to_publish_benefit: "Objavljivanje benefita nije uspelo.",
  success_publish_benefit: "Benefit uspešno objavljen.",
  success_edit_benefit: "Benefit uspešno ažuriran!",
  success_add_company_benefit_group:
    "Kompanijska benefit grupa je uspešno dodata!",
  success_edit_company_benefit_group:
    "Kompanijska benefit grupa je uspešno ažurirana!",
  success_onboarding_finish: "Onboarding je već završen.",
  fill_form_warning: "Molimo te da pravilno popuniš formu.",
  step_1_must_be_filled_warning: "Prvi korak mora biti pravilno popunjen!",
  failed_to_finish_onboarding: "Završetak onboardinga nije uspeo.",
  failed_to_get_token_value: "Preuzimanje vrednosti budžeta nije uspelo.",
  failed_to_get_refund_list: "Preuzimanje liste refundacija nije uspelo.",
  failed_to_accept_refund: "Prihvatanje refundacije nije uspelo.",
  failed_to_get_company_reimbursement_state:
    "Nije moguće preuzeti stanje refundacije kompanije.",
  success_tier_create: "Razred uspešno kreiran!",
  success_tier_edit: "Razred uspešno ažuriran!",
  failed_to_currency_change: "Promena tokena u valutu nije uspela.",
  failed_to_reset_password: "Resetovanje lozinke nije uspelo.",
  failed_to_get_additional_logs: "Preuzimanje dodatnih logova nije uspelo.",
  failed_to_get_user_info: "Nije moguće preuzeti informacije o korisniku.",
  the_company_is_not_in_bonus_budget_warning:
    "UPOZORENJE: Kompanija više nije u bonus budžetu. Bonusi za sve zaposlene traju do kraja ovog meseca!",
  the_company_is_not_in_sso_mode_warning:
    "UPOZORENJE: Kompanija više nije u režimu samo SSO prijavljivanja!",
  failed_to_get_admin_statistics:
    "Nije moguće preuzeti administratorske statistike.",
  failed_to_send_emails: "Slanje mejlova nije uspelo.",
  failed_to_get_providers: "Preuzimanje provajdera nije uspelo.",
  failed_to_get_report: "Preuzimanje izveštaja nije uspelo.",
  provider_delete_info:
    "Kako bi obrisali ovog provajdera, prvo moraš obrisati sve benefit grupe i benefite koje pripadaju provajderu.",
  proved_delete_question:
    "Da li si sigurnan da želiš da obrišeš ovog provajdera?",
  success_add_benefit_group: "Benefit grupa uspešno kreirana!",
  success_edit_benefit_group: "Benefit grupa uspešno ažurirana!",
  success_create_provider: "Provajder uspešno kreiran!",
  success_update_provider: "Provajder uspešno ažuriran!",
  select_benefit_group_warning: "Moraš odabrati benefit grupu!",
  delete_benefit_group_question:
    "Da li si sigurnan da želiš da obrišeš ovu benefit grupu?",
  delete_benefit_group_description:
    "Moguće je da korisnici pretplaćeni na ovu benefit grupu. Ako je obrišeš, automatski će biti odjavljeni.",
  delete_company_tag_question:
    "Da li si siguran da želiš da obrišeš ovu oznaku kompanije?",
  failed_to_delete_company_tag: "Brisanje oznake kompanije nije uspelo.",
  success_delete_company_tag: "Oznaka kompanije uspešno obrisana!",
  failed_to_delete_benefit_tag: "Brisanje oznake beneficije nije uspelo.",
  success_delete_benefit_tag: "Oznaka benefita uspešno obrisana!",
  delete_benefit_group_category_question:
    "Da li si siguran da želiš da obrišeš ovu kategoriju benefit grupe?",
  failed_to_delete_benefit_group_category:
    "Brisanje kategorije benefit grupe nije uspelo.",
  success_delete_benefit_group_category: "Kategorija uspešno obrisana!",
  delete_city_question: "Da li si siguran da želiš da obrišeš ovaj grad?",
  failed_to_delete_city: "Brisanje grada nije uspelo.",
  success_add_tag: "Oznaka benefita uspešno kreirana!",
  success_update_tag: "Oznaka benefita uspešno ažurirana!",
  success_add_city: "Grad uspešno dodat!",
  success_update_city: "Grad uspešno ažuriran!",
  delete_country_question: "Da li si siguran da želiš da obrišeš ovu državu?",
  failed_to_delete_country: "Brisanje države nije uspelo.",
  success_add_country: "Država uspešno dodata!",
  success_update_country: "Država uspešno ažurirana!",
  failed_to_get_companies_benefit_statistics:
    "Preuzimanje statistika beneficija kompanija nije uspelo.",
  failed_to_upload_csv_file: "Otpremanje CSV datoteke nije uspelo.",
  bonus_start_date_disclaimer:
    "Ako ne odaberete datum početka, bonus će biti aktivan od prvog dana sledećeg meseca.",
  bonus_end_date_disclaimer:
    "Ako ne odaberete datum završetka, bonus će po defaultu biti aktivan na period od tri meseca.",
  "Recurring Bonus": "Ponavljajući bonus",
  mcp_period_info: "*Minimalni period ugovora: {{month}} meseca",
  mcp_take_warning:
    "Otkazivanje pretplate neće biti moguće dok ne istekne minimalni period ugovora, a nakon isteka, pretplata će automatski biti ukinuta.",
  mcp_take_info: "*Cena je podeljena na rate kako je prikazano gore.",
  add_company: "Dodaj kompaniju",
  Subscriptions: "Pretplate",
  company_name: "Ime kompanije",
  id_num: "Identifikacioni broj",
  inv_email: "Email za fakture",
  Address: "Adresa",
  enable_vat: "Omogući PDV",
  vat_enable_desc: "Kada označiš polje, kompanija će moći da koristi PDV",
  only_bonus_budget: "Samo budžet za bonuse",
  bonus_budget_desc: "Kada označiš polje, kompanija ima samo budžet za bonuse",
  only_sso_login: "Samo SSO prijava",
  sso_login_desc:
    "Kada označiš polje, HR kompanije ima samo opciju za SSO prijavu",
  test_phase: "Test faza",
  test_phase_desc: "Kada označiš polje, kompanija je u test fazi",
  enable_reimbursement: "Omogući refundaciju",
  enable_reimbursement_desc:
    "Kada označiš polje, kompanija će moći da koristi refundaciju",
  enable_currency: "Omogući valutu",
  enable_currency_desc:
    "Kada označiš polje, kompanija će videti vrednosti u valuti",
  payment_deadline: "Rok za plaćanje",
  company_tags: "Oznake kompanije",
  tag_name: "Naziv oznake",
  add_tag: "Dodaj oznaku",
  upload_logo: "Otpremi logo u png formatu",
  no_image: "Nema slike za prikaz",
  upload_image: "Otpremi sliku",
  "Payment Deadline": "Rok za plaćanje",
  "Invoice email": "Email za fakture",
  Tags: "Oznake",
  edit_company: "Ažuriraj kompaniju",
  search_users: "Pretraga korisnika",
  add_user: "Dodaj korisnika",
  clear_all: "Obriši sve",
  Selected: "Odabrano",
  user_info: "Informacije o korisniku",
  Roles: "Uloge",
  first_name: "Ime",
  last_name: "Prezime",
  formal_mail_flag: "Zastavica za formalni email",
  additional_members_fields: "Dodatna polja za zaposlene",
  Enroll: "Datum upisa",
  Phone: "Telefon",
  Locations: "Lokacije",
  user_disabled: "Korisnik je onemogućen",
  enable_user: "Omogući korisnika",
  Role: "Uloga",
  "Is Verified": "Verifikovan",
  edit_user: "Ažuriraj korisnika",
  voc_name: "Vokativno ime",
  disabled_usr_desc: "Ovaj korisnik je trenutno onemogućen.",
  remaining_tokens_total_tokens: "Preostali budžet / Ukupan budžet",
  number_of_different_benefits:
    "Ukupan broj različitih benefita na koje je korisnik pretplaćen",
  month: "Mesečni",
  "one time": "Jednokratni",
  voucher: "Vaučer",
  subscribed_success_to_mcp_title:
    "Uspešno si se pretplatio/pretplatila na benefit koji ima minimalni period trajanja ugovora.",
  subscribed_success_to_mcp_info:
    "Kada benefit postane aktivan, nećeš moći da ga otkažeš dok ne istekne minimalni period ugovora. Nakon toga, tvoja pretplata će biti automatski otkazana.",
  subscribed_success_to_mcp_info2:
    "Pretplata će trajati {{minimumContractPeriod}} meseci i isteći će",
  Male: "Muški",
  Female: "Ženski",
  Other: "Drugo",
  male: "Muški",
  female: "Ženski",
  other: "Drugo",
  married: "Oženjen/Udata",
  single: "Slobodan/Slobodna",
  divorced: "Razveden/Razvedena",
  Married: "Oženjen/Udata",
  Single: "Slobodan/Slobodna",
  Divorced: "Razveden/Razvedena",
  ben_group_no_ben: "Benefit grupa trenutno nema benefita.",
  empty_list: "Lista je prazna",

  file_to_large: "Veličina fajla je prevelika",
  upload_supported_type: "Molimo vas da otpremite podržani tip slike",
  invalid_file_format: "Neispravan format fajla",
  invalid_image_mime: "Neispravan MIME tip slike",
  internal_server_error: "Interna greška servera",
  not_found_error: "Greška: nije pronađeno",
  token_invalid: "Neispravan token",
  unauthorized: "Neautorizovan pristup",
  forbidden: "Zabranjen pristup",
  sign_in_with_sso: "Morate se prijaviti putem Microsofta",
  conflict: "Sukob",
  invalid_file_field: "Proverite da li je polje za fajl ispravno",
  foreign_key_out_bound: "Uneti strani ključ je izvan dozvoljenih granica",
  something_went_wrong: "Nešto je pošlo po zlu, osvežite stranicu",
  something_went_wrong_csv: "Nešto nije u redu sa otpremanjem CSV fajla",
  email_confirmation: "Morate potvrditi svoj email da biste nastavili!",
  account_disabled: "Vaš nalog je onemogućen.",
  link_expired: "Link je istekao",
  user_exist: "Korisnik već postoji",
  cannot_delete_yourself: "Ne možete obrisati sebe",
  email_is_taken: "Email je već zauzet",
  email_does_not_exist: "Email ne postoji",
  user_not_found: "Korisnik nije pronađen",
  user_details_is_provided: "Podaci o korisniku su već uneti",
  user_has_been_deleted: "Korisnik je obrisan",
  incorrect_credentials: "Neispravni podaci za prijavu",
  incorrect_password: "Neispravna lozinka",
  email_already_verified: "Email je već verifikovan",
  email_verification_failed: "Verifikacija emaila nije uspela",
  invalid_email_address: "Neispravna email adresa",
  cannot_upload_file: "Fajl ne može biti otpremljen",
  could_not_create_directory: "Nije moguće kreirati potrebni direktorijum",
  cannot_find_tag: "Tag nije pronađen",
  cannot_find_company: "Kompanija nije pronađena",
  cannot_find_tier: "Nivo nije pronađen",
  cannot_find_company_id: "ID kompanije nije pronađen",
  must_assign_employees_to_new_tiers:
    "Nivo sadrži zaposlene. Zaposleni moraju biti dodeljeni novim nivoima pre izmene",
  pib_must_be_unique: "PIB mora biti jedinstven",
  id_must_be_unique: "Identifikacioni broj mora biti jedinstven",
  tier_name_already_exist: "Naziv nivoa već postoji",
  failed_update_provider: "Ažuriranje provajdera nije uspelo",
  failed_delete_provider:
    "Morate prvo obrisati sve grupe beneficija unutar ovog provajdera",
  benefit_category_not_found: "Kategorija beneficija nije pronađena",
  cannot_find_benefit_group: "Grupa beneficija nije pronađena",
  failed_delete_benefit_group:
    "Morate prvo obrisati sve beneficije u ovoj grupi beneficija",
  failed_delete_benefit_category:
    "Morate prvo obrisati sve grupe beneficija u ovoj kategoriji",
  cannot_find_benefit: "Beneficija nije pronađena",
  failed_delete_benefit:
    "Ova beneficija ne može biti obrisana dok ima aktivne pretplate",
  cannot_find_employee: "Zaposleni nije pronađen",
  is_not_employee: "Samo zaposleni imaju dostupne tokene",
  already_subscribed: "Već ste pretplaćeni na ovu beneficiju",
  already_unsubscribed: "Već ste odjavljeni sa ove beneficije",
  benefit_unavailable_for_user_location:
    "Ova beneficija nije dostupna na vašoj lokaciji",
  company_blocked: "Kompanija je privremeno blokirana. Kontaktirajte podršku",
  company_disabled: "Kompanija je onemogućena. Kontaktirajte podršku",
  company_test_phase: "Kompanija je u testnoj fazi. Kontaktirajte podršku",
  token_restriction: "Nemate dovoljno tokena za ovu akciju",
  base_budget_token_restriction:
    "Nemate dovoljno osnovnog budžeta za pokrivanje cene ove beneficije",
  employee_end_of_month_restriction:
    "Izmene na kraju meseca trenutno nisu dozvoljene. Možete ih izvršiti početkom sledećeg meseca",
  sync_enabled: "Korisnici ne mogu vršiti izmene tokom perioda sinhronizacije",
  subscribe_on_time: "Ne možete se pretplatiti dok ste u procesu deaktivacije",
  benefit_removed_restriction:
    "Beneficija je uklonjena ili nivo kojem pripadate više nema pristup ovoj beneficiji",
  benefit_inactive: "Ova beneficija je neaktivna",
  benefit_subscribe_restriction:
    "Ne možete biti pretplaćeni na više od jedne beneficije/vaučera u istoj grupi beneficija. Morate se prvo odjaviti, a zatim izabrati novu beneficiju/vaučer",
  unsubscribe_from_benefit_with_mcp_restriction:
    "Ne možete se odjaviti sa beneficije koja ima minimalni period ugovora dok ne istekne",
  remove_city_from_employee_restriction:
    "Ne možete ukloniti lokaciju zaposlenog u drugoj polovini meseca",
  failed_create_employee:
    "Nešto je pošlo po zlu prilikom kreiranja zaposlenog. Molimo vas da pokušate ponovo",
  hr_time_restriction: "Ova akcija nije dostupna u drugoj polovini meseca",
  company_blocked_hr: "Kompanija je blokirana. Kontaktirajte podršku",
  cannot_find_token: "Token ne postoji",
  failed_remove_employee:
    "Zaposleni ne može biti uklonjen zbog aktivnih beneficija!",
  benefit_price_change_restriction:
    "Ne možete promeniti cenu beneficije u drugoj polovini meseca",
  benefit_quantity_error: "Nema dovoljno dostupnih stavki",
  password_required: "Lozinka je obavezna",
  invalid_email: "Neispravan format emaila",
  invalid_password: "Neispravna lozinka",
  password_min_length: "Minimalna dužina lozinke je 8 karaktera",
  first_name_should_not_be_empty: "Ime ne sme biti prazno",
  last_name_should_not_be_empty: "Prezime ne sme biti prazno",
  January: "Januar",
  February: "Februar",
  March: "Mart",
  April: "April",
  May: "Maj",
  June: "Jun",
  July: "Jul",
  August: "Avgust",
  September: "Septembar",
  October: "Oktobar",
  November: "Novembar",
  December: "Decembar",
  m_users: "korisnici",
  j_users: "korisnik",
  m_sub: "pretplaćeni",
  j_sub: "pretplaćen",
  earlier: "Nedavno",
  on_something: "na",
  active_until: "Aktivan do",
  active_from: "Aktivan od",
  Active: "Aktivan",
  bg_successfully_deleted: "Benefit grupa je uspešno obrisana!",
  tier_successfully_deleted: "Razred je uspešno obrisan!",
  bg_status_changed: "Status benefit grupe je promenjen!",
  mark_as_read_successfully: "Uspešno označeno kao pročitano!",
  invalid_file_name: "Nevažeće ime fajla",
  published_successfully_bg: "Benefit grupa je uspešno objavljena!",
  ben_successfully_deleted: "Benefit je uspešno obrisan!",
  ben_tag_successfully_deleted: "Oznaka benefita je uspešno obrisana!",
  published_successfully_ben: "Benefit je uspešno objavljen!",
  deleted_successfully_company: "Kompanija je uspešno obrisana!",
  deleted_successfully_tag: "Oznaka je uspešno obrisana!",
  saved_successfully_changes: "Promene su uspešno sačuvane!",
  saved_successfully_rollover_budget: "Budžet za prenos je uspešno sačuvan!",
  deleted_successfully_company_theme: "Tema kompanije je uspešno obrisana!",
  request_submitted: "Zahtev je podnet!",
  reset_successfully_info: "Informacije su uspešno resetovane!",
  sent_successfully_verification_link:
    "Link za verifikaciju je uspešno poslat!",
  email_verified: "Email je verifikovan!",
  sync_successfully_benefit: "Benefit je uspešno sinhronizovan!",
  upload_successful: "Uspešno otpremljeno!",
  add_successful_to_fav: "Benefit je uspešno dodat u omiljene!",
  removed_successful_from_fav: "Benefit je uspešno uklonjen iz omiljenih!",
  delete_successfully_employee: "Zaposleni je uspešno obrisan!",
  delete_successfully_provider: "Provajder je uspešno obrisan!",
  accept_successfully_refund: "Povraćaj sredstava je uspešno prihvaćen!",
  rejected_successfully_refund: "Povraćaj sredstava je uspešno odbijen!",
  accept_reject_fail_refund:
    "Neki povraćaji sredstava nisu mogli biti prihvaćeni zbog nedovoljnih sredstava kod pojedinih korisnika.",
  all_notif_read: "Sve notifikacije su pročitane",
  assign_failed_user_to_role_nonexisting:
    "Ne može se dodeliti uloga korisniku koji je nema ponudjenu",
  role_nonexisting: "Uloga ne postoji",
  accepted_successfully_privacy_policy:
    "Politika privatnosti je uspešno prihvaćena!",
  check_email: "Proverite svoj email",
  password_changed_successfully: "Lozinka je uspešno promenjena",
  email_sent: "Email-ovi su poslati!",
  onboarding_finished: "Onboarding je završen!",
  user_deleted: "Korisnik je obrisan!",
  user_disabled: "Korisnik je onemogućen!",
  user_enabled: "Korisnik je omogućen!",
  user_no_role: "Korisnik nema ulogu",

  subj_min_5_char: "Naslov mora imati najmanje 5 karaktera",
  message_min_5_char: "Poruka mora imati najmanje 5 karaktera",
  recipient_min_1_company: "Primalac mora imati najmanje 1 kompaniju",
  name_too_long: "Ime je predugačko",
  html_tag_ban: "HTML tag nije dozvoljen",
  too_long: "Predugačko",
  too_short: "Prekratko",
  all_caps_ban: "Velika slova nisu dozvoljena",
  add_voucher_please: "Molimo dodaj vaučer",
  please_fill_all_fields: "Moraš popuniti sva polja",
  minimum_token_value: "Minimalna vrednost za cenu tokena je 1",
  token_price_should_be_divisible_by_installments:
    "Cena tokena mora biti deljiva sa brojem rata",
  must_have_9_digits: "Mora imati 9 cifara",
  must_have_8_digits: "Mora imati 8 cifara",
  min_8_chars: "Najmanje 8 karaktera",
  must_contain_a_number: "Mora sadržati broj",
  must_contain_letters: "Mora sadržati slova",
  no_match: "Nema podudaranja",
  minimum_1_tier_creation: "Mora biti kreiran najmanje 1 nivo",
  tier_is_created: "Ime razreda je zauzeto",
  must_be_divisible_by_20: "Mora biti deljivo sa 20",
  file_required: "Fajl je obavezan",
  only_csv_upload: "Možeš otpremiti samo .csv format fajla",
  min_quantity_requirement: "Minimalna vrednost za količinu je 1",
  popup_window_error: "Greška sa iskačućim prozorom! Molimo pokušaj ponovo",
  user_cancelled: "Korisnik je otkazao! Molimo pokušaj ponovo",
  token_renewal_error:
    "Greška pri obnavljanju tokena! Molimo ponovo se autentifikuj",
  login_hint_error: "Greška pri prijavi! Molimo ponovo se autentifikuj",
  interaction_in_progress:
    "Interakcija je u toku! Molimo te da ne zatvaraš iskačući prozor",
  browser_auth_error:
    "Greška pri autentifikaciji u pregledaču! Molimo pokušaj ponovo",
  unexpected_error_message:
    "Došlo je do neočekivane greške! Molimo pokušaj ponovo",
  no_uploaded_images: "Nijedna slika nije otpremljena.",
  loading_images_error: "Greška pri učitavanju slika.",
  images_are_loading_info:
    "Slike se preuzimaju, molimo budite strpljivi. Može trajati do 15-20 minuta u zavisnosti od veličine fajla."
};

const RESOURCES = { ENG: ENG, RS: RS };

export default RESOURCES;
