import i18next from "i18next";

const SUPPORTED_FORMATS = ["csv"];

export const AddEmployeesWithCSVValidations = ({ csvFile }) => {
  const errors = {};

  if (!csvFile) {
    errors.csvFile = i18next.t("required_field");
  } else if (
    csvFile &&
    csvFile.name &&
    !SUPPORTED_FORMATS.includes(csvFile.name.split(".").pop())
  ) {
    errors.csvFile = i18next.t("only_csv_upload");
  }

  return errors;
};
