import React, { useEffect, useState } from "react";
import CustomBudgetsStyles from "./CustomBudgetsStyles";
import { useSelector } from "react-redux";
import { Modal } from "../modal";
import { apiService } from "../../services/api/api.service";
import { PngPhoto } from "../../services/global.entities";
import { getUrl } from "../../services/imageHandler";
import { March8 } from "./March8/March8";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";

export const CustomBudgets = () => {
  const { companyId } = useSelector(state => state.app.user);
  const { pathname } = useLocation();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(true);
  const [companyInfo, setCompanyInfo] = useState(null);

  const [customBudgetData, setCustomBudgetData] = useState([]);

  const getCustomBudgetInfo = async () => {
    try {
      const response = await apiService.get(`/custom-budgets`);

      setCustomBudgetData(response.data.items);
    } catch (error) {
      console.error(error, "Unable to getCustomBudgetInfo");
    }
  };

  const getCompanyInfo = async companyId => {
    try {
      const response = await apiService.get(`/company/${companyId}`);

      const companyPhoto = new PngPhoto(response.data.Photo);
      const companyLogo = getUrl(companyPhoto, "url3x");
      setCompanyInfo({ logo: companyLogo || null, name: response.data.name });
    } catch (error) {
      console.error(error, "Unable to getCompanyInfo");
    }
  };

  useEffect(() => {
    getCustomBudgetInfo();

    if (!companyId) return;
    getCompanyInfo(companyId);
  }, [companyId]);

  const handleClose = () => {
    setIsOpen(false);

    history.push("/");
  };

  return (
    <>
      {isOpen && companyInfo && (
        <Modal closeModal={handleClose}>
          <CustomBudgetsStyles>
            <div className="custom-budget">
              <div className="custom-budget__header">
                <March8 content="header" data={companyInfo} />
              </div>
              {customBudgetData.length > 0 && (
                <section className="custom-budget__body">
                  <March8
                    content="body"
                    data={{
                      ...companyInfo,
                      customBudgetData,
                      getCustomBudgetInfo
                    }}
                  />
                </section>
              )}
            </div>
          </CustomBudgetsStyles>
        </Modal>
      )}
    </>
  );
};
