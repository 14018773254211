import styled from "styled-components";

const ProviderToggleButtonComponentStyles = styled.div`
  display: flex;
  align-items: center;

  .activationCheckBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    border-radius: 16px;
    border: 2px solid;
    border-color: ${({ isActive }) => {
      if (isActive) {
        return "#41c264";
      }
      return "#ce1212";
    }};
    padding: 5px;
    margin-right: 8px;
    position: relative;
  }

  .dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ isActive }) => {
      if (isActive) {
        return "#41c264";
      }
      return "#ce1212";
    }};
    margin: ${({ isActive }) => {
      if (isActive) {
        return "0 0 0 5px";
      }
      return "0 5px 0 0";
    }};
    cursor: pointer;
  }

  .activityOnText {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #41c264;
  }

  .activationOffText {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ce1212;
  }

  .benefitActivityStatusText {
    font-size: 16px;
    font-weight: 500;
    width: 209px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;

export default ProviderToggleButtonComponentStyles;
