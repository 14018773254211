import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { toast } from "react-toastify";
// assets
import { EditIconSvg } from "../../../../assets/Icons";
// components
import List from "../List/List";
import BenefitDetails from "../shared/BenefitDetails/BenefitDetails";
// services
import * as providersService from "../../../../services/api/admin/providers.service";
import * as actionCreators from "../../../../actions/admin/providers.actions";
import { removeCardView, removeHeightOnClick } from "../providersAnimations";
// styles
import SubRowStyles from "./SubRowStyles";
// context
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const SubRow = ({
  provider,
  openModal,
  chosenBenefitGroupId,
  setChosenBenefitGroupId,
  setChosenBenefitId,
  chosenBenefitId,
  deleteBenefitGroup,
  deleteBenefit,
  fetchData
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [chosenBenefits, setChosenBenefits] = useState(undefined);
  const [benefitDetails, setBenefitDetails] = useState(undefined);

  useEffect(() => {
    const chosenBenefitGroup = provider.benefitGroups.find(
      bg => bg.id === chosenBenefitGroupId
    );
    if (chosenBenefitGroup && chosenBenefitGroup.benefits.length > 0) {
      setChosenBenefitId(chosenBenefitGroup.benefits[0].id);
      setChosenBenefits(chosenBenefitGroup.benefits);
      setBenefitDetails(chosenBenefitGroup.benefits[0]);
    } else if (chosenBenefitGroup && chosenBenefitGroup.benefits.length === 0) {
      setChosenBenefitId(null);
      setChosenBenefits(undefined);
      setBenefitDetails(undefined);
    }
  }, [chosenBenefitGroupId, provider]);

  const openEditBenefitGroup = benefitGroupId => {
    openModal("editBenefitGroup");
    setChosenBenefitGroupId(benefitGroupId);
  };

  const openEditBenefit = benefitId => {
    openModal("editBenefit");
    setChosenBenefitId(benefitId);
    setBenefitDetails(
      provider.benefitGroups
        .find(bg => bg.id === chosenBenefitGroupId)
        .benefits.find(benefit => benefit.id === benefitId)
    );
  };

  const openModalPreviewModalAction = benefitGroupId => {
    // openModal("preview");
    setChosenBenefitGroupId(benefitGroupId);
    history.push(`/benefits/preview/${benefitGroupId}`);
  };

  const removeBenefitGroup = async benefitGroup => {
    if (benefitGroup.benefits.length > 0) {
      toast.info(t("delete_benefit_group_info"));
    } else {
      const result = confirm(t("delete_benefit_group_alert"));
      if (result) {
        const response = await providersService.deleteBenefitGroup(
          benefitGroup.id
        );

        if (response.hasError) {
          return toast.error(t(response.error.data.error.message));
        }

        setChosenBenefitGroupId(null);
        deleteBenefitGroup(provider.id, benefitGroup.id);
      }
    }
  };

  const removeBenefit = async benefit => {
    swal({
      title: t("delete_benefit_group_question"),
      text: t("delete_benefit_group_description"),
      buttons: [true, "Delete"],
      dangerMode: true
    }).then(async value => {
      if (value) {
        const response = await providersService.deleteBenefit(benefit.id);

        if (response.hasError) {
          return toast.error(t(response.error.data.error.message));
        }

        deleteBenefit(provider.id, chosenBenefitGroupId, benefit.id);
      }
    });
  };

  const setBenefits = id => {
    const choisenBenefirGroup = provider.benefitGroups.find(bg => bg.id === id);
    setChosenBenefits(choisenBenefirGroup.benefits);
    setChosenBenefitGroupId(id);
  };

  const setBenefit = id => {
    const chosenBenefit = chosenBenefits.find(benefit => benefit.id === id);
    setBenefitDetails(chosenBenefit);
    setChosenBenefitId(id);
  };

  return (
    <SubRowStyles>
      <div className="benefitGroupContainer">
        <div className="testDiv" id={`test${provider.id}`}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                removeHeightOnClick(provider.id);
                removeCardView(provider.id);
              }}
            >
              x
            </div>
          </div>
          <div className="subRowContent">
            <div className="contentContainer">
              <div className="firstTwo">
                <div className="providerDetails">
                  <div
                    style={{ cursor: "pointer" }}
                    className="providerDetailsCard"
                    onClick={() => openModal("editProvider")}
                  >
                    <div className="providerTitle">
                      <h2>{provider.name}</h2>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal("editProvider")}
                      >
                        <EditIconSvg />
                      </div>
                    </div>
                    <div className="providerDescription">
                      <p className="providerDescriptionText">
                        {provider.summary}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="benefitGroupsContainer">
                  <div>
                    {provider.benefitGroups && (
                      <List
                        list={provider.benefitGroups}
                        chosenItem={chosenBenefitGroupId}
                        itemOnClick={setBenefits}
                        editButton={openEditBenefitGroup}
                        removeButton={removeBenefitGroup}
                        openModalPreviewModalAction={
                          openModalPreviewModalAction
                        }
                        isBenefitGroup
                      />
                    )}
                    <div
                      className="addBenefitGroup"
                      onClick={() => openModal("addBenefitGroup")}
                    >
                      <div className="circle">
                        <p>+</p>
                      </div>
                      <p className="buttonText">{t("add_benefit_group")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="secondTwo">
                <div className="benefitsContainer">
                  <List
                    list={chosenBenefits}
                    itemOnClick={setBenefit}
                    chosenItem={chosenBenefitId}
                    editButton={openEditBenefit}
                    removeButton={removeBenefit}
                  />
                  <div
                    className="addBenefitGroup"
                    onClick={() => {
                      if (chosenBenefitGroupId) {
                        openModal("addBenefit");
                      } else {
                        toast.info(t("select_benefit_group_warning"));
                      }
                    }}
                  >
                    <div className="circle">
                      <p>+</p>
                    </div>
                    <p className="buttonText">{t("add_benefit")}</p>
                  </div>
                </div>
                <BenefitDetails
                  animationId={`benefitDetail${provider.id}`}
                  benefitDetails={benefitDetails}
                  animationClassName="benefitDetails"
                  openEditBenefit={() => openEditBenefit(benefitDetails.id)}
                  fetchData={fetchData}
                />
              </div>
            </div>
          </div>
          <div style={{ height: 30 }} />
        </div>
      </div>
    </SubRowStyles>
  );
};

SubRow.propTypes = {
  provider: PropTypes.shape(),
  openModal: PropTypes.func,
  chosenBenefitId: PropTypes.number,
  setChosenBenefitGroupId: PropTypes.func,
  setChosenBenefitId: PropTypes.func,
  chosenBenefitGroupId: PropTypes.number
};

const mapDispatchToProps = dispatch => {
  return {
    deleteBenefitGroup: (providerId, benefitGroupId) =>
      dispatch(actionCreators.deleteBenefitGroup(providerId, benefitGroupId)),
    deleteBenefit: (providerId, benefitGroupId, benefitId) => {
      dispatch(
        actionCreators.deleteBenefit(providerId, benefitGroupId, benefitId)
      );
    }
  };
};

export default connect(undefined, mapDispatchToProps)(SubRow);
