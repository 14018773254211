import React, { useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import StarRatings from "react-star-ratings";

// Styles
// Assets
import Capacity from "../../../../assets/images/capacity-left.svg";
// Components
import {
  ActiveIcon,
  FullHeartIconSVG,
  InfoIconSvg,
  OutlineHeartIconSVG,
  PendingActivationIcon,
  PendingDeactivationIcon,
  TokenIcon
} from "../../../../assets/Icons";
// Services
import { getUrl } from "../../../../services/imageHandler";
import { truncate } from "../../../../services/truncateString";
// Utils
import { isEmpty } from "../../../../services/general.utils";
// Constants
import { LOCAL } from "../../../../builders/benefitGroups/benefitGroup.types";

import { UserRolesKeysEnum } from "../../../../constants/roles.constants";
import BenefitCardStyles from "../../../../components/benefitCard/BenefitCardStyles";
import { toast } from "react-toastify";
import { setListOfFavorites } from "../../../../actions/employee/employeeBenefits.actions";
import { apiService } from "../../../../services/api/api.service";
import {
  transformPriceRangeTokensToPriceRangeRsd,
  transformTokenCurrencyIcons
} from "../../../../utils/transformTokensToRsd";
import { useTranslation } from "react-i18next";
import { statusPreviewTranslation } from "../../../../utils/language/statusPreviewTranslation";

const BenefitCard = ({
  benefitGroupId,
  benefitDetails: {
    photo,
    provider,
    name,
    benefits = [],
    categories,
    status,
    statusPreview,
    taxes,
    cities,
    isRemote,
    type,
    summary,
    doesBenefitGroupHaveMcp,
    isBenefitGroupNew,
    benefitGroupAvailable,
    doesBenefitGroupHaveInstalments,
    doesBenefitGroupHaveQuantity,
    sumRatings,
    numberReviews,
    priceRange
  },
  onClick,
  isMyBenefits = false,
  isHr = false,
  user,
  isPreview = false
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);

  const { listOfFavoriteBenefitsInObject } = useSelector(
    state => state.employeeBenefitsPage.favoriteBenefits
  );

  /**
   * Handles on click event for the benefit card.
   * @returns {Function}
   */
  const handleOnClick = async () => {
    return onClick();
  };

  /**
   * Renders cities on benefit card.
   * If user is hr, and benefit group has more than one city while also being remote, renders remote with no of locations.
   * If user is hr, and benefit group has more than one city, renders first city name and array length - 1.
   * If user is employee and benefit group has remote renders remote and renders first city name and array length - 1.
   * If user is employee, renders first city from his cities and array length - 1.
   * If there is only one city in cities list, render its name.
   * @param {City[]} cities
   */
  const renderCities = cities => {
    if (isEmpty(cities) && type === LOCAL) return [];

    if (
      (isHr || user.authenticatedRole?.key !== UserRolesKeysEnum.MEMBER) &&
      cities.length >= 1 &&
      isRemote
    ) {
      return <p className="cities">Remote, + {cities.length}</p>;
    }

    if (
      (isHr || user.authenticatedRole?.key !== UserRolesKeysEnum.MEMBER) &&
      cities.length > 1
    ) {
      return (
        <p className="cities">
          {cities[0].name}, + {cities.length - 1}
        </p>
      );
    }

    if (isEmpty(cities) && isRemote) return <p className="cities">Remote</p>;

    if (
      user.authenticatedRole?.key === UserRolesKeysEnum.MEMBER &&
      !isEmpty(user.employee.cities)
    ) {
      const employeeCity = user.employee?.cities?.find(city =>
        cities.find(ct => ct.id === city.id)
      );
      if (!!employeeCity && cities.length > 1 && !isRemote) {
        return (
          <p className="cities">
            {employeeCity.name}, + {cities.length - 1}
          </p>
        );
      }

      if (!!employeeCity && cities.length > 1 && isRemote) {
        return (
          <p className="cities">
            Remote, {employeeCity.name}, + {cities.length - 1}
          </p>
        );
      }

      if (!!employeeCity && cities.length === 1 && isRemote) {
        return <p className="cities">Remote, {employeeCity.name}</p>;
      }

      if (!isEmpty(cities) && isEmpty(employeeCity) && isRemote) {
        return <p className="cities">Remote, + {cities.length}</p>;
      }

      if (cities.length > 1) {
        return (
          <p className="cities">
            {cities[0].name}, + {cities.length - 1}
          </p>
        );
      }
    }

    return <p className="cities">{cities[0].name}</p>;
  };

  /**
   * Renders benefit group categories to card.
   * @returns {HTMLDivElement}
   */
  const renderCategories = () => {
    if (isEmpty(categories)) return [];

    if (!isEmpty(categories) && categories.length > 1) {
      return (
        <p className="categoryText">
          {categories[0].name}, + {categories.length - 1}
        </p>
      );
    }

    return <p className="categoryText">{categories[0].name}</p>;
  };

  /**
   * Returns icon component based on status.
   * @param {string} status
   * @returns {import("react").ReactComponentElement}
   */
  const renderStatusIcon = status => {
    const statusObject = {
      Active: <ActiveIcon />,
      "Pending Activation": <PendingActivationIcon />,
      "Pending Deactivation": <PendingDeactivationIcon />
    };

    return statusObject[status];
  };

  /**
   * Calculates & returns total quantity remaining.
   * @returns {number}
   */
  const calculateTotalQuantityRemaining = () => {
    return benefits?.reduce(
      (total, benefit) => total + benefit.totalQuantityRemaining,
      null
    );
  };
  const quantityRemaining = useMemo(() => calculateTotalQuantityRemaining(), [
    benefits
  ]);

  /**
   * Calculates & returns tooltip position relative on top left border.
   * @param {number} left
   * @param {number} top
   * @param {HTMLDivElement} node
   * @returns {number, number}
   */
  const overrideTooltipPosition = (
    { top, left },
    currentEvent,
    currentTarget,
    node
  ) => {
    const d = document.documentElement;
    left = Math.min(d.clientWidth - node.clientWidth, left);
    top = Math.min(d.clientHeight - node.clientHeight, top);
    left = Math.max(0, left);
    top = Math.max(0, top);

    return { top, left };
  };

  const refetchFavoriteBenefits = async () => {
    try {
      const response = await apiService.get("/favorites");
      const listOfFavorite =
        response.data?.items.length > 0
          ? response.data.items.map(item => item.group)
          : [];

      dispatch(setListOfFavorites(listOfFavorite));
    } catch (error) {
      console.log(error);
      toast.error(t("failed_to_get_favorite_benefits"));
    }
  };

  const handleRemoveFromFavorites = async e => {
    e.stopPropagation();

    try {
      setIsDisabled(true);
      const removeFromBenefitsResponse = await apiService.delete(
        `/favorites/remove/benefits/${benefitGroupId}`
      );

      await refetchFavoriteBenefits();
      toast.success(t(removeFromBenefitsResponse.data.message));
    } catch (error) {
      console.log(error);
      toast.error(t("failed_to_remove_from_favorites"));
    } finally {
      setIsDisabled(false);
    }
  };

  const handleAddToFavorites = async e => {
    e.stopPropagation();

    try {
      setIsDisabled(true);

      const removeFromBenefitsResponse = await apiService.post(
        `/favorites/add/benefits/${benefitGroupId}`
      );

      await refetchFavoriteBenefits();
      toast.success(t(removeFromBenefitsResponse.data.message));
    } catch (error) {
      toast.error(t("failed_to_add_to_favorites"));
    } finally {
      setIsDisabled(false);
    }
  };

  const statusThatNeedToBeTranslated = statusPreview
    ?.split("|")
    .map(item => statusPreviewTranslation(item, t))
    .join(" ");

  return (
    <BenefitCardStyles id="benefitCard" status={status} onClick={handleOnClick}>
      <div className="benefitCardWrapper">
        <div className="imgContainer">
          <img
            src={getUrl(photo, "url5x")}
            alt={name}
            className="benefitImage"
            crossOrigin="anonymous"
          />
          {isBenefitGroupNew && <span className="newIndicator">NEW</span>}
        </div>
        <div className="topContent">
          <div className="providerImageOuterCircle">
            <div className="providerImageInnerCircle">
              <img
                src={provider?.photo ? getUrl(provider?.photo) : getUrl(photo)}
                alt="Provider logo"
                className="providerLogo"
                crossOrigin="anonymous"
              />
            </div>
          </div>
          <div className="titleAndTokensContainer">
            <h2 className="title">{name}</h2>
            <div className="categoryTag">{renderCategories()}</div>
          </div>
          <div className="ratingContainer">
            <div className="starContainer">
              <span className="total">
                {" "}
                {numberReviews
                  ? (sumRatings / numberReviews).toFixed(1)
                  : 0}{" "}
              </span>
              <StarRatings
                numberOfStars={1}
                rating={numberReviews ? sumRatings / numberReviews : 0}
                starDimension="12px"
                starSpacing="0px"
                starEmptyColor="#EAEAEA"
                starRatedColor="#FFD402"
              />
              <span className="reviewsNumber"> ({numberReviews} ratings)</span>
            </div>
          </div>
        </div>
        <div
          className={isHr ? "hrDescriptionContainer" : "descriptionContainer"}
        >
          {!isEmpty(taxes) && <p className="taxes">{truncate(taxes, 60)}</p>}
          <p className="providerDescription">{truncate(summary, 89)}</p>
        </div>
        <div
          className={isHr ? "hr-locations-container" : "locations-container"}
        >
          {cities && renderCities(cities)}
        </div>
        <div className="priceAncCapacityContainer">
          <div className="tokensContainer">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="tokensContent">
                {transformTokenCurrencyIcons()}
                <p className="price">
                  {transformPriceRangeTokensToPriceRangeRsd(priceRange)}
                </p>
              </div>
              {isPreview ||
                (doesBenefitGroupHaveMcp && (
                  <>
                    <div
                      className="mcpContainer"
                      data-tip
                      data-for="mcpTooltip"
                    >
                      <span>MCP</span>
                    </div>
                    <ReactTooltip
                      id="mcpTooltip"
                      place="bottom"
                      effect="solid"
                      className="mcpTooltip"
                      backgroundColor="black"
                      borderColor="black"
                      arrowColor="black"
                      overridePosition={overrideTooltipPosition}
                    >
                      <span style={{ color: "black" }}>
                        {t("mcp_take_warning")}
                      </span>
                    </ReactTooltip>
                  </>
                ))}
              {doesBenefitGroupHaveInstalments && (
                <>
                  <a
                    data-tip
                    data-for="instalmentsInfo"
                    style={{ cursor: "pointer" }}
                  >
                    <InfoIconSvg />
                  </a>
                  <ReactTooltip
                    id="instalmentsInfo"
                    place="top"
                    effect="solid"
                    className="tooltip"
                    backgroundColor="#1568bf"
                    borderColor="#1568bf"
                    arrowColor="#1568bf"
                  >
                    <span className="spanText" style={{ color: "black" }}>
                      This benefit group has a price split in instalments.
                    </span>
                  </ReactTooltip>
                </>
              )}
            </div>
            {!isHr && (
              <div>
                {!!listOfFavoriteBenefitsInObject[benefitGroupId] ? (
                  <button
                    disabled={isDisabled}
                    className="favoriteButton"
                    onClick={handleRemoveFromFavorites}
                  >
                    <FullHeartIconSVG />
                  </button>
                ) : (
                  <button
                    disabled={isDisabled}
                    className="favoriteButton"
                    onClick={handleAddToFavorites}
                  >
                    <OutlineHeartIconSVG />
                  </button>
                )}
                {!isEmpty(quantityRemaining) && doesBenefitGroupHaveQuantity && (
                  <div className="capacityContainer" style={{ marginLeft: 24 }}>
                    <img
                      src={Capacity}
                      alt="capacity"
                      crossOrigin="anonymous"
                    />
                    <p className="price">{quantityRemaining} left</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isHr && (
          <div className="enabledStatus">
            {benefitGroupAvailable ? (
              <div className="enabled">
                ON <div className="green-dot" />
              </div>
            ) : (
              <div className="disabled">
                OFF <div className="red-dot" />
              </div>
            )}
          </div>
        )}
        {isMyBenefits
          ? status && (
              <div className="activeContainer">
                {renderStatusIcon(status)}
                <p className="activeText">{statusThatNeedToBeTranslated}</p>
              </div>
            )
          : status && (
              <div className="activeContainer">
                {renderStatusIcon(status)}
                <p className="activeText">{statusThatNeedToBeTranslated}</p>
              </div>
            )}
      </div>
    </BenefitCardStyles>
  );
};

BenefitCard.propTypes = {
  benefitDetails: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    statusPreview: PropTypes.string,
    photo: PropTypes.shape({
      url: PropTypes.string
    }),
    provider: PropTypes.shape({
      photo: PropTypes.shape({
        url: PropTypes.string
      }),
      description: PropTypes.string
    }),
    benefitMin: PropTypes.number,
    benefitMax: PropTypes.number,
    capacity: PropTypes.number,
    takenBenefits: PropTypes.number,
    Benefit: PropTypes.arrayOf(PropTypes.shape({})),
    isPremium: PropTypes.bool,
    sumRatings: PropTypes.number,
    numberReviews: PropTypes.number
  }),
  onClick: PropTypes.func,
  isHr: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    user: state.app.user
  };
};

export default connect(mapStateToProps, undefined)(BenefitCard);
