import React, { useCallback, useEffect, useState } from "react";

import { March8Cards } from "./March8Cards";
import { FlagCurrency } from "../../../assets/Icons";
import { useSelector } from "react-redux";
import { apiService } from "../../../services/api/api.service";
import { toast } from "react-toastify";

export const March8Body = ({ data }) => {
  const { customBudgetData, getCustomBudgetInfo } = data;

  const { id: userId } = useSelector(state => state.app.user);

  const [budgetData, setBudgetData] = useState({
    totalBudget: 0,
    spentBudget: 0
  });

  const staticCardData = [
    {
      id: 1,
      title: "Lilly poklon vaučer",
      description: `Kompanija Benefiti vam poklanja Lilly poklon vaučer u vrednosti od ${budgetData.totalBudget} dinara.`,
      price: 4000,
      category: "Poklon za 8. mart",
      image:
        "https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/demo/lily-logo.png",
      location: "Remote"
    },
    {
      id: 2,
      title: "Jasmin poklon vaučer",
      description: `Kompanija Benefiti vam poklanja Jasmin poklon vaučer u vrednosti od ${budgetData.totalBudget} dinara.`,
      price: 4000,
      category: "Poklon za 8. mart",
      image:
        "https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/demo/jasmin-logo.png",
      location: "Remote"
    },
    {
      id: 3,
      title: "DM poklon vaučer",
      description: `Kompanija Benefiti vam poklanja DM poklon vaučer u vrednosti od ${budgetData.totalBudget} dinara.`,
      price: 4000,
      category: "Poklon za 8. mart",
      image:
        "https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/demo/dm-logo.png",
      location: "Remote"
    }
  ];

  const subscribeToCustomBenefit = async payload => {
    try {
      const response = await apiService.post(
        "/custom-budgets/subscribe",
        payload
      );
      getCustomBudgetInfo();
      toast.success(response.data.message);
    } catch (error) {
      toast.error(
        error.errorMessage || "Nemate dovoljno sredstava za ovu transakciju."
      );
      console.log(error, "Unable to subscribeToCustomBenefit");
    }
  };

  const [selectedDataBudget, setSelectedDataBudget] = useState([]);

  const handleSelect = useCallback(
    data => {
      let updatedSpentBudget = [...selectedDataBudget];

      // if (updatedSpentBudget.includes(data.id)) {
      //   updatedSpentBudget = updatedSpentBudget.filter(
      //     item => item.cardId !== data.id
      //   );
      // } else {
      updatedSpentBudget.push({ cardId: data.cardId, price: data.price });
      // }

      const payload = {
        userId: userId,
        price: data.price,
        providerId: data.cardId,
        providerDescription: data.cardDescription
      };

      subscribeToCustomBenefit(payload);
    },
    [selectedDataBudget]
  );

  useEffect(() => {
    const totalBudget = customBudgetData[0].totalBudget;
    const spentBudget = customBudgetData.reduce(
      (total, item) => total + Number(item.spentBudget),
      0
    );
    setBudgetData({ totalBudget, spentBudget });

    const spentCardData = customBudgetData.map(customBudgetItem => {
      return {
        cardId: customBudgetItem.providerId,
        price: customBudgetItem.spentBudget
      };
    });

    setSelectedDataBudget([...spentCardData, ...selectedDataBudget]);
  }, [customBudgetData]);

  return (
    <section className="custom-budget__body">
      <p className="custom-budget-body__intro">
        Drage dame, kompanija Benefiti vam je, u vidu poklona za 8. mart,
        priredila sledeće vaučere koje možete izabrati.
      </p>

      <span className="custom-budget-body__budget">
        <span>
          <b>Ukupan Budžet:</b> {budgetData.totalBudget} <FlagCurrency />
        </span>
        <span>
          <b>Preostali Budžet:</b>{" "}
          {budgetData.totalBudget - budgetData.spentBudget} <FlagCurrency />
        </span>
      </span>

      <section className="custom-budget-body__cards">
        {staticCardData.map(card => {
          return (
            <March8Cards
              key={card.id}
              card={card}
              data={data}
              handleSelect={handleSelect}
              selectedDataBudget={selectedDataBudget}
              budgetData={budgetData}
            />
          );
        })}
      </section>
    </section>
  );
};
