import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";
// components
import { SubscribeCountDown, Button } from "../../../components";
// assets
import { WatchIcon } from "../../../assets/Icons";
// services
import { calculateTimeLeft } from "../../../components/timer/countDown.service";
import * as api from "../../../services/api/employee/employeeBenefits.services";
// actions
import * as actionCreators from "../../../actions/app.actions";
import { useTranslation } from "react-i18next";

const SyncModalStyles = styled.div`
  width: 544px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 46px 30px 30px 30px;

  .syncTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    color: #4b4b4b;
  }

  .syncDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    width: 380px;
    text-align: center;
    color: #4b4b4b;
    margin: 30px 0;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }

  @media only screen and (max-width: 420px) {
    .syncTitle {
      font-size: 24px;
      line-height: 24px;
    }

    .syncDescription {
      width: 265px;
    }
  }
`;

const SyncModal = ({ isSync, syncUser }) => {
  const { t } = useTranslation();

  const syncEmployee = async () => {
    const response = await api.syncEmployeeUser();
    if (response.hasError) {
      return toast.error(
        response.errorMessage ? t(response.errorMessage) : "Submit failed."
      );
    }
    toast.success(t(response.data.message));
    syncUser();
  };
  return (
    <SyncModalStyles>
      <h2 className="syncTitle">Submit Benefits</h2>
      <p className="syncDescription">
        Once you submit the data you will not be able to change them until the
        next month. An automatic submit is done on the 26th of each month. You
        can manually submit the data now if you are sure about your choices.
      </p>
      <p className="syncDescription" style={{ margin: 0 }}>
        Time remaining before the next automatic submit:
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <WatchIcon />
        <SubscribeCountDown isSync={isSync} />
      </div>
      {calculateTimeLeft() !== "Submit in progress..." && !isSync && (
        <Button
          onClick={() => syncEmployee()}
          margin="29px 0 0 0"
          width="140px"
        >
          Submit
        </Button>
      )}
    </SyncModalStyles>
  );
};

const mapStateToProps = state => {
  return {
    isSync: state.app.user.isSync
  };
};

const mapDispatchToProps = dispatch => {
  return {
    syncUser: () => dispatch(actionCreators.syncUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncModal);
