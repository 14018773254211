import i18next from "i18next";

export const categoryBudgetConfig = {
  series: [
    {
      name: i18next.t("chosen_month_budget"),
      data: []
    },
    {
      name: i18next.t("previous_month_budget"),
      data: []
    }
  ],
  options: {
    chart: {
      type: "bar",
      height: 430,
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    colors: ["#3361b5", "#24ccb8"],
    tooltip: {
      y: {
        // formatter(val) {
        // 	return `${val} ${renderToString(<TokenIcon height="16" />)}`
        // }
        formatter(val) {
          return `${val} currency`;
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top"
        }
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      style: {
        fontSize: "12px",
        colors: ["#fff"]
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"]
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "left"
    },
    xaxis: {
      categories: []
    }
  }
};
