import React, { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import Clarity from "@microsoft/clarity";

import { userInitalState, userReducer } from "./user.reducer";
import { useUserActions } from "./user.actions";

const UserContext = createContext(userInitalState);
const CLARITY_PROJECT_ID = "q7ofku4hzs";

const UserProvider = ({ children }) => {
  const [user, dispatchUser] = useReducer(userReducer, userInitalState);
  const userActions = useUserActions(dispatchUser);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;

    Clarity.init(CLARITY_PROJECT_ID);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        userActions
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node
};

export { UserProvider, UserContext };
