import i18next from "i18next";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";

export const tierFormValidation = ({ name, description, tokens }) => {
  const errors = {};

  if (!name.trim()) {
    errors.name = i18next.t("required_field");
  } else if (removeSpacesAndHTML(name).length > 20) {
    errors.name = i18next.t("too_long");
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = i18next.t("html_tag_ban");
  }

  if (description && removeSpacesAndHTML(description).length > 100) {
    errors.description = i18next.t("too_long");
  }

  if (isEmpty(tokens)) {
    errors.tokens = i18next.t("required_field");
  }

  return errors;
};
