import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import styled from "styled-components";
// components
import BenefitForm from "./BenefitForm";
// entity
import { LocalBenefitForm } from "../../../../../models/domain";
// services
import { validateHrLocalBenefitCreate } from "../../../../../services/validations/hr/hrLocalBenefitValidation";
import * as actionCreators from "../../../../../actions/hr/localBenefits.actions";
import * as localBenefitsService from "../../../../../services/api/hr/localBenefits.service";
import { useTranslation } from "react-i18next";

const AddBenefitStyles = styled.div`
  .title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    margin: 30px 0;
  }

  .form {
    width: 481px;
    height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const AddBenefit = ({
  closeModal,
  chosenBenefitGroupId,
  addNewBenefitToBenefitGroup
}) => {
  const { t } = useTranslation();
  const handleCreateBenefitError = ({ error }) => {
    const { data } = error;
    if (data) {
      if (data.errors && data.error) {
        if (data.errors.restrict) {
          toast.error(
            `${t(data.error.message)}: ${t(data.errors.restrict.keys[0])}`
          );
        }
        if (data.errors.title) {
          toast.error(
            `${t(data.error.message)}: ${t(data.errors.title.keys[0])}`
          );
        }
        if (data.errors.photo) {
          toast.error(`${t(data.errors.photo.keys[0])}`);
        }
        if (data.errors.expiration) {
          toast.error(
            `${t(data.error.message)}: ${t(data.errors.expiration.keys[0])}`
          );
        }
        if (data.errors.tokenPrice) {
          toast.error(
            `${t(data.error.message)}: ${t(data.errors.tokenPrice.keys[0])}`
          );
        }
        if (data.errors.description) {
          toast.error(
            `${t(data.error.message)}: ${t(data.errors.description.keys[0])}`
          );
        }
        if (data.errors.minimumContractPeriod) {
          toast.error(`${t(data.errors.minimumContractPeriod.keys[0])}`);
        }
        if (data.errors.summary) {
          toast.error(
            `${t(data.error.message)}: ${t(data.errors.summary.keys[0])}`
          );
        }
        if (data.errors.benefitGroupId) {
          toast.error(
            `${t(data.error.message)}: ${t(data.errors.benefitGroupId.keys[0])}`
          );
        }
        if (data.errors.isDraft) {
          toast.error(
            `${t(data.error.message)}: ${t(data.errors.isDraft.keys[0])}`
          );
        }
      } else {
        toast.error(t(data.error.message));
      }
    } else {
      toast.error("Something went wrong!");
    }
  };

  const createNewBenefit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await localBenefitsService.createNewLocalBenefit(
      values,
      chosenBenefitGroupId
    );

    setSubmitting(false);

    if (response.hasError) {
      return handleCreateBenefitError(response);
    }

    addNewBenefitToBenefitGroup(chosenBenefitGroupId, response);
    toast.success(t("success_add_benefit"));
    closeModal();
  };

  const formik = useFormik({
    initialValues: new LocalBenefitForm(),
    validate: validateHrLocalBenefitCreate,
    onSubmit: createNewBenefit
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddBenefitStyles>
      <h3 className="title">{t("add_local_benefit")}</h3>
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <BenefitForm
          buttonText="Create"
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
          handleChange={handleChange}
          handleBlur={handleBlur}
          closeModal={closeModal}
          disableButton={isSubmitting}
        />
      </form>
    </AddBenefitStyles>
  );
};

AddBenefit.propTypes = {
  chosenBenefitGroupId: PropTypes.number,
  closeModal: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    addNewBenefitToBenefitGroup: (benefitGroupId, benefit) =>
      dispatch(
        actionCreators.addNewBenefitToBenefitGroup(benefitGroupId, benefit)
      )
  };
};

export default connect(undefined, mapDispatchToProps)(AddBenefit);
