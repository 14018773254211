import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
// services
import * as usersService from "../../../../services/api/admin/users.service";
import * as hrEmployeeServices from "../../../../services/api/hr/employees.service";
// components
import {
  IntitialsCircle,
  InputLabel,
  Input,
  PhoneNumberInput,
  SelectInput,
  Button
} from "../../../../components";
import { UserDisabledIconSvg } from "../../../../assets/Icons";
// dropdown options
import { genderOptions } from "./genderOptions";
// styles
import BasicInfoStyles from "./BasicInfoStyles";
import { useTranslation } from "react-i18next";

const BasicInfo = ({
  isFormDisplay = false,
  isEditEmployee = false,
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  handleChange,
  fetchData = () => {}
}) => {
  const { t } = useTranslation();
  const [branchCodeOptions, setBranchCodeOptions] = useState([]);

  /**
   * Returns list of company branches from server and sets it to state.
   * Is fired every time if page changes.
   */
  const fetchCompanyBranches = async () => {
    const response = await hrEmployeeServices.getCompanyBranches();

    if (response.hasError) {
      return toast.error(t(response.errorMessage));
    }

    const branchOptions = response.branches.map(branch => ({
      id: branch.id,
      name: branch.branchCode
    }));
    setBranchCodeOptions(branchOptions);
  };

  useEffect(() => {
    fetchCompanyBranches();

    return () => fetchCompanyBranches();
  }, []);

  const resendVerificationLink = async e => {
    e.stopPropagation();
    const response = await usersService.resendVerificationLink(values.userId);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_send_ver_link")
      );
    }

    toast.success(t(response.data.message));
  };

  /**
   * Handles user enabling.
   * Sends request to update user enabled status.
   * Refetches data.
   * @param {Event} e
   */
  const handleUserEnable = async e => {
    e.stopPropagation();

    const response = await usersService.enableUser(values.userId);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_enable_user")
      );
    }

    toast.success(t(response.data.message));

    fetchData();
  };

  const translatedGenderOptions = genderOptions.map(gender => {
    return { ...gender, name: t(gender.value) };
  });

  return (
    <BasicInfoStyles>
      <div className="employeeIntialsContainer">
        <IntitialsCircle
          value={`${values.firstName} ${values.lastName}`}
          size={126}
        />
        {values.isDisabled && (
          <span style={{ position: "absolute", top: "121px", left: "83px" }}>
            <UserDisabledIconSvg width="50" height="50" />
          </span>
        )}
      </div>
      <div className="inputContainers">
        <div className="topInputsContainer">
          <div className="inputContainerFirstRow">
            <div className="labelAndValidation">
              <InputLabel label={t("Name")} margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.firstName && touched.firstName
                    ? errors.firstName
                    : ""}
                </div>
              )}
            </div>
            {isFormDisplay ? (
              <Input
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
                onBlur={handleBlur}
              />
            ) : (
              <p className="infoText">{values.firstName}</p>
            )}
          </div>
          <div className="inputContainerFirstRow">
            <div className="labelAndValidation">
              <InputLabel label={t("Surname")} margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.lastName && touched.lastName ? errors.lastName : ""}
                </div>
              )}
            </div>
            {isFormDisplay ? (
              <Input
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
                onBlur={handleBlur}
              />
            ) : (
              <p className="infoText">{values.lastName}</p>
            )}
          </div>
          <div className="inputContainerFirstRow">
            <div className="labelAndValidation">
              <InputLabel label={t("Birthday")} margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.born_date && touched.born_date
                    ? errors.born_date
                    : ""}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative"
              }}
            >
              {isFormDisplay ? (
                <>
                  <div className="datepickerWrapper">
                    <DatePicker
                      className="datePickerInput"
                      name="bornDate"
                      id="datePicker"
                      selected={values.born_date}
                      dateFormat="dd/MM/yyyy"
                      onChange={selectedDate =>
                        setFieldValue("born_date", selectedDate)
                      }
                      maxDate={new Date()}
                      value={parseISO(values.born_date)}
                      showYearDropdown
                      placeholderText="DD/MM/YYYY"
                    />
                  </div>
                </>
              ) : (
                <p className="infoText" style={{ textAlign: "center" }}>
                  {values.born_date}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="middleInputsContainer">
          <div className="emailInputContainer">
            <div className="labelAndValidation">
              <InputLabel label="Email" margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.email && touched.email ? errors.email : ""}
                </div>
              )}
            </div>
            {isFormDisplay ? (
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
              />
            ) : (
              <p className="infoText">{values.email}</p>
            )}
          </div>
          <div className="firstDayAtWork">
            <div className="labelAndValidation">
              <InputLabel label={t("Enroll date")} margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.firstDayAtWork && touched.firstDayAtWork
                    ? errors.firstDayAtWork
                    : ""}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative"
              }}
            >
              {isFormDisplay ? (
                <>
                  <div className="datepickerWrapper">
                    <DatePicker
                      className="datePickerInput"
                      name="firstDayAtWork"
                      id="datePicker"
                      selected={values.firstDayAtWork}
                      dateFormat="dd/MM/yyyy"
                      onChange={selectedDate =>
                        setFieldValue("firstDayAtWork", selectedDate)
                      }
                      maxDate={new Date()}
                      value={parseISO(values.firstDayAtWork)}
                      showYearDropdown
                      placeholderText="DD/MM/YYYY"
                    />
                  </div>
                </>
              ) : (
                <p className="infoText" style={{ textAlign: "center" }}>
                  {values.firstDayAtWork}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="bottomInputs">
          <div className="phoneInputContainer">
            <div className="labelAndValidation">
              <InputLabel label={t("Contact")} margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.contact && touched.contact ? errors.contact : ""}
                </div>
              )}
            </div>
            {isFormDisplay ? (
              <PhoneNumberInput
                defaultCountry="rs"
                onChange={phone => setFieldValue("contact", phone)}
                value={values.contact}
                onBlur={handleBlur}
                name="contact"
              />
            ) : (
              <p className="infoText">{values.contact}</p>
            )}
          </div>
          <div className="teamContainer">
            <div className="labelAndValidation">
              <InputLabel label={t("Team")} margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.team && touched.team ? errors.team : ""}
                </div>
              )}
            </div>
            {isFormDisplay ? (
              <Input
                name="team"
                value={values.team}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ) : (
              <p className="infoText">{values.team}</p>
            )}
          </div>
          <div className="genderContainer">
            <div className="labelAndValidation">
              <InputLabel label={t("Gender")} margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.gender && touched.gender ? errors.gender : ""}
                </div>
              )}
            </div>
            {isFormDisplay ? (
              <SelectInput
                name="gender"
                onBlur={handleBlur}
                options={translatedGenderOptions}
                selectedValues={translatedGenderOptions}
                value={t(values.gender)}
                handleChange={option => setFieldValue("gender", option.value)}
                shouldHaveFullWidth
                customHeight="27px"
              />
            ) : (
              <p className="infoText" style={{ textTransform: "Capitalize" }}>
                {t(values.gender)}
              </p>
            )}
          </div>
        </div>
        <div className="bottomInputs">
          <div className="uniqueIdContainer">
            <div className="labelAndValidation">
              <InputLabel label={t("Unique ID")} margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.uniqueId && touched.uniqueId ? errors.uniqueId : ""}
                </div>
              )}
            </div>
            {isFormDisplay ? (
              <Input
                name="uniqueId"
                value={values.uniqueId}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ) : (
              <p className="infoText">{values.uniqueId}</p>
            )}
          </div>
          <div className="branchCodeContainer">
            <div className="labelAndValidation">
              <InputLabel label={t("Branch Code")} margin="0 0 5px 0" />
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.branchCode && touched.branchCode
                    ? errors.branchCode
                    : ""}
                </div>
              )}
            </div>
            {isFormDisplay ? (
              <SelectInput
                name="branchCode"
                onBlur={handleBlur}
                options={branchCodeOptions}
                selectedValues={branchCodeOptions}
                value={values.branchCode}
                handleChange={option => setFieldValue("branchCode", option.id)}
                shouldHaveFullWidth
                customHeight="27px"
              />
            ) : (
              <p className="infoText" style={{ textTransform: "Capitalize" }}>
                {values.branchCode}
              </p>
            )}
          </div>
        </div>
        {values.isDisabled && (
          <div className="userDisabledContainer">
            <span className="userDisabledTitle">{t("disabled_user_info")}</span>
            {isEditEmployee && (
              <Button
                onClick={e => handleUserEnable(e)}
                margin="8px 0 0 0"
                padding="10px 25px"
                outline
              >
                {t("enable_user")}
              </Button>
            )}
          </div>
        )}
      </div>
      {isEditEmployee && (
        <span
          className="resendVerificationLink"
          onClick={resendVerificationLink}
        >
          {t("resend_ver_link")}
        </span>
      )}
    </BasicInfoStyles>
  );
};

BasicInfo.propTypes = {
  isFormDisplay: PropTypes.bool,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func
};

export default BasicInfo;
