import i18next from "i18next";
import {
  isEmpty,
  isStringAllCaps,
  removeSpacesAndHTML
} from "../general.utils";
import { convertEditorStateToString } from "../textEditor.utils";

export const validateBenefitGroupForm = ({
  name,
  photo,
  description,
  categories,
  taxes,
  cities,
  isRemote,
  summary
}) => {
  const errors = {};

  if (!name.trim()) {
    errors.name = i18next.t("required_field");
  } else if (removeSpacesAndHTML(name).length > 30) {
    errors.name = i18next.t("name_too_long");
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = i18next.t("html_tag_ban");
  }

  const descriptionString = convertEditorStateToString(description);
  const tagRegex = /<.+?>/g;
  const formattedDescription = descriptionString.replace(tagRegex, "").trim();
  if (formattedDescription.length > 1001) {
    errors.description = i18next.t("too_long");
  }

  if (!summary.trim()) {
    errors.summary = i18next.t("required_field");
  } else if (removeSpacesAndHTML(summary).length < 20) {
    errors.summary = i18next.t("too_short");
  } else if (removeSpacesAndHTML(summary).length > 90) {
    errors.summary = i18next.t("too_long");
  }

  if (isEmpty(categories)) {
    errors.categories = i18next.t("required_field");
  }

  if (!isEmpty(taxes) && isStringAllCaps(taxes)) {
    errors.taxes = i18next.t("all_caps_ban");
  }

  if (!isEmpty(taxes) && removeSpacesAndHTML(taxes).length > 100) {
    errors.taxes = i18next.t("too_long");
  }

  if (!photo) {
    errors.photo = i18next.t("required_field");
  }

  if (!isRemote) {
    if (isEmpty(cities)) {
      errors.cities = i18next.t("required_field");
    }
  }

  return errors;
};
