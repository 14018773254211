import React, { useState, useMemo, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// components and animations
import { SelectInput, closeModalAnim, Input } from "../../../../../components";
import BenefitSubscribeQuantity from "./BenefitSubscribeQuantity/BenefitSubscribeQuantity";
// assets
import { TokenIcon, CheckIcon } from "../../../../../assets/Icons";
// services
import * as api from "../../../../../services/api/employee/employeeBenefits.services";
import * as employeeService from "../../../../../services/api/employee/employeeBenefits.services";
import * as userApiService from "../../../../../services/api/auth.service";
import * as benefitGroupService from "../../../../../services/api/admin/benefitGroups.service";
// Utils
import { sortNumbersByKey } from "../../../../../services/sort.utils";
import { isEmpty } from "../../../../../services/general.utils";
// actions
import * as actionCreators from "../../../../../actions/employee/employeeBenefits.actions";
import * as appActionCreators from "../../../../../actions/app.actions";
import { TOKEN_VALUE } from "../../../../../builders/benefits/tokenValue";
import { apiService } from "../../../../../services/api/api.service";
import { UploadPDFPayload } from "../../../../../models/server/payloads";
import sendImageToSupabase from "../../../../../utils/supabaseUtils/sendImageToSupabase";
import { useTranslation } from "react-i18next";
import {
  transformTokenCurrencyIcons,
  transformTokensToRsd
} from "../../../../../utils/transformTokensToRsd";
import { calculateValueWithVAT } from "../../../../../utils/calculateValueWithVAT";

const VoucherSubscribeButton = ({
  benefit,
  benefitGroup,
  appliedChosenCategoryIds,
  appliedCityIds,
  appliedRemoteFilter,
  appliedChosenPriceRange,
  closeBenefitGroupModal,
  setBenefitGroupsAndSubscribedBenefitGroups,
  setEmployeeTokenCount,
  setSystemNotifications,
  user,
  isPreview = false,
  updateBenefitGroup,
  setSubscribedBenefitGroups,
  setIsLoading,
  setShowPDFBill,
  setVoucherReimbursementData,
  appliedBenefitExpirationTypes,
  appliedSearchFilter,
  appliedOrderSort,
  appliedKeySort,
  setListOfCompanyBenefits
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubscribedButtonHovered, setIsSubscribedButtonHovered] = useState(
    false
  );
  const [IsButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [voucher, setVoucher] = useState(null);
  const [customVoucherPrice, setCustomVoucherPrice] = useState("");
  const [customVoucherPriceTokens, setCustomVoucherPriceTokens] = useState(
    null
  );
  const [nearestValueError, setNearestValueError] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [uploadedPDF, setUploadedPDF] = useState(null);

  const { benefitGroupType } = useSelector(
    state => state.employeeBenefitsPage.pageFrontEndStates
  );

  const { benefitGroupsPage } = useSelector(
    state => state.employeeBenefitsPage.benefitsCompany
  );

  const companyId = useSelector(state => state.app.user.companyId);

  const convertedCustomVoucherPrice = useMemo(
    () =>
      parseFloat(
        (customVoucherPriceTokens * TOKEN_VALUE * quantity).toFixed(2)
      ),
    [customVoucherPriceTokens, quantity]
  );

  /**
   * If VAT is defined, include VAT in the price the user will pay for the voucher
   */
  const convertedCustomVoucherPriceTokens = useMemo(
    () =>
      user.companyVatEnabled && benefit.vat
        ? parseFloat(
            (
              customVoucherPriceTokens *
              quantity *
              (1 + benefit.vat / 100)
            ).toFixed(2)
          )
        : customVoucherPriceTokens,
    [customVoucherPriceTokens, quantity]
  );

  /**
   * If VAT is defined, include VAT in the price the user will pay for the voucher
   */
  const convertedVoucherPrice = useMemo(
    () =>
      user.companyVatEnabled && benefit.vat
        ? parseFloat((voucher?.price * (1 + benefit.vat / 100)).toFixed(2))
        : voucher?.price,
    [voucher?.price, benefit.vat]
  );

  const calculatedPriceBasedOnQuantity = useMemo(
    () => quantity * voucher?.price,
    [quantity]
  );

  const closeModal = () => {
    closeModalAnim();
    // delays change of state until animation is done
    setTimeout(() => {
      closeBenefitGroupModal();
    }, 350);
  };

  const fetchFavoritesData = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.get("/favorites");
      const listOfFavorite =
        response.data.items.length > 0
          ? response.data.items.map(item => item.group)
          : [];

      dispatch(actionCreators.setListOfFavorites(listOfFavorite));
    } catch (error) {
      toast.error(t("failed_to_get_favorite_benefits"));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLocalBenefits = async () => {
    try {
      const response = await api.getBenefitGroups({
        chosenCategories: appliedChosenCategoryIds,
        page: 1,
        limit: 10 * benefitGroupsPage,
        priceRange: appliedChosenPriceRange,
        chosenCities: appliedCityIds,
        remoteFilter: appliedRemoteFilter,
        chosenBenefitExpirationTypes: appliedBenefitExpirationTypes,
        search: appliedSearchFilter,
        order: appliedOrderSort,
        key: appliedKeySort,
        type: benefitGroupType
      });
      setListOfCompanyBenefits(response);
    } catch (error) {
      toast.error(t("failed_to_get_benefit_groups"));
    }
  };

  const updatePublicBenefits = async () => {
    try {
      const updatedBenefitFromPublicListOfBenefits = await benefitGroupService.refreshBenefitGroup(
        benefitGroup.id
      );

      updateBenefitGroup(updatedBenefitFromPublicListOfBenefits);
    } catch (error) {
      toast.error(t("failed_to_get_benefit_groups"));
    }
  };

  const fetchNewBenefits = async benefitGroupType => {
    if (!benefitGroupType) return;
    const objectForFetching = {
      public: updatePublicBenefits,
      local: fetchLocalBenefits,
      favorite: fetchFavoritesData
    };

    await objectForFetching[benefitGroupType]();
  };

  const handleSubmitFile = async (pdfValues, companyId) => {
    const payloadData = new UploadPDFPayload(pdfValues);

    try {
      const response = await apiService.post(
        "/employee/upload",
        payloadData.payload
      );

      sendImageToSupabase(response.data.content, pdfValues.pdfFile, companyId);
    } catch (error) {
      toast.error(t(response.error.data.error.message));
    }
  };

  const redeemVoucher = async () => {
    setIsButtonsDisabled(true);
    setIsLoading(true);

    const response = await api.subscribeOrUnsubscribeToVoucher({
      benefitId: benefit.id,
      subscribe: true,
      benefitGroupId: benefitGroup.id,
      voucherId: voucher
        ? voucher.id
        : benefit.vouchers.find(voucher => voucher.isSubscribed).id,
      voucherCustomPrice: customVoucherPriceTokens,
      voucherIsReimbursement: voucher.isReimbursement,
      quantity: quantity
    });

    if (response.hasError) {
      setIsButtonsDisabled(false);
      setIsLoading(false);

      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_redeem_voucher")
      );
    }

    const [
      subscribedBenefitGroupsResponse,
      employeeTokens,
      notificationsResponse
    ] = await Promise.all([
      api.getSubscribedBenefits(),
      employeeService.getEmployeeTokenCount(),
      userApiService.getUnreadNotifications()
    ]);

    if (employeeTokens.hasError) {
      return toast.error(
        employeeTokens.errorMessage
          ? t(employeeTokens.errorMessage)
          : t("failed_to_get_employees_tokens")
      );
    }
    if (notificationsResponse.hasError) {
      return toast.error(
        notificationsResponse.errorMessage
          ? t(notificationsResponse.errorMessage)
          : t("failed_to_get_notifications")
      );
    }
    setEmployeeTokenCount(employeeTokens.data);
    setSystemNotifications(notificationsResponse.notifications);

    toast.success(t(response.data.value));

    const voucherReimbursementData = {
      user_id: user.id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      benefit_name: voucher.name,
      benefit_price: customVoucherPriceTokens,
      created_at: new Date(),
      voucher_id: voucher.id,
      benefit_group_id: benefitGroup.id
    };

    voucher &&
      voucher.isReimbursement &&
      setVoucherReimbursementData(voucherReimbursementData);

    uploadedPDF &&
      handleSubmitFile(
        {
          pdfFile: uploadedPDF,
          voucherId: voucher.id,
          benefitGroupId: benefitGroup.id
        },
        companyId
      );

    closeModal();

    if (subscribedBenefitGroupsResponse.hasError) {
      return toast.error(
        subscribedBenefitGroupsResponse.errorMessage
          ? t(subscribedBenefitGroupsResponse.errorMessage)
          : t("failed_to_get_sub_benefit_groups")
      );
    }

    setSubscribedBenefitGroups(subscribedBenefitGroupsResponse.groups);

    const metadata = {
      user_id: user.id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      benefit_group_name: benefitGroup.name,
      benefit_name: voucher.name,
      benefit_price: voucher.price,
      quantity: quantity,
      created_at: new Date()
    };
    window.Intercom("trackEvent", "subscribed-to-benefit", metadata);

    fetchNewBenefits(benefitGroupType);
  };

  const returnVoucher = async () => {
    setIsLoading(true);

    const findSubscribedVoucher = benefit.vouchers.find(
      voucher => voucher.isSubscribed === true
    );
    const response = await api.subscribeOrUnsubscribeToVoucher({
      benefitId: benefit.id,
      subscribe: false,
      benefitGroupId: benefitGroup.id,
      voucherId: findSubscribedVoucher.id,
      voucherIsReimbursement: findSubscribedVoucher.isReimbursement,
      quantity: quantity
    });

    if (response.hasError) {
      setIsButtonsDisabled(false);
      setIsLoading(false);

      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_redeem_voucher")
      );
    }

    const [
      subscribedBenefitGroupsResponse,
      employeeTokens
    ] = await Promise.all([
      api.getSubscribedBenefits(),
      employeeService.getEmployeeTokenCount()
    ]);

    if (subscribedBenefitGroupsResponse.hasError) {
      return toast.error(
        subscribedBenefitGroupsResponse.errorMessage
          ? t(subscribedBenefitGroupsResponse.errorMessage)
          : t("failed_to_get_sub_benefit_groups")
      );
    }

    if (employeeTokens.hasError) {
      return toast.error(
        employeeTokens.errorMessage
          ? t(employeeTokens.errorMessage)
          : t("failed_to_get_employees_tokens")
      );
    }
    setEmployeeTokenCount(employeeTokens.data);

    toast.success(t(response.data.value));
    closeModal();

    setSubscribedBenefitGroups(subscribedBenefitGroupsResponse.groups);
    fetchNewBenefits(benefitGroupType);
  };

  /**
   * Handles number input on change.
   * Sets custom voucher price based on input value to state.
   * If the input value in tokens does not round up to an even number, we set zero, which disables button for redeeming.
   * @param {Event} e
   */
  const handleNumberInput = e => {
    let inputValue;
    if (e.target.validity.valid) {
      let replacedValue = e.target.value.replace(/[^0-9]*/g, "");
      inputValue = parseInt(replacedValue);
    }
    if (isNaN(inputValue)) {
      inputValue = "";
    }

    if (inputValue % TOKEN_VALUE === 0) {
      setCustomVoucherPriceTokens(inputValue ? inputValue / TOKEN_VALUE : "");
      setNearestValueError(false);
    } else {
      setCustomVoucherPriceTokens(Math.floor(inputValue / TOKEN_VALUE));
      setNearestValueError(true);
    }

    setCustomVoucherPrice(inputValue);
  };

  /**
   * Handles setting chosen voucher from dropdown.
   * Sets custom voucher price to default value.
   * Sets custom voucher price in tokens to default value.
   * @param {any} option
   */
  const handleSetVoucher = option => {
    setVoucher(option);
    setCustomVoucherPrice("");
    setCustomVoucherPriceTokens(null);
  };

  const handleKeyDown = e => {
    if (e.key === "." || e.key === "," || e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  /**
   * Handles setting of quantity from on change event in input field.
   * Sets quantity to state.
   * @param {Event} e
   */
  const handleSetQuantity = e => {
    let inputValue;
    if (e.target.validity.valid) {
      let replacedValue = e.target.value.replace(/[^0-9]*/g, "");
      inputValue = parseInt(replacedValue);
    }
    if (isNaN(inputValue)) {
      inputValue = 1;
    }

    if (inputValue >= 999) {
      inputValue = 999;
    }

    setQuantity(inputValue);
  };

  const uploadPDF = e => {
    if (e.target.files.length > 0) setUploadedPDF(e.target.files[0]);
  };

  const handleUpload = () => {
    document.getElementById("pdf").click();
  };

  useEffect(() => {
    const listOfVouchers = sortNumbersByKey(benefit.vouchers, "price");
    if (listOfVouchers?.length > 1) return;

    handleSetVoucher(listOfVouchers[0]);
  }, []);

  console.log(user);

  return (
    <div className="desktopVoucherContainer">
      {benefit.isSubscribed ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p className="voucherName">
            {transformTokensToRsd(benefit.subscribedPrice)}
          </p>
          {benefit.vouchers.find(voucher => voucher.isSubscribed === true) && (
            <>
              {transformTokenCurrencyIcons()}
              <p className="voucherPrice">
                {transformTokensToRsd(benefit.subscribedPrice)}
              </p>
            </>
          )}
        </div>
      ) : (
        <div className="desktopVoucher">
          <div className="voucherInputContainer">
            <SelectInput
              name="voucher"
              customId="voucherInput"
              options={sortNumbersByKey(benefit.vouchers, "price")}
              value={voucher}
              handleChange={option => handleSetVoucher(option)}
              customValuePosition="-8%"
              disabled={isPreview}
              menuPlacement="top"
              searchable={false}
            />
          </div>
          {voucher && (
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <p
                className="voucherPrice"
                style={{ fontWeight: 400, marginRight: 5 }}
              >
                {!voucher.predefinedValuesOnly && customVoucherPriceTokens > 0
                  ? transformTokensToRsd(convertedCustomVoucherPriceTokens)
                  : transformTokensToRsd(convertedVoucherPrice)}
              </p>
              {transformTokenCurrencyIcons()}
            </div>
          )}
          {voucher && voucher.predefinedValuesOnly && (
            <div className="tokensContainer predefinedContainer">
              <div className="errorMsg voucherErrorMsg predefined">
                {voucher.price > user.remainingTokens && t("tokens_shortage")}
              </div>
              <div className="placeholder"></div>
            </div>
          )}

          {voucher && !voucher.predefinedValuesOnly && (
            <div className="tokensContainer">
              {voucher &&
                calculateValueWithVAT(
                  quantity * customVoucherPriceTokens,
                  voucher.vatIncluded,
                  user.companyVatEnabled
                ) > user.remainingTokens && (
                  <div className="errorMsg voucherErrorMsg">
                    {t("tokens_shortage")}
                  </div>
                )}
              <div className="errorMsg voucherErrorMsg">
                {(customVoucherPriceTokens > user.remainingTokens ||
                  (voucher.price > user.remainingTokens &&
                    customVoucherPriceTokens === null)) &&
                  !IsButtonsDisabled &&
                  t("tokens_shortage")}
              </div>
              <div className="errorMsg voucherErrorMsg">
                {customVoucherPriceTokens !== null &&
                  customVoucherPriceTokens < voucher.price &&
                  customVoucherPriceTokens <= user.remainingTokens &&
                  t("min_voucher_price", {
                    price: voucher.price * TOKEN_VALUE
                  })}
              </div>
              {/* <div className="infoMsg voucherErrorMsg">
                {nearestValueError &&
                  customVoucherPrice >= voucher.price * TOKEN_VALUE &&
                  customVoucherPriceTokens <= user.remainingTokens &&
                  `Value of the voucher has been changed to nearest possible value of ${convertedCustomVoucherPrice}`}
              </div> */}
              <div className="inputContainer">
                <Input
                  type="number"
                  pattern="^\d+$"
                  className="numbersInputs"
                  name="currency"
                  max={999999}
                  onChange={handleNumberInput}
                  onBlur={handleNumberInput}
                  value={customVoucherPrice}
                  onKeyDown={handleKeyDown}
                  disabled={isPreview}
                />
                {user.companyVatEnabled &&
                  !isEmpty(benefit.vat) &&
                  customVoucherPriceTokens > 0 && (
                    <div className="priceDisclaimer">
                      Price with VAT of {benefit.vat}% in currency is{" "}
                      <b>{convertedCustomVoucherPriceTokens * 20}</b>
                    </div>
                  )}
              </div>
              <div className="label" style={{ fontSize: "11px" }}>
                {t("enter_the_value")}
              </div>
            </div>
          )}
        </div>
      )}
      {benefit.isSubscribed && !isPreview ? (
        <button
          type="button"
          className="subscribedButton"
          style={{ width: 140 }}
          onMouseEnter={() => setIsSubscribedButtonHovered(true)}
          onMouseLeave={() => setIsSubscribedButtonHovered(false)}
          disabled={isPreview || IsButtonsDisabled}
          onClick={returnVoucher}
        >
          <div className="circle">
            {isSubscribedButtonHovered ? (
              <hr className="unsubscribeLine" />
            ) : (
              <CheckIcon fill="#1568bf" height="8" />
            )}
          </div>
          <p className="subscribedText" style={{ width: 110 }}>
            {isSubscribedButtonHovered ? t("Return_voucher") : t("Redeemed")}
          </p>
        </button>
      ) : (
        <>
          {!isEmpty(benefit.quantity) && !isEmpty(voucher) && !isPreview && (
            <>
              <div className="errorMsg benefitErrorMsg">
                {!IsButtonsDisabled &&
                  calculatedPriceBasedOnQuantity > user.remainingTokens &&
                  t("tokens_shortage_sub")}
              </div>
              <div
                style={{
                  width: "215px",
                  display: "flex",
                  justifyContent: "space-around"
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {transformTokenCurrencyIcons()}
                  <p className="benefitPrice">
                    {!voucher.predefinedValuesOnly &&
                    customVoucherPriceTokens > 0
                      ? transformTokensToRsd(
                          calculateValueWithVAT(
                            quantity * customVoucherPriceTokens,
                            voucher.vatIncluded,
                            user.companyVatEnabled
                          )
                        )
                      : transformTokensToRsd(
                          calculateValueWithVAT(
                            quantity * voucher.price,
                            voucher.vatIncluded,
                            user.companyVatEnabled
                          )
                        )}
                  </p>
                </div>
                <BenefitSubscribeQuantity
                  setQuantity={setQuantity}
                  isPreview={isPreview}
                  handleKeyDown={handleKeyDown}
                  handleSetQuantity={handleSetQuantity}
                  quantity={quantity}
                  customVoucherPriceTokens={customVoucherPriceTokens}
                />
              </div>
            </>
          )}
          {voucher?.isReimbursement && (
            <>
              <input
                type="file"
                name="pdfFile"
                id="pdf"
                onClick={e => {
                  e.target.value = null;
                }}
                accept=".pdf, .jpg, .jpeg, .png, .JPG, .PNG, .JPEG, .PDF"
                onChange={e => {
                  const fileName = e.target.value;
                  const regex = /\.(pdf|jpg|jpeg|png|JPG|PNG|JPEG|PDF)$/;

                  if (!regex.test(fileName)) {
                    toast.error(t("failed_to_set_valid_file_format"));
                    e.target.value = null;
                    return;
                  }

                  uploadPDF(e);
                }}
                style={{ display: "none" }}
              />

              <button
                type="button"
                className="redeemButton"
                onClick={handleUpload}
              >
                {t("upload")}
              </button>

              {uploadedPDF && <p className="fileName">{uploadedPDF.name}</p>}
            </>
          )}
          <button
            type="button"
            className="redeemButton"
            disabled={
              isPreview ||
              !voucher ||
              IsButtonsDisabled ||
              (!voucher.predefinedValuesOnly &&
                customVoucherPriceTokens !== null &&
                customVoucherPriceTokens < voucher.price) ||
              voucher.price > user.remainingTokens ||
              customVoucherPriceTokens > user.remainingTokens ||
              (voucher?.isReimbursement && !uploadedPDF) ||
              !benefitGroup.provider.isActive
            }
            onClick={redeemVoucher}
          >
            {t("Redeem")}
          </button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    benefitGroup:
      state.employeeBenefitsPage.pageFrontEndStates.chosenBenefitGroup,
    appliedChosenCategoryIds:
      state.employeeBenefitsPage.filters.appliedChosenCategoryIds,
    appliedCityIds: state.employeeBenefitsPage.filters.cityIds,
    appliedRemoteFilter: state.employeeBenefitsPage.filters.isRemote,
    appliedChosenPriceRange:
      state.employeeBenefitsPage.filters.appliedChosenPriceRange,
    appliedBenefitExpirationTypes:
      state.employeeBenefitsPage.filters.expirations,
    appliedSearchFilter: state.employeeBenefitsPage.filters.search,
    appliedOrderSort: state.employeeBenefitsPage.filters.order,
    appliedKeySort: state.employeeBenefitsPage.filters.key
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setListOfCompanyBenefits: payload => {
      return dispatch(actionCreators.setListOfCompanyBenefits(payload));
    },
    closeBenefitGroupModal: () =>
      dispatch(actionCreators.closeBenefitGroupModal()),
    setBenefitGroupsAndSubscribedBenefitGroups: (
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsPages,
      subscribedBenefitGroups
    ) =>
      dispatch(
        actionCreators.setBenefitGroupsAndSubscribedBenefitGroups(
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsPages,
          subscribedBenefitGroups
        )
      ),
    setEmployeeTokenCount: payload =>
      dispatch(appActionCreators.setEmployeeTokens(payload)),
    setSystemNotifications: notifications =>
      dispatch(appActionCreators.setSystemNotifications(notifications)),
    updateBenefitGroup: benefitGroup =>
      dispatch(actionCreators.updateBenefitGroup(benefitGroup)),
    setSubscribedBenefitGroups: benefitGroups =>
      dispatch(actionCreators.setSubscribedBenefitGroups(benefitGroups))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherSubscribeButton);
