import validator from "validator";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";
import { UserRolesKeysEnum } from "../../../constants/roles.constants";
import { doesRoleExist } from "../../roles.utils";
import { findLocales } from "../../../utils/helpers/findLocales";
import i18next from "i18next";

const allowedDateFormats = [
  "DD/MM/YYYY",
  "D/M/YYYY",
  "DD.MM.YYYY",
  "D.M.YYYY",
  "DD-MM-YYYY",
  "D-M-YYYY"
];

export const editUserFormValidations = ({
  firstName,
  lastName,
  email,
  roles,
  bornDate,
  firstDayAtWork,
  gender,
  tier,
  companyId,
  cities
}) => {
  const isInLanguage = findLocales("rs");

  const errors = {};
  if (!firstName || !/\S/.test(firstName)) {
    errors.firstName = i18next.t("required_field");
  } else if (!removeSpacesAndHTML(firstName)) {
    errors.firstName = i18next.t("html_tag_ban");
  }

  if (!lastName || !/\S/.test(lastName)) {
    errors.lastName = i18next.t("required_field");
  } else if (!removeSpacesAndHTML(lastName)) {
    errors.lastName = i18next.t("html_tag_ban");
  }

  if (!roles) {
    errors.roles = i18next.t("required_field");
  }

  if (!email) {
    errors.email = i18next.t("required_field");
  } else if (!validator.isEmail(email)) {
    errors.email = i18next.t("invalid_email");
  }

  if (doesRoleExist(roles, UserRolesKeysEnum.MEMBER)) {
    if (typeof tier === Object) {
      if (tier.id === null) {
        errors.tier = i18next.t("required_field");
      }
    } else {
      if (!tier) {
        errors.tier = i18next.t("required_field");
      }
    }

    if (isEmpty(cities)) {
      errors.cities = i18next.t("required_field");
    }
  }

  if (
    doesRoleExist(roles, UserRolesKeysEnum.HR) ||
    doesRoleExist(roles, UserRolesKeysEnum.MEMBER)
  ) {
    if (!companyId) {
      errors.companyId = i18next.t("required_field");
    }
  }

  return errors;
};
