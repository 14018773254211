import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
// components
import { Card } from "../../../../components";
// config
import { totalBudgetConfig } from "./totalBudget.config";
// services
import { formatDateForSearch } from "../dashboad.services";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";
// styles
import TotalBudgetStyles from "./TotalBudgetStyles";
import { useTranslation } from "react-i18next";

const TotalBudget = ({ totalBudgetLoading, setTotalBudgetLoading }) => {
  const { t } = useTranslation();
  const [totalBudget, setTotalBudget] = useState(totalBudgetConfig);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [date, setDate] = useState(new Date());
  const [searchDateString, setSearchDateString] = useState(
    formatDateForSearch(new Date())
  );
  const isMounted = useRef(false);

  const handleDateChange = chosenDate => {
    setDate(chosenDate);
    setSearchDateString(formatDateForSearch(chosenDate));
  };

  const fetchData = async () => {
    const response = await dashboardService.fetchTotalBudget(searchDateString);

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_get_total_budgets")
      );
    }

    if (isMounted.current) {
      setTotalBudgetLoading(false);
      if (
        errorMessage !== undefined &&
        (response.budgetLastMonth !== 0 || response.budget !== 0)
      ) {
        setErrorMessage(undefined);
      } else if (response.budget === 0 && response.budgetLastMonth === 0) {
        setErrorMessage("No data to compare");
      }
      setTotalBudget({
        ...totalBudget,
        series: [response.budget, response.budgetLastMonth]
      });
    }
  };
  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [date]);

  useEffect(() => {
    if (totalBudgetLoading === false) {
      const datePicker = document.getElementById("datePicker2");
      if (datePicker) {
        datePicker.setAttribute("readOnly", true);
      }
    }
  }, [totalBudgetLoading]);

  return (
    <TotalBudgetStyles>
      <Card
        boxShadow="
					0 2px 4px -1px rgba(0, 0, 0, 0.2),
					0 1px 10px 0 rgba(0, 0, 0, 0.12),
					0 4px 5px 0 rgba(0, 0, 0, 0.14)
				"
      >
        <div className="titleContainer">
          <DatePicker
            className="datePickerInput"
            id="datePicker2"
            selected={date}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onChange={chosenDate => handleDateChange(chosenDate)}
            value={date}
            maxDate={new Date()}
          />
          <h3 className="title">{t("total_budget")}</h3>
        </div>
        <div className="chartAndMessageContainer">
          {errorMessage && (
            <div className="messageContainer">
              <p className="text">{errorMessage}</p>
            </div>
          )}
          <ReactApexChart
            options={totalBudget.options}
            series={totalBudget.series}
            type="donut"
            width={250}
            height={250}
          />
          <div className="legend">
            <div className="item">
              <div className="dot" style={{ background: "#4880ff" }} />
              <p className="description">
                {t("chosen_month")} - {totalBudget.series[0]} currency
              </p>
            </div>
            <div className="item">
              <div className="dot" style={{ background: "#ffc400" }} />
              <p className="description">
                {t("previous_month")} - {totalBudget.series[1]} currency
              </p>
            </div>
          </div>
        </div>
      </Card>
    </TotalBudgetStyles>
  );
};

TotalBudget.propTypes = {
  totalBudgetLoading: PropTypes.bool,
  setTotalBudgetLoading: PropTypes.func
};

export default TotalBudget;
