import i18next from "i18next";
import validator from "validator";

export const validateForgotPassword = ({ email }) => {
  const errors = {};
  if (!email) {
    errors.email = i18next.t("required_field");
  } else if (!validator.isEmail(email)) {
    errors.email = i18next.t("invalid_email");
  }

  return errors;
};
