import React, { useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { toast } from "react-toastify";
// components
import Form from "./Form";
// entity
import { TierForm } from "../../../models/domain";
// services
import * as actionCreators from "../../../actions/hr/settings.actions";
import * as userActionCreators from "../../../actions/app.actions";
import * as settingsService from "../../../services/api/hr/settings.service";
import { tierFormValidation } from "../../../services/validations/hr/tierFormValidation";
import { useTranslation } from "react-i18next";

const AddTier = ({
  tokenWorth,
  addTier,
  closeModal,
  user,
  finishOnboarding
}) => {
  const { t } = useTranslation();

  const onboardingDone = async () => {
    if (user.serverOnboarding === false) {
      const response = await settingsService.onboardingFinish();

      if (response.hasError) {
        return toast.error(t(response.error.data.error.message));
      }

      finishOnboarding();
    }
  };

  const handleAddTierError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${t(data.error.message)}: ${t(data.errors.name.keys[0])}`);
      }
      if (data.errors.companyId) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.companyId.keys[0])}`
        );
      }
      if (data.errors.tokens) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.tokens.keys[0])}`
        );
      }
      if (data.errors.description) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.description.keys[0])}`
        );
      }
    } else {
      toast.error(t(data.error.message));
    }
  };

  const createTierSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const response = await settingsService.addTier(values);

    if (response.hasError) {
      setSubmitting(false);
      return handleAddTierError(response);
    }

    addTier(response);
    toast.success(t("success_tier_create"));
    setSubmitting(false);
    closeModal();
    onboardingDone();
  };

  const formik = useFormik({
    initialValues: new TierForm(),
    validate: tierFormValidation,
    onSubmit: createTierSubmit
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur
  } = formik;

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Form
        title="Add Tier"
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        tokenWorth={tokenWorth}
        disableButton={isSubmitting}
        buttonText="Create"
      />
    </form>
  );
};

AddTier.propTypes = {
  tokenWorth: PropTypes.shape(),
  addTier: PropTypes.func,
  closeModal: PropTypes.func
};

const mapStateToProps = state => {
  return {
    user: state.app.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTier: tier => dispatch(actionCreators.addTier(tier)),
    finishOnboarding: () => dispatch(userActionCreators.finishOnboarding())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTier);
