import React, { useRef, useState } from "react";
import BenefitCardStyles from "../../benefitCard/BenefitCardStyles";
import { truncate } from "../../../services/truncateString";
import { FlagCurrency } from "../../../assets/Icons";

export const March8Cards = props => {
  const { card, data, handleSelect, selectedDataBudget, budgetData } = props;

  const budgetRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  const handleSelectCard = () => {
    handleSelect({
      cardId: card.id,
      price: inputValue,
      cardDescription: card.title
    });
  };

  const debouncedChange = e => {
    const value = parseFloat(e.target.value);

    if (value < 0) return;

    if (value > budgetData.totalBudget - budgetData.spentBudget) {
      budgetRef.current.value = (
        budgetData.totalBudget - budgetData.spentBudget
      ).toString();
    } else {
      budgetRef.current.value = e.target.value;
    }

    setInputValue(budgetRef.current.value);
  };

  const selectedIds = selectedDataBudget.map(item => item.cardId);
  const selectedBudgetCard = selectedDataBudget.find(
    item => item.cardId === card.id
  );

  return (
    <BenefitCardStyles id="benefitCard">
      <div
        className={`benefitCardWrapper ${
          selectedIds.includes(card.id) ? "benefitCardWrapper--selected" : ""
        }`}
      >
        <div className="imgContainer">
          <img
            src={card.image}
            alt="Benefiti Logo"
            className="benefitImage"
            crossOrigin="anonymous"
          />
        </div>
        <div className="underImageCardWrapper">
          <div className="topContent">
            <div className="providerImageOuterCircle">
              <div className="providerImageInnerCircle">
                <img
                  src="https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/demo/benefiti-logo.jpeg"
                  alt="Provider logo"
                  className="providerLogo"
                  crossOrigin="anonymous"
                />
              </div>
            </div>
            <div className="titleAndTokensContainer">
              <h2 className="title">{card.title}</h2>
              <div className="categoryTag">
                <p className="categoryText">{card.category}</p>
              </div>
            </div>
          </div>
          <div className="descriptionContainer">
            <p className="providerDescription">
              {truncate(card.description, 89)}
            </p>
          </div>
          <div className="locations-container">
            <p className="cities">{card.location}</p>
          </div>
          <div className="priceAncCapacityContainer">
            <div className="tokensContainer">
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                {selectedIds.includes(card.id) ? (
                  <div className="tokensContentChosen">
                    <p className="price">
                      <b>{selectedBudgetCard.price}</b>
                    </p>
                    <FlagCurrency />
                  </div>
                ) : (
                  <div className="tokensContent">
                    <input
                      type="number"
                      min="0"
                      max={budgetData.totalValue}
                      ref={budgetRef}
                      value={budgetRef.current?.value || ""}
                      onChange={debouncedChange}
                      className="input-tokens"
                    />
                    <button disabled={!inputValue} onClick={handleSelectCard}>
                      Pretplati se
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BenefitCardStyles>
  );
};
