import { convertEditorStateToString } from "../textEditor.utils";
import { removeSpacesAndHTML } from "../general.utils";
import i18next from "i18next";

export const validateProviderForm = ({ name, description, photo, summary }) => {
  const errors = {};

  if (!name.trim()) {
    errors.name = i18next.t("required_field");
  } else if (removeSpacesAndHTML(name).length > 40) {
    errors.name = i18next.t("too_long");
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = i18next.t("html_tag_ban");
  }

  const descriptionString = convertEditorStateToString(description);
  const tagRegex = /<.+?>/g;
  const formattedDescription = descriptionString.replace(tagRegex, "").trim();
  if (formattedDescription.length > 1001) {
    errors.description = i18next.t("too_long");
  }

  if (!summary.trim()) {
    errors.summary = i18next.t("required_filed");
  } else if (removeSpacesAndHTML(summary).length < 20) {
    errors.summary = i18next.t("too_short");
  } else if (removeSpacesAndHTML(summary).length > 90) {
    errors.summary = i18next.t("too_long");
  } else if (!removeSpacesAndHTML(summary)) {
    errors.summary = i18next.t("html_tag_ban");
  }

  if (!photo) {
    errors.photo = i18next.t("required_filed");
  }

  return errors;
};
