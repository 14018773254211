import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
// components
import Button from "../button";
// services
import * as api from "../../services/api/admin/users.service";
// Utils
import {
  startFutureMonthFromDate,
  formatToStartOfDay
} from "../../services/date.utils";
import { useTranslation } from "react-i18next";

const DisableUserModalStyles = styled.div`
  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .disableUserTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    color: #4b4b4b;
    margin-bottom: 20px;
  }

  .datePickerInput {
    height: 32px;
    width: 194px;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-color: 1px solid #d8d8d8;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
    position: relative;
    margin-top: 20px;

    &:hover {
      border: 1px solid #1568bf;
      z-index: 2;
    }
  }

  width: 544px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 46px 30px 30px 30px;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
`;

const initalDate = startFutureMonthFromDate(new Date());

const DisableUserModal = ({ userId, closeModal, fetchData = () => {} }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(initalDate);

  const handleDateChange = chosenDate => {
    setDate(formatToStartOfDay(chosenDate));
  };

  const handleDisableUserError = ({ error }) => {
    const { data } = error;
    if (data) {
      if (data.errors && data.error) {
        if (data.errors.scheduleDeleteAt) {
          toast.error(t(`${data.errors.scheduleDeleteAt.keys[0]}`));
        }
      } else {
        toast.error(t(data.error.message));
      }
    }
  };

  /**
   * Sends patch request to disable user.
   * Closes modal.
   * Refetches data from server.
   */
  const disableUser = async () => {
    const response = await api.disableUser({
      userId,
      scheduleDeleteAt: date
    });

    if (response.hasError) {
      return handleDisableUserError(response);
    }

    toast.success(t(response.data.message));

    setTimeout(() => {
      closeModal();
      fetchData();
    }, 200);
  };

  return (
    <DisableUserModalStyles>
      <div className="contentWrapper">
        <h2 className="disableUserTitle">{t("Disable User")}</h2>
        <p>{t("choose_disabling_date")}</p>
        <p>{t("immediate_delete")}</p>
        <DatePicker
          className="datePickerInput"
          id="datePicker"
          selected={date}
          onChange={chosenDate => handleDateChange(chosenDate)}
          minDate={new Date()}
          value={date}
        />

        <Button
          onClick={async () => disableUser()}
          margin="29px 0 0 0"
          width="140px"
        >
          {t("Submit")}
        </Button>
      </div>
    </DisableUserModalStyles>
  );
};

export default DisableUserModal;
