import i18next from "i18next";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";

export const addBonusFormValidation = ({
  subject,
  message,
  tokens,
  employeeId,
  employees,
  recurring,
  recurringType
}) => {
  const errors = {};

  if (subject && removeSpacesAndHTML(subject).length > 50) {
    errors.subject = i18next.t("too_long");
  }

  if (message && removeSpacesAndHTML(message).length > 1000) {
    errors.message = i18next.t("too_long");
  }

  if (!tokens) {
    errors.tokens = i18next.t("required_field");
  }

  if (!employeeId && (!employees || employees.length === 0)) {
    errors.employees = i18next.t("required_field");
  }

  if (recurring) {
    if (isEmpty(recurringType)) {
      errors.recurringType = i18next.t("required_field");
    }
  }

  return errors;
};
