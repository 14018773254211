import React, { useEffect, useRef } from "react";
import SocialProofNotificationsStyles from "./SocialProofNotificationsStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  employeeBenefitsSetInitialState,
  openBenefitGroupModal,
  setBenefitGroupType,
  setIsInitialStateSet,
  setIsSubscribedBenefitGroupsVisible,
  setUsedBenefitsRedux
} from "../../../../actions/employee/employeeBenefits.actions";
import { toast } from "react-toastify";
import {
  getAlreadyUsedBenefits,
  getBenefitGroups,
  getCategoriesForEmployeePlatform
} from "../../../../services/api/employee/employeeBenefits.services";
import { openModalAnim } from "../../../../components";
import { uniqueArrayOfObjects } from "../../../../services/general.utils";
import { apiService } from "../../../../services/api/api.service";
import { useTranslation } from "react-i18next";

export const SocialProofNotifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationRef = useRef(null);

  const { currentlyPopularBenefit } = useSelector(
    state => state.employeeBenefitsPage.socialProof
  );
  const { benefitGroups } = useSelector(
    state => state.employeeBenefitsPage.benefitsBrowse
  );

  const {
    appliedChosenCategoryIds,
    appliedChosenPriceRange,
    appliedCityIds,
    appliedRemoteFilter
  } = useSelector(state => state.employeeBenefitsPage.filters);

  useEffect(() => {
    const showNotification = () => {
      notificationRef.current?.classList.add("show");

      setTimeout(() => {
        notificationRef.current?.classList.remove("show");
        notificationRef.current?.classList.add("hide");
        setTimeout(() => {
          notificationRef.current?.classList.remove("hide");
        }, 700);
      }, 15000);
    };

    showNotification();
    const intervalId = setInterval(showNotification, 900000);
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const providerLogo = currentlyPopularBenefit?.photo;

  const imageUrl = currentlyPopularBenefit?.photo
    ? `${providerLogo.base}${providerLogo.path}/${providerLogo.name}@${providerLogo.size[0]}.${providerLogo.type}`
    : null;

  const openModalFunctionality = () => {
    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  const fetchBenefits = async type => {
    const [benefitGroupsResponse, categoriesResponse] = await Promise.all([
      getBenefitGroups({
        chosenCategories: appliedChosenCategoryIds,
        page: 1,
        priceRange: appliedChosenPriceRange,
        chosenCities: appliedCityIds,
        remoteFilter: appliedRemoteFilter,
        type: type
      }),
      getCategoriesForEmployeePlatform()
    ]);

    if (benefitGroupsResponse.hasError) {
      return toast.error(
        benefitGroupsResponse.errorMessage
          ? t(benefitGroupsResponse.errorMessage)
          : t("failed_to_get_benefit_groups")
      );
    }

    if (categoriesResponse.hasError) {
      return toast.error(
        categoriesResponse.errorMessage
          ? t(categoriesResponse.errorMessage)
          : t("failed_to_get_categories")
      );
    }

    dispatch(
      employeeBenefitsSetInitialState(
        benefitGroupsResponse.groups,
        benefitGroupsResponse.count,
        benefitGroupsResponse.pages,
        1,
        categoriesResponse.categories
      )
    );
    dispatch(setIsInitialStateSet(true));

    setTimeout(() => {
      const editEmployeeContentCard = document.getElementById(
        "benefitGroupList"
      );
      editEmployeeContentCard.style.opacity = 1;
    }, 100);
  };

  const navigateToProperTab = type => {
    dispatch(setIsSubscribedBenefitGroupsVisible(false));
    dispatch(setBenefitGroupType(type));
  };

  const fetchAlreadyUsedBenefits = async id => {
    try {
      const response = await getAlreadyUsedBenefits(id);
      dispatch(setUsedBenefitsRedux(uniqueArrayOfObjects(response.benefits)));
    } catch (error) {
      toast.error(t("failed_to_get_used_benefits"));
    }
  };

  const fetchInfoForSingleBenefitGroup = async benefitGroupId => {
    try {
      const response = await apiService.get(
        `/benefit-group/${benefitGroupId}/social-proof-data`
      );

      dispatch(openBenefitGroupModal(response.data));
    } catch (error) {
      toast.error(t("failed_to_get_single_benefit_info"));
    }
  };

  const handleOpenBenefit = async () => {
    const isProviderInactive = !currentlyPopularBenefit.Provider?.isActive;
    if (isProviderInactive) return;
    if (benefitGroups.length === 0) return;
    await fetchBenefits("public");

    navigateToProperTab("public");

    fetchAlreadyUsedBenefits(currentlyPopularBenefit.benefit_group_id);

    fetchInfoForSingleBenefitGroup(currentlyPopularBenefit.benefit_group_id);

    openModalFunctionality();
  };

  return (
    <SocialProofNotificationsStyles onClick={handleOpenBenefit}>
      <div id="social-proof-notification" ref={notificationRef}>
        <div className="socialProofNotificationWrapper">
          <img
            src={imageUrl}
            alt="Provider logo"
            className="providerLogo"
            crossOrigin="anonymous"
          />
          <div>
            <span>{currentlyPopularBenefit?.BenefitGroup?.name}</span> je
            trenutno najpopularniji benefit u Vašoj kompaniji.
          </div>
        </div>
      </div>
    </SocialProofNotificationsStyles>
  );
};
