import i18next from "i18next";
import validator from "validator";

export const validateLogin = ({ email, password }) => {
  const errors = {};

  if (!email) {
    errors.email = i18next.t("required_field");
  } else if (!validator.isEmail(email)) {
    errors.email = i18next.t("invalid_email");
  }

  if (!password) {
    errors.password = i18next.t("required_field");
  } else if (password.length < 8) {
    errors.password = i18next.t("min_8_chars");
  }

  return errors;
};
