import i18next from "i18next";

export const validateCompanyThemeForm = ({ photo }) => {
  const errors = {};

  if (!photo) {
    errors.photo = i18next.t("required_field");
  }

  return errors;
};
