import { apiService } from "../../services/api/api.service";

export const setupLocales = async companyId => {
  const locales = localStorage.getItem("locales");

  if (!locales) {
    if (companyId) {
      const response = await apiService.get(`/company/${companyId}`);
      localStorage.setItem("locales", response.data.locales);
      return;
    }

    localStorage.setItem("locales", "en");
  }
};
