import React, { useContext } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";

import Form from "./Form";

import { Category } from "../../../../../../modules/superAdmin/settings/settings.entity";
import { categoryValidations } from "../../../../../../services/validations/superAdmin/category.validations";
import * as categoriesService from "../../../../../../services/api/admin/categories.service";

import * as actionCreators from "../../../../../../actions/admin/categories.actions";
import { useTranslation } from "react-i18next";

const AddCategoryStyles = styled.div`
  padding: 20px;
  width: 400px;

  .title {
    margin-bottom: 20px;
  }
`;

const AddCategory = ({ closeModal, addCategory }) => {
  const { t } = useTranslation();

  const handleCreateCategoryError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${t(data.error.message)}: ${t(data.errors.name.keys[0])}`);
      }
      if (data.errors.description) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.description.keys[0])}`
        );
      }
    }
  };

  const createCategory = async values => {
    const response = await categoriesService.addCategory(values);
    if (response.hasError) {
      return handleCreateCategoryError(response);
    }

    addCategory(response);
    toast.success(t("success_add_category"));
    closeModal();
  };

  const formik = useFormik({
    initialValues: new Category(),
    validate: categoryValidations,
    onSubmit: createCategory
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddCategoryStyles>
      <h2 className="title">{t("add_category")}</h2>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Form
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          buttonText="Create"
        />
      </form>
    </AddCategoryStyles>
  );
};

AddCategory.propTypes = {
  closeModal: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    addCategory: category => dispatch(actionCreators.addCategory(category))
  };
};

export default connect(undefined, mapDispatchToProps)(AddCategory);
