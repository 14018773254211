import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppRouter from "./Router";
import { useStore } from "./store/store";
import { Provider } from "react-redux";
import { UserProvider } from "./modules/user/context/user.context";

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./config/azureAuth.config";
import { MsalProvider } from "@azure/msal-react";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { GlobalErrorBoundaryFallback } from "./components/ErrorBoundary/GlobalErrorBoundaryFallback";

import "./utils/i18n";
import { setupLocales } from "./utils/language/setupLocales";

const Application = ({ pca }) => {
  const store = useStore();

  return (
    <MsalProvider instance={pca}>
      <ErrorBoundary fallback={<GlobalErrorBoundaryFallback />}>
        <Provider store={store}>
          <UserProvider>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              theme="colored"
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={false}
            />
            <AppRouter />
          </UserProvider>
        </Provider>
      </ErrorBoundary>
    </MsalProvider>
  );
};

export const msalInstance = new PublicClientApplication(msalConfig);

setupLocales(null);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  ReactDOM.render(
    <Application pca={msalInstance} />,
    document.getElementById("root")
  );
});
