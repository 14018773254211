import { toast } from "react-toastify";
import supabaseClient from "./supabaseClient";
import i18next from "i18next";

async function downloadAllFiles(folderName, setIsRefundDownloading) {
  let offset = 0;
  let allFiles = [];
  let hasMore = true;

  const toastId = toast.info(i18next.t("images_are_loading_info"), {
    autoClose: false,
    position: "top-center"
  });

  while (hasMore) {
    const { data: files, error } = await supabaseClient.storage
      .from("refund-upload")
      .list(folderName, {
        limit: 500,
        offset: offset
      });

    if (error) {
      setTimeout(() => {
        toast.update(toastId, {
          type: toast.TYPE.ERROR,
          render: i18next.t("loading_images_error"),
          autoClose: 5000
        });
        setIsRefundDownloading(false);
      }, 5000);

      console.error(i18next.t("loading_images_error"), error);
      return null;
    }

    hasMore = !!files.length;

    allFiles = allFiles.concat(files);
    offset += files.length;
  }

  if (allFiles.length === 0) {
    setTimeout(() => {
      toast.update(toastId, {
        type: toast.TYPE.INFO,
        render: i18next.t("no_uploaded_images"),
        autoClose: 5000
      });
      setIsRefundDownloading(false);
    }, 5000);
    return null;
  }

  return { allFiles, toastId };
}

export default downloadAllFiles;
