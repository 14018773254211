import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// components and openModal animation function
import {
  BenefitCard,
  TextAndLine,
  openModalAnim
} from "../../../../components";
import NextMonthDropDown from "./NextMonthDropDown/NextMonthDropDown";
// styles
import { SubscribedBenefitGroupsStyles } from "./subscribedBenefitGroups.styles";
// actions
import * as actionCreators from "../../../../actions/employee/employeeBenefits.actions";
// services
import * as benefitGroupService from "../../../../services/api/admin/benefitGroups.service";
import * as api from "../../../../services/api/employee/employeeBenefits.services";
// builder constants
import * as benefitGroupStatus from "../../../../builders/benefitGroups/benefitGroup.status";
import * as benefitGroupType from "../../../../builders/benefitGroups/benefitGroup.types";
import * as mobileBenefitsTypeRender from "../../../../builders/benefitGroups/mobileBenefitsType";
import { useTranslation } from "react-i18next";

const SubscribedBenefitGroups = ({
  subscribedBenefitGroups,
  isConditionAllBenefits,
  openBenefitGroupModal,
  fetchAlreadyUsedBenefits,
  setSubscribedBenefitGroups
}) => {
  const { t } = useTranslation();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);
  const [mobileBenefitsType, setMobileBenefitsTypes] = useState(
    mobileBenefitsTypeRender.THIS_MONTH
  );
  const isMounted = useRef(false);

  const fetchInitialData = async () => {
    const subscribedBenefitGroupsResponse = await api.getSubscribedBenefits();

    if (subscribedBenefitGroupsResponse.hasError) {
      return toast.error(
        subscribedBenefitGroupsResponse.errorMessage
          ? t(subscribedBenefitGroupsResponse.errorMessage)
          : t("failed_to_get_sub_benefit_groups")
      );
    }

    if (isMounted.current) {
      setSubscribedBenefitGroups(subscribedBenefitGroupsResponse.groups);
    }

    // // displays content after all state is set
    // setTimeout(() => {
    //   const editEmployeeContentCard = document.getElementById(
    //     "benefitGroupList"
    //   );
    //   if (isMounted.current && editEmployeeContentCard) {
    //     editEmployeeContentCard.style.opacity = 1;
    //   }
    // }, 100);
  };

  /**
   * Handles opening of modal after click on benefit group card.
   * If benefit group is marked as new, sends request to DB for updating status.
   * Refetches data.
   * @param {BenefitGroup} chosenBenefitGroup
   */
  const openModal = async chosenBenefitGroup => {
    if (chosenBenefitGroup.isBenefitGroupNew) {
      await benefitGroupService.markNewBenefitGroupAsSeen(
        chosenBenefitGroup.id
      );
      fetchData();
    }

    fetchAlreadyUsedBenefits(chosenBenefitGroup.id);

    openBenefitGroupModal(chosenBenefitGroup);
    // modal fades in after 100 ms
    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  /**
   * If benefit group is marked as new, sends request to DB for updating status.
   * Refetches data.
   * @param {BenefitGroup} chosenBenefitGroup
   */
  const handleDefaultBenefitCardClick = async chosenBenefitGroup => {
    if (chosenBenefitGroup.isBenefitGroupNew) {
      await benefitGroupService.markNewBenefitGroupAsSeen(
        chosenBenefitGroup.id
      );
      fetchData();
    }
  };

  // splits subscribed benefit groups in this month, next month and default on frontend
  const splitBenefitGroups = nextMonthCondition => {
    let thisMonthBenefitGroups = [];
    let nextMonthBenefitGroups = [];
    let defaultBenefitGroups = [];

    subscribedBenefitGroups.forEach(benefitGroup => {
      if (benefitGroup.type === benefitGroupType.PUBLIC) {
        if (
          benefitGroup.status === benefitGroupStatus.PENDING_DEACTIVATION ||
          benefitGroup.status === benefitGroupStatus.ACTIVE
        ) {
          thisMonthBenefitGroups = [...thisMonthBenefitGroups, benefitGroup];
        }
        if (nextMonthCondition) {
          if (
            benefitGroup.status === benefitGroupStatus.PENDING_ACTIVATION ||
            benefitGroup.status === benefitGroupStatus.ACTIVE ||
            benefitGroup.shouldBeInAllBenefits
          ) {
            nextMonthBenefitGroups = [...nextMonthBenefitGroups, benefitGroup];
          }
        } else {
          if (
            !benefitGroup.shouldBeInAllBenefits &&
            (benefitGroup.status === benefitGroupStatus.PENDING_DEACTIVATION ||
              benefitGroup.status === benefitGroupStatus.PENDING_ACTIVATION)
          ) {
            nextMonthBenefitGroups = [...nextMonthBenefitGroups, benefitGroup];
          }
        }
      } else {
        defaultBenefitGroups = [...defaultBenefitGroups, benefitGroup];
      }
    });
    return [
      thisMonthBenefitGroups,
      nextMonthBenefitGroups,
      defaultBenefitGroups
    ];
  };

  const [
    thisMonthBenefitGroups,
    nextMonthBenefitGroups,
    defaultBenefitGroups
  ] = splitBenefitGroups(isConditionAllBenefits);

  const mobileSubscribedGroupsRender = () => {
    if (mobileBenefitsType === mobileBenefitsTypeRender.THIS_MONTH)
      return thisMonthBenefitGroups;
    if (mobileBenefitsType === mobileBenefitsTypeRender.NEXT_MONTH)
      return nextMonthBenefitGroups;
    if (mobileBenefitsType === mobileBenefitsTypeRender.DEFAULT)
      return defaultBenefitGroups;
  };

  useEffect(() => {
    // setIsDesctop because i need to reinizialize two diffret designs
    const resizeListener = () => {
      setIsDesktop(window.innerWidth > 767);
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    isMounted.current = true;
    fetchInitialData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <SubscribedBenefitGroupsStyles>
      {isDesktop ? (
        <div className="desktopMyBenefits">
          <TextAndLine title={t("TM")} />
          <div className="benefitList">
            {thisMonthBenefitGroups?.length > 0 ? (
              thisMonthBenefitGroups.map(benefitGroup => (
                <BenefitCard
                  benefitGroupId={benefitGroup.id}
                  key={benefitGroup.id}
                  benefitDetails={benefitGroup}
                  isMyBenefits={true}
                  onClick={() => openModal(benefitGroup)}
                />
              ))
            ) : (
              <p className="no-benefits-text">{t("NABTM")}</p>
            )}
          </div>
          <TextAndLine title={t("NM")}>
            <NextMonthDropDown />
          </TextAndLine>
          <div className="benefitList">
            {nextMonthBenefitGroups?.length > 0 ? (
              nextMonthBenefitGroups.map(benefitGroup => (
                <BenefitCard
                  benefitGroupId={benefitGroup.id}
                  key={benefitGroup.id}
                  benefitDetails={benefitGroup}
                  isMyBenefits={true}
                  onClick={() => openModal(benefitGroup)}
                />
              ))
            ) : (
              <p className="no-benefits-text">{t("NABNM")}</p>
            )}
          </div>
          <TextAndLine title={t("DEF")} />
          <div className="benefitList">
            {defaultBenefitGroups?.length > 0 ? (
              defaultBenefitGroups.map(benefitGroup => (
                <BenefitCard
                  benefitGroupId={benefitGroup.id}
                  key={benefitGroup.id}
                  benefitDetails={benefitGroup}
                  onClick={() => openModal(benefitGroup)}
                />
              ))
            ) : (
              <p className="no-benefits-text">{t("NADB")}</p>
            )}
          </div>
        </div>
      ) : (
        <div className="mobileMyBenefits">
          <div className="buttonsContainer">
            <button
              type="button"
              className={`button ${mobileBenefitsType ===
                mobileBenefitsTypeRender.THIS_MONTH && "active"}`}
              onClick={() =>
                setMobileBenefitsTypes(mobileBenefitsTypeRender.THIS_MONTH)
              }
            >
              {t("TM")}
            </button>
            <button
              type="button"
              className={`button ${mobileBenefitsType ===
                mobileBenefitsTypeRender.NEXT_MONTH && "active"}`}
              onClick={() =>
                setMobileBenefitsTypes(mobileBenefitsTypeRender.NEXT_MONTH)
              }
            >
              {t("NM")}
            </button>
            <button
              type="button"
              className={`button ${mobileBenefitsType ===
                mobileBenefitsTypeRender.DEFAULT && "active"}`}
              onClick={() =>
                setMobileBenefitsTypes(mobileBenefitsTypeRender.DEFAULT)
              }
            >
              {t("DEF")}
            </button>
          </div>
          {mobileBenefitsType === mobileBenefitsTypeRender.NEXT_MONTH && (
            <NextMonthDropDown />
          )}
          <div className="benefitList">
            {mobileSubscribedGroupsRender().map(benefitGroup => (
              <BenefitCard
                benefitGroupId={benefitGroup.id}
                key={benefitGroup.id}
                benefitDetails={benefitGroup}
                isMyBenefits={true}
                onClick={() =>
                  mobileBenefitsType !== mobileBenefitsTypeRender.DEFAULT &&
                  openModal(benefitGroup)
                }
              />
            ))}
          </div>
        </div>
      )}
    </SubscribedBenefitGroupsStyles>
  );
};

const mapStateToProps = state => {
  return {
    subscribedBenefitGroups:
      state.employeeBenefitsPage.subscribedBenefits.subscribedBenefitGroups,
    isConditionAllBenefits:
      state.employeeBenefitsPage.subscribedBenefits.isConditionAllBenefits
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openBenefitGroupModal: chosenBenefitGroup =>
      dispatch(actionCreators.openBenefitGroupModal(chosenBenefitGroup)),
    setSubscribedBenefitGroups: subscribedBenefitGroups =>
      dispatch(
        actionCreators.setSubscribedBenefitGroups(subscribedBenefitGroups)
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribedBenefitGroups);
