import React, { useEffect, useState } from "react";
import { InputLabel, SelectInput } from "../../../../../components";
import * as companiesService from "../../../../../services/api/admin/companies.service";
import { useTranslation } from "react-i18next";

export const SelectCompaniesComponent = ({ onSelect }) => {
  const { t } = useTranslation();
  const [companiesPage, setCompaniesPage] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const getCompanies = async () => {
    const response = await companiesService.getCompanies({
      page: companiesPage
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_get_companies")
      );
    }

    const formattedResponse = [
      { id: "All Companies", name: "All Companies" },
      ...response.companies
    ];

    setCompanies(formattedResponse);
    setTotalPages(response.pages);
  };

  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = (currentPage += 1);

    if (newPage > totalPages) return;

    setCompaniesPage(newPage);

    const response = await companiesService.getCompanies({ page: newPage });
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_get_companies")
      );
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companies, ...response.companies];
      setTimeout(() => {
        setCompanies(newCompanies);
      }, 100);
    }, 100);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <SelectInput
      name="companies"
      options={companies}
      selectedValues={companies}
      value={company}
      handleChange={option => onSelect(option.id)}
      fetchMoreData={refetchCompanies}
      customId="companyId"
      shouldHaveHight={false}
      shouldHaveFullWidth
      customHeight="27px"
      placeholder={t("Select company")}
    />
  );
};
