import validator from "validator";
import moment from "moment";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";
import i18next from "i18next";

const allowedDateFormats = [
  "DD/MM/YYYY",
  "D/M/YYYY",
  "DD.MM.YYYY",
  "D.M.YYYY",
  "DD-MM-YYYY",
  "D-M-YYYY"
];

const validateDate = value => moment(value, allowedDateFormats, true).isValid();

export const hrEmployeeValidations = ({
  firstName,
  lastName,
  email,
  tier,
  cities
}) => {
  const errors = {};

  if (!firstName || !/\S/.test(firstName)) {
    errors.firstName = i18next.t("required_field");
  } else if (!removeSpacesAndHTML(firstName)) {
    errors.firstName = i18next.t("html_tag_ban");
  }

  if (!lastName || !/\S/.test(lastName)) {
    errors.lastName = i18next.t("required_field");
  } else if (!removeSpacesAndHTML(lastName)) {
    errors.lastName = i18next.t("html_tag_ban");
  }

  if (!email) {
    errors.email = i18next.t("required_field");
  } else if (!validator.isEmail(email)) {
    errors.email = i18next.t("invalid_email");
  }

  if (!tier) {
    errors.tier = i18next.t("required_field");
  }

  if (isEmpty(cities)) {
    errors.cities = i18next.t("required_field");
  }

  return errors;
};
