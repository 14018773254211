import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// styles
import { BenefitGroupFiltersStyles } from "./benefitGroupsFilters.styles";
// components
import {
  Card,
  TextAndLine,
  Button,
  LocationsMultiselectInput,
  TypesMultiselectInput,
  SearchInput,
  SortSelectInput
} from "../../../../components";
import PriceFilter from "./priceFilter";
import CategoryFilters from "./categoriesFilter";
// models
import { PriceRange } from "../../../../models/domain";
// services
import * as api from "../../../../services/api/employee/employeeBenefits.services";
import * as employeeService from "../../../../services/api/hr/employees.service";
// actions
import * as actionCreators from "../../../../actions/employee/employeeBenefits.actions";
// Utils
import { isEmpty } from "../../../../services/general.utils";
// Constants
import {
  BENEFIT_EXPIRATION_TYPES,
  FILTERS_TIMER_IN_MILLISECONDS,
  SORT_OPTIONS,
  PRICE_MIN,
  PRICE_MAX
} from "../../../../constants/filter.constants";
import { useTranslation } from "react-i18next";
import { transformTokensToRsd } from "../../../../utils/transformTokensToRsd";

const BenefitGroupFilters = ({
  categories,
  chosenCategoryIds,
  setChosenCategoryIds,
  chosenPriceRange,
  applyBenefitGroupsFilters,
  resetBenefitGroupFilters,
  setCitiesFilter,
  setRemoteFilterToStore,
  setBenefitExpirationTypesFilter,
  setSearchFilter,
  setOrderSort,
  setKeySort,
  benefitBrowseGroupsCount,
  benefitCompanyGroupsCount,
  setFiltersPriceRange,
  priceRangeOptions,
  benefitGroupType,
  isInitialStateSet,
  setIsPageLoading
}) => {
  const { t } = useTranslation();
  const [cities, setCities] = useState([]);
  const [citiesPage, setCitiesPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [citiesSearchQuery, setCitiesSearchQuery] = useState("");
  const [chosenCityIds, setChosenCityIds] = useState([]);
  const [chosenCities, setChosenCities] = useState([]);
  const [remoteFilter, setRemoteFilter] = useState(false);
  const [
    chosenBenefitExpirationTypeKeys,
    setChosenBenefitExpirationTypeKeys
  ] = useState([]);
  const [
    chosenBenefitExpirationTypes,
    setChosenBenefitExpirationTypes
  ] = useState([]);
  const [numberOfProvidersRender, setNumberOfProvidersRender] = useState(false);
  const [page, setPage] = useState(1);
  const [sortOrder, setOrder] = useState("");
  const [sortKey, setKey] = useState("");
  const [keywordSearch, setKeywordSearch] = useState("");
  const [priceRange, setPriceRange] = useState([
    transformTokensToRsd(PRICE_MIN),
    transformTokensToRsd(PRICE_MAX)
  ]);
  const [isFilter, setIsFilter] = useState(false);

  const timeout = useRef();

  /**
   * Handles altering of sorting direction.
   * Sets sort order to state.
   * Sets sort key to state.
   * @param {object} option
   */
  const handleSelectingSortOption = option => {
    if (isEmpty(option)) {
      setKey("");
      setOrder("");
      setKeySort("");
      setOrderSort("");
      return;
    }

    if (sortOrder === "") {
      setOrder("DESC");
      setOrderSort("DESC");
    }
    if (sortOrder === "DESC" && option.id !== 3) {
      setOrder("ASC");
      setOrderSort("ASC");
    }
    if (sortOrder === "ASC") {
      setOrder("DESC");
      setOrderSort("DESC");
    }
    setKey(option.key);
    setKeySort(option.key);
    setIsFilter(true);
  };

  /**
   * Returns list of cities from server and sets it to state.
   * Is fired every time if search query or page changes.
   */
  const fetchCities = async () => {
    const response = await employeeService.getCities(
      citiesSearchQuery,
      citiesPage
    );

    if (response.hasError) {
      return toast.error(t(response.errorMessage));
    }

    const remoteOption = { id: 0, name: "Remote" };
    const cities = [remoteOption].concat(response.cities);

    setCities(cities);
    setTotalPages(response.pages);
  };

  /**
   * Refetches cities list from server and sets it to state.
   * If page number increments to a value that is greater than there are total pages
   * function exits.
   * Updates page number & sets it to state.
   */
  const refetchCities = async () => {
    let currentPage = citiesPage;
    let newPage = (currentPage += 1);

    if (newPage > totalPages) return;

    setCitiesPage(newPage);

    const response = await employeeService.getCities(
      citiesSearchQuery,
      newPage
    );

    if (response.hasError) {
      return toast.error(t(response.errorMessage));
    }

    let newCities = [];
    setTimeout(() => {
      newCities = [...cities, ...response.cities];
      setTimeout(() => {
        setCities(newCities);
      }, 100);
    }, 100);
  };

  const handleCategoryChange = id => {
    const newChosenCategoryIds = chosenCategoryIds.includes(id)
      ? chosenCategoryIds.filter(cat => cat !== id)
      : [...chosenCategoryIds, id];
    setChosenCategoryIds(newChosenCategoryIds);
    setIsFilter(true);
  };

  const applyFilters = async (
    chosenCategories = chosenCategoryIds,
    priceRange = chosenPriceRange,
    chosenCities = chosenCityIds,
    chosenBenefitExpirationTypes = chosenBenefitExpirationTypeKeys,
    search = keywordSearch,
    order = sortOrder,
    key = sortKey,
    type = benefitGroupType
  ) => {
    const response = await api.getBenefitGroups({
      chosenCategories,
      page: page,
      priceRange,
      chosenCities,
      chosenBenefitExpirationTypes,
      remoteFilter,
      search,
      order,
      key,
      type
    });

    if (response.hasError) {
      setIsPageLoading(false);
      return;
    }
    if (
      !isEmpty(chosenCategories) ||
      !isEmpty(chosenCities) ||
      !isEmpty(chosenBenefitExpirationTypes) ||
      !isEmpty(priceRange) ||
      !isEmpty(keywordSearch)
    ) {
      setNumberOfProvidersRender(true);
    } else {
      setNumberOfProvidersRender(false);
    }
    applyBenefitGroupsFilters(
      response.groups,
      response.count,
      response.pages,
      benefitGroupType
    );
    setIsPageLoading(false);
  };

  const resetFilters = async () => {
    setCitiesSearchQuery("");
    setChosenCityIds([]);
    setChosenCities([]);
    setRemoteFilter(false);
    setCitiesFilter([]);
    setChosenBenefitExpirationTypes([]);
    setRemoteFilterToStore(false);
    setChosenCategoryIds([]);
    setNumberOfProvidersRender(false);
    handleSelectingSortOption(null);
    setKeywordSearch("");
    setPriceRange([
      transformTokensToRsd(PRICE_MIN),
      transformTokensToRsd(PRICE_MAX)
    ]);

    const response = await api.getBenefitGroups({
      chosenCategories: [],
      chosenCities: [],
      page: page,
      priceRange: new PriceRange(),
      search: "",
      order: "",
      key: "",
      type: benefitGroupType
    });

    if (response.hasError) {
      setIsPageLoading(false);
      return;
    }

    resetBenefitGroupFilters(
      response.groups,
      response.count,
      response.pages,
      benefitGroupType
    );
    setIsPageLoading(false);
  };

  const hideFilters = () => {
    const filters = document.getElementById("filters");
    const filterWrapper = document.getElementById("filterWrapper");
    const body = document.getElementsByTagName("body")[0];

    if (
      filters &&
      filterWrapper &&
      filterWrapper.classList.contains("showWrapper") &&
      filters.classList.contains("showFilters")
    ) {
      filterWrapper.classList.remove("showWrapper");
      filters.classList.remove("showFilters");
      body.style.overflow = "";
      body.style.position = "";
      body.style.width = "";
    }
  };

  /**
   * Handles input change, search of cities.
   * Sets it to state.
   * @param {string} newValue
   * @returns {string}
   */
  const handleInputChange = newValue => {
    setCitiesPage(1);
    setCitiesSearchQuery(newValue);

    return newValue;
  };

  /**
   * Handles select of element from multiselect.
   * Sets selected elements' ids to state.
   * @param {City} options - Selected options, emmited from multiselect.
   * @param {object} action - Action object, emmited from multiselect. Consists of action type, option data object.
   */
  const handleSelect = (options, action) => {
    let selectedCityIds = [];

    if (options && options.length) {
      selectedCityIds = options.map(city => city.id);
    }

    if (action.action === "select-option" && action.option.name === "Remote") {
      setRemoteFilter(true);
      setRemoteFilterToStore(true);
    }

    if (
      action.action === "remove-value" &&
      action.removedValue.name === "Remote"
    ) {
      setRemoteFilter(false);
      setRemoteFilterToStore(false);
    }

    setChosenCityIds(selectedCityIds);
    setCitiesFilter(selectedCityIds);
    setChosenCities(options);
    setIsFilter(true);
  };

  /**
   * Handles select of element from multiselect.
   * Sets selected element's names to state.
   * @param {object} options - Selected options, emitted from multiselect.
   * @param {object} action - Action object, emitted from multiselect. Consists of action type, option data object.
   */
  const handleSelectTypes = (options, action) => {
    let selectedExpirationTypes = [];

    if (options && options.length) {
      selectedExpirationTypes = options.map(type => type.key);
    }
    setChosenBenefitExpirationTypeKeys(selectedExpirationTypes);
    setBenefitExpirationTypesFilter(selectedExpirationTypes);
    setChosenBenefitExpirationTypes(options);
    setIsFilter(true);
  };

  const handleSearch = searchQuery => {
    setKeywordSearch(searchQuery);
    setSearchFilter(searchQuery);
    setIsFilter(true);
  };

  /**
   * Handles range of price change.
   * Sets price range to state.
   * Sets filters price range to state.
   * @param {Array} values - Array of numbers, Array[0] - min value, Array[1] - max value
   */
  const handlePriceChange = values => {
    const isValueInRsd = localStorage.getItem("isValueInRsd");

    setPriceRange(values);

    const transformedValue = isValueInRsd
      ? values.map(value => value / 20)
      : values;

    setFiltersPriceRange(priceRangeOptions, {
      min: transformedValue[0],
      max: transformedValue[1]
    });
    setIsFilter(true);
  };

  useEffect(() => {
    fetchCities();
  }, [citiesPage, citiesSearchQuery]);

  useEffect(() => {
    clearTimeout(timeout.current);
    setIsFilter(false);

    timeout.current = setTimeout(() => {
      if (isInitialStateSet) {
        setIsPageLoading(true);
        applyFilters();
      }
    }, FILTERS_TIMER_IN_MILLISECONDS);

    return () => {
      setIsFilter(false);
      clearTimeout(timeout.current);
    };
  }, [isFilter]);

  const handleResetFilters = () => {
    setIsFilter(false);
    setIsPageLoading(true);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      resetFilters();
    }, FILTERS_TIMER_IN_MILLISECONDS);

    return () => {
      setIsFilter(false);
      clearTimeout(timeout.current);
    };
  };

  useEffect(() => {
    if (isInitialStateSet) {
      handleResetFilters();
    }

    return () => {
      handleResetFilters();
    };
  }, [benefitGroupType]);

  const displayAvailableProviders = () => {
    const objectOfProviders = {
      public: benefitBrowseGroupsCount,
      local: benefitCompanyGroupsCount,
      favorite: 1
    };

    return objectOfProviders[benefitGroupType];
  };

  return (
    <BenefitGroupFiltersStyles id="filterWrapper">
      <div className="filtersContainer" id="filters">
        <Card className="filtersCard">
          {numberOfProvidersRender && (
            <div className="availableProviders">
              <div className="filterTitle">
                {t("available_prov")}: {displayAvailableProviders()}
              </div>
              <TextAndLine />
            </div>
          )}
          <div className="searchWrapper">
            <SearchInput
              fetchData={() => {}}
              setSearch={handleSearch}
              search={keywordSearch}
              setPagginationPage={setPage}
              placeholder={t("Search")}
            />
          </div>
          <h3 className="filterTitle">{t("Price")}</h3>
          <div className="filtersListContainer">
            <PriceFilter
              handlePriceChange={handlePriceChange}
              priceRange={priceRange}
            />
          </div>
          <h3 className="filterTitle">{t("SBG")}</h3>
          <div className="filtersListContainer">
            <SortSelectInput
              name="sorting"
              options={SORT_OPTIONS}
              sortKey={sortKey}
              sortOrder={sortOrder}
              placeholder={t("sort_by")}
              handleChange={option => handleSelectingSortOption(option)}
            />
          </div>
          <h3 className="filterTitle">{t("locations")}</h3>
          <div className="filtersListContainer">
            <LocationsMultiselectInput
              name="cities"
              options={cities}
              selectedValues={chosenCities}
              handleChange={(option, action) => handleSelect(option, action)}
              handleInputChange={handleInputChange}
              shouldHaveFullWidth={true}
              fetchMoreData={refetchCities}
            />
          </div>
          <h3 className="filterTitle">{t("FBT")}</h3>
          <div className="filtersListContainer">
            <TypesMultiselectInput
              name="types"
              options={BENEFIT_EXPIRATION_TYPES}
              selectedValues={chosenBenefitExpirationTypes}
              handleChange={(option, action) =>
                handleSelectTypes(option, action)
              }
              shouldHaveFullWidth={true}
            />
          </div>
          <h3 className="filterTitle">{t("Category")}</h3>
          <div className="filtersListContainer">
            <CategoryFilters
              categories={categories}
              eventProtectClass="eventProtectClass"
              handleChange={handleCategoryChange}
              chosenCategories={chosenCategoryIds}
            />
          </div>
          <div className="filtersButtonsContainer">
            <Button
              margin="0 0 10px 0"
              onClick={() => {
                handleResetFilters();
                hideFilters();
              }}
            >
              {t("reset_filter")}
            </Button>
            <Button
              margin="0 0 10px 0"
              width="120px"
              customClass="cancelFilters"
              onClick={hideFilters}
            >
              {t("close")}
            </Button>
          </div>
        </Card>
      </div>
    </BenefitGroupFiltersStyles>
  );
};

BenefitGroupFilters.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  chosenCategoryIds: PropTypes.arrayOf(PropTypes.number),
  setChosenCategoryIds: PropTypes.func,
  chosenPriceRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  applyBenefitGroupsFilters: PropTypes.func,
  resetBenefitGroupFilters: PropTypes.func,
  setCitiesFilter: PropTypes.func,
  setRemoteFilterToStore: PropTypes.func,
  setBenefitExpirationTypesFilter: PropTypes.func,
  setSearchFilter: PropTypes.func,
  setOrderSort: PropTypes.func,
  setKeySort: PropTypes.func,
  benefitGroupsCount: PropTypes.number,
  priceRangeOptions: PropTypes.array,
  setFiltersPriceRange: PropTypes.func,
  benefitGroupType: PropTypes.string,
  isInitialStateSet: PropTypes.bool,
  setIsPageLoading: PropTypes.func
};

const mapStateToProps = state => {
  return {
    categories: state.employeeBenefitsPage.filters.categories,
    chosenCategoryIds: state.employeeBenefitsPage.filters.chosenCategoryIds,
    chosenPriceRange: state.employeeBenefitsPage.filters.chosenPriceRange,
    benefitBrowseGroupsCount:
      state.employeeBenefitsPage.benefitsBrowse.benefitGroupsCount,
    benefitCompanyGroupsCount:
      state.employeeBenefitsPage.benefitsCompany.benefitGroupsCount,
    priceRangeOptions: state.employeeBenefitsPage.filters.priceRangeOptions,
    benefitGroupType:
      state.employeeBenefitsPage.pageFrontEndStates.benefitGroupType,
    isInitialStateSet:
      state.employeeBenefitsPage.pageFrontEndStates.isInitialStateSet
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setChosenCategoryIds: chosenCategoryIds =>
      dispatch(actionCreators.setChosenCategoryIds(chosenCategoryIds)),
    setCitiesFilter: cityIds =>
      dispatch(actionCreators.setCitiesFilter(cityIds)),
    setRemoteFilterToStore: isRemote =>
      dispatch(actionCreators.setRemoteFilter(isRemote)),
    setBenefitExpirationTypesFilter: expirations =>
      dispatch(actionCreators.setBenefitExpirationTypesFilter(expirations)),
    setSearchFilter: search => dispatch(actionCreators.setSearchFilter(search)),
    setOrderSort: order => dispatch(actionCreators.setOrderSort(order)),
    setKeySort: key => dispatch(actionCreators.setKeySort(key)),
    applyBenefitGroupsFilters: (
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsTotalPages,
      benefitGroupType
    ) =>
      dispatch(
        actionCreators.applyBenefitGroupsFilters(
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupType
        )
      ),
    resetBenefitGroupFilters: (
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsTotalPages,
      benefitGroupType
    ) =>
      dispatch(
        actionCreators.resetBenefitGroupFilters(
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupType
        )
      ),
    setFiltersPriceRange: (updatedPriceRangeOptions, priceRange) =>
      dispatch(
        actionCreators.setFiltersPriceRange(
          updatedPriceRangeOptions,
          priceRange
        )
      ),
    setIsPageLoading: isLoading =>
      dispatch(actionCreators.setIsPageLoading(isLoading))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitGroupFilters);
