import i18next from "i18next";
import { removeSpacesAndHTML } from "../../general.utils";

export const categoryValidations = ({ name }) => {
  const errors = {};

  if (!name.trim()) {
    errors.name = i18next.t("required_field");
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = i18next.t("html_tag_ban");
  }

  return errors;
};
