import { toast } from "react-toastify";
import supabaseClient from "./supabaseClient";
import createSupaBaseStorageFolder from "./createSupaBaseStorageFolder";
import i18next from "i18next";

const sendImageToSupabase = async (fileName, file, companyId) => {
  const folderName = createSupaBaseStorageFolder(companyId);

  try {
    const { error } = await supabaseClient.storage
      .from("refund-upload")
      .upload(`${folderName}_pending_refund/${fileName}`, file);

    if (error) throw new Error(i18next.t(error));
  } catch (error) {
    toast.error(i18next.t(error.message));
  }
};

export default sendImageToSupabase;
