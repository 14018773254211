import i18next from "i18next";

export const validateNotificationForm = ({ subject, message, companies }) => {
  const errors = {};

  if (!subject) {
    errors.subject = i18next.t("required_field");
  } else if (subject.length < 5) {
    errors.subject = i18next.t("subj_min_5_char");
  }

  if (!message) {
    errors.message = i18next.t("required_field");
  } else if (message.length < 5) {
    errors.message = i18next.t("message_min_5_char");
  }

  if (!companies) {
    errors.companies = i18next.t("required_field");
  } else if (companies.length < 1) {
    errors.companies = i18next.t("recipient_min_1_company");
  }

  return errors;
};
