import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// components
import {
  Input,
  InputLabel,
  LocationsMultiselectInput,
  SelectInput
} from "../../../../components";
// relationshipsOptions
import { relationshipOptions } from "./relationshipOptions";
// styles
import DetailsStyles from "./DetailsStyles";
import * as employeeService from "../../../../services/api/hr/employees.service";
import { useTranslation } from "react-i18next";

const Details = ({
  isFormDisplay = false,
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  handleChange
}) => {
  const { t } = useTranslation();
  const [cities, setCities] = useState([]);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  /**
   * Returns list of cities from server and sets it to state.
   * Is fired every time if search query or page changes.
   */
  const fetchCities = async () => {
    const response = await employeeService.getCities(search, page);

    if (response.hasError) {
      return toast.error(t(response.errorMessage));
    }

    setCities(response.cities);
    setTotalPages(response.pages);
  };

  /**
   * Refetches cities list from server and sets it to state.
   * If page number increments to a value that is greater than there are total pages
   * function exits.
   * Updates page number & sets it to state.
   */
  const refetchCities = async () => {
    let currentPage = page;
    let newPage = (currentPage += 1);

    if (newPage > totalPages) return;

    setPage(newPage);

    const response = await employeeService.getCities(search, newPage);

    if (response.hasError) {
      return toast.error(t(response.errorMessage));
    }

    let newCities = [];
    setTimeout(() => {
      newCities = [...cities, ...response.cities];
      setTimeout(() => {
        setCities(newCities);
      }, 100);
    }, 100);
  };

  const handleInputChange = newValue => {
    setPage(1);
    setSearch(newValue);

    return newValue;
  };

  const handleSelect = option => {
    setFieldValue("cities", option);
  };

  useEffect(() => {
    fetchCities();
  }, [page, search]);

  const translatedRelationshipOptions = relationshipOptions.map(
    relationship => {
      return { ...relationship, name: t(relationship.value) };
    }
  );

  return (
    <DetailsStyles haveChildren={values.children}>
      <div className="infoContainer">
        <p className="title">{t("Info")} :</p>
        <div className="inputContainer">
          <div className="labelAndValidation">
            <InputLabel label={t("Position")} margin="0 0 5px 0" />
            {isFormDisplay && (
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.description && touched.description
                  ? errors.description
                  : ""}
              </div>
            )}
          </div>
          {isFormDisplay ? (
            <Input
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
            />
          ) : (
            <p className="textInfo">{values.description}</p>
          )}
        </div>
      </div>
      <div className="familyContainer">
        <p className="title">{t("Family")} :</p>
        <div className="checkboxesContainer">
          <div className="relationshipContainer">
            <InputLabel label={t("Relationship")} margin="0 0 4px 0" />
            {isFormDisplay ? (
              <SelectInput
                name="relationship"
                onBlur={handleBlur}
                shouldHaveFullWidth
                options={translatedRelationshipOptions}
                selectedValues={translatedRelationshipOptions}
                value={t(values.relationship)}
                handleChange={option =>
                  setFieldValue("relationship", option.value)
                }
                customHeight="27px"
              />
            ) : (
              <p className="textInfo">{t(values.relationship)}</p>
            )}
          </div>
        </div>
      </div>
      <div
        className="citiesContainer"
        style={{ minWidth: "130px", width: "230px" }}
      >
        <p className="title">{t("locations")} :</p>
        <div className="inputContainer">
          <div className="labelAndValidation">
            <InputLabel label={t("locations")} margin="0 0 5px 0" />
            {isFormDisplay && (
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.cities ? errors.cities : ""}
              </div>
            )}
          </div>
          {isFormDisplay ? (
            <LocationsMultiselectInput
              name="cities"
              options={cities}
              selectedValues={values.cities}
              onBlur={handleBlur}
              shouldHaveFullWidth
              handleChange={option => handleSelect(option)}
              handleInputChange={handleInputChange}
              fetchMoreData={refetchCities}
              customHeight="27px"
            />
          ) : (
            <LocationsMultiselectInput
              name="cities"
              isDisabled={true}
              options={cities}
              selectedValues={values.cities}
              onBlur={handleBlur}
              handleChange={option => handleSelect(option)}
              handleInputChange={handleInputChange}
              fetchMoreData={refetchCities}
              customHeight="27px"
              shouldHaveFullWidth
            />
          )}
        </div>
      </div>
    </DetailsStyles>
  );
};

Details.propTypes = {
  isFormDisplay: PropTypes.bool,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func
};

export default Details;
