import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// components
import BenefitForm from "../BenefitForm";
// styles
import EditBenefitStyles from "./EditBenefitStyles";
// entity
import { Benefit } from "../../../../../../models/domain";
// services
import { validateBenefitForm } from "../../../../../../services/validations/benefitFormValidation";
import * as actionCreators from "../../../../../../actions/admin/providers.actions";
import * as providersService from "../../../../../../services/api/admin/providers.service";
// context
import { ProvidersContext } from "../../../../../../modules/superAdmin/providers/context/providers.context";
import { useTranslation } from "react-i18next";

const EditBenefit = ({
  providerId,
  benefitGroupId,
  benefitId,
  closeModal,
  editBenefit
}) => {
  const { t } = useTranslation();
  const [benefit, setBenefit] = useState(new Benefit());
  const [disableButton, setDisableButton] = useState(false);

  const handleEditBenefitError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.restrict) {
        toast.error(`${t(data.errors.restrict.keys[0])}`);
      }
      if (data.errors.title) {
        toast.error(`${t(data.errors.title.keys[0])}`);
      }
      if (data.errors.photo) {
        toast.error(`${t(data.errors.photo.keys[0])}`);
      }
      if (data.errors.expiration) {
        toast.error(`${t(data.errors.expiration.keys[0])}`);
      }
      if (data.errors.tokenPrice) {
        toast.error(`${t(data.errors.tokenPrice.keys[0])}`);
      }
      if (data.errors.instalments) {
        toast.error(`${t(data.errors.instalments.keys[0])}`);
      }
      if (data.errors.description) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.description.keys[0])}`
        );
      }
      if (data.errors.summary) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.summary.keys[0])}`
        );
      }
      if (data.errors.benefitGroupId) {
        toast.error(`${t(data.errors.benefitGroupId.keys[0])}`);
      }
      if (data.errors.minimumContractPeriod) {
        toast.error(`${t(data.errors.minimumContractPeriod.keys[0])}`);
      }
      if (data.errors.shortName) {
        toast.error(`${t(data.errors.shortName.keys[0])}`);
      }
    } else {
      toast.error(t(data.error.message));
    }
  };

  const updateBenefit = async values => {
    setDisableButton(true);

    const response = await providersService.editBenefit(
      providerId,
      benefitGroupId,
      benefitId,
      values
    );

    if (response.hasError) {
      setDisableButton(false);
      return handleEditBenefitError(response);
    }

    editBenefit(providerId, benefitGroupId, response);
    toast.success(t("success_edit_benefit"));
    closeModal();
    setTimeout(() => {
      setDisableButton(false);
    }, 550);
  };

  const fetchBenefit = async () => {
    const response = await providersService.getChosenBenefit(benefitId);

    if (response.hasError) {
      return toast.error(response.error.data.error.message);
    }

    setBenefit(response);
  };

  useEffect(() => {
    fetchBenefit();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: benefit,
    validate: validateBenefitForm,
    onSubmit: updateBenefit
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditBenefitStyles>
      <h3 className="title">{t("edit_benefit")}</h3>
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <BenefitForm
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleBlur={handleBlur}
          closeModal={closeModal}
          handleChange={handleChange}
          buttonText="Update"
          isEdit
          disableButton={disableButton}
        />
      </form>
    </EditBenefitStyles>
  );
};

EditBenefit.propTypes = {
  providerId: PropTypes.number,
  benefitGroupId: PropTypes.number,
  benefitId: PropTypes.number,
  closeModal: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    editBenefit: (providerId, benefitGroupId, benefit) =>
      dispatch(actionCreators.editBenefit(providerId, benefitGroupId, benefit))
  };
};

export default connect(undefined, mapDispatchToProps)(EditBenefit);
