import React, { useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
// components
import {
  Button,
  Card,
  Header,
  TextAndLine,
  Table,
  TableHead,
  TableRow
} from "../../../../components";
import BasicInfo from "../BasicInfoSection";
import Details from "../DetailsSection";
import Rank from "../RankSection";
// entity
import { HrEmployeeDetails } from "../../../../models/domain";
// styles
import EmployeeDetailsStyles from "./EmployeeDetailsStyles";
import { override } from "../../../../services/loadingOverride.styles";
// config builders
import employeeActiveBenefitsTableHead from "../userBenefits.tableHeader";
// services
import * as employeesService from "../../../../services/api/hr/employees.service";
// context
import { UserContext } from "../../../../modules/user/context/user.context";

const EmployeeDetails = ({ match }) => {
  const [employee, setEmployee] = useState(new HrEmployeeDetails());
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(true);
  const history = useHistory();

  const fetchEmployee = async () => {
    const response = await employeesService.getChosenCompanyEmployee(
      match.params.id
    );

    if (response.hasError) {
      return toast.error(t(response.error.data.error.message));
    }

    if (isMounted.current && response) {
      setTimeout(() => {
        setEmployee(response);
        setIsLoading(false);
        setTimeout(() => {
          const employeeContentCard = document.getElementById(
            "employeeContentCard"
          );
          if (employeeContentCard && isMounted.current) {
            employeeContentCard.style.opacity = 1;
          }
        }, 100);
      }, 100);
    }
  };

  useEffect(() => {
    fetchEmployee();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <EmployeeDetailsStyles>
      <div className="employeeDetailsContainer">
        <Header headerTitle="Employee Details" />
        {isLoading ? (
          <div
            style={{
              height: "calc(100vh - 180px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BeatLoader
              css={override}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
          </div>
        ) : (
          <div className="employeeDetailsContent">
            <Card
              id="employeeContentCard"
              boxShadow="
								0 2px 4px -1px rgba(0, 0, 0, 0.2),
								0 1px 10px 0 rgba(0, 0, 0, 0.12),
								0 4px 5px 0 rgba(0, 0, 0, 0.14)
							"
            >
              <TextAndLine title="Basic info" />
              <BasicInfo
                values={{
                  firstName: employee.firstName,
                  lastName: employee.lastName,
                  bornDate: employee.bornDate,
                  email: employee.email,
                  enrollDate: employee.enrollDate,
                  contact: employee.contact,
                  team: employee.team,
                  gender: employee.gender,
                  isDisabled: employee.isDisabled
                }}
              />
              <TextAndLine title="Details" />
              <Details
                values={{
                  description: employee.description,
                  relationship: employee.relationship,
                  children: employee.haveChildren,
                  cities: employee.cities
                }}
              />
              <TextAndLine title="Rank" />
              <Rank
                values={{
                  tier: employee.tier,
                  shouldEmployeeHaveHRRole: employee.shouldEmployeeHaveHRRole
                }}
                disableHrCheckbox
              />
              <div style={{ display: "flex" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/employees/edit/${match.params.id}`}
                >
                  <Button margin="0 0 0 0" width="130px" padding="10px 22px">
                    Edit
                  </Button>
                </Link>
                <Button
                  margin="0 0 0 30px"
                  width="130px"
                  onClick={() => history.goBack()}
                  padding="10px 22px"
                  outline
                >
                  Cancel
                </Button>
              </div>
              {employee.userBenefits && employee.userBenefits.length > 0 && (
                <Card
                  margin="60px 0 0 0"
                  boxShadow="
										0 2px 4px -1px rgba(0, 0, 0, 0.2),
										0 1px 10px 0 rgba(0, 0, 0, 0.12),
										0 4px 5px 0 rgba(0, 0, 0, 0.14)
									"
                >
                  <TextAndLine title="Active Benefits :" />
                  <Table>
                    <div className="tableHead">
                      {employeeActiveBenefitsTableHead.map(
                        ({ id, headName, width, justifyContent, margin }) => (
                          <TableHead
                            key={id}
                            tableHeadColName={headName}
                            colWidth={width}
                            justifyContent={justifyContent}
                            margin={margin}
                          />
                        )
                      )}
                    </div>
                    <div className="tableBodyContainer">
                      {employee.userBenefits.map(
                        ({
                          title,
                          expiration,
                          benefitGroup,
                          benefitPrice,
                          benefitId
                        }) => (
                          <TableRow
                            columnOne={title}
                            columnTwo={benefitGroup.provider.name}
                            columnThree={benefitPrice}
                            columnFour={benefitGroup.name}
                            columnFive={expiration}
                            key={benefitId}
                            additionalLinkParams=""
                            isFirstLetter
                            tableStylings={{
                              firstColWidth: "15%",
                              firstColJustifyContent: "flex-start",
                              secondColWidth: "25%",
                              secondColJustifyContent: "center",
                              thirdColWidth: "20%",
                              thirdColJustifyContent: "center",
                              forthColWidth: "25%",
                              forthColJustifyContent: "center",
                              forthColPadding: "0",
                              forthColMargin: "0",
                              fifthColWidth: "15%",
                              fifthColJustifyContent: "center"
                            }}
                          />
                        )
                      )}
                    </div>
                  </Table>
                </Card>
              )}
            </Card>
          </div>
        )}
      </div>
    </EmployeeDetailsStyles>
  );
};

EmployeeDetails.propTypes = {
  match: PropTypes.shape()
};

export default EmployeeDetails;
