import i18next from "i18next";
import validator from "validator";

export const resetPasswordValidation = ({
  email,
  password,
  passwordConfirmation
}) => {
  const errors = {};

  if (!email) {
    errors.email = i18next.t("required_field");
  } else if (!validator.isEmail(email)) {
    errors.email = i18next.t("invalid_email");
  }

  if (!password) {
    errors.password = i18next.t("required_field");
  } else if (password.length < 8) {
    errors.password = i18next("min_8_chars");
  } else if (!password.match(/\d+/g)) {
    errors.password = i18next.t("must_contain_a_number");
  } else if (!password.match(/[a-zA-Z]/)) {
    errors.password = i18next.t("must_contain_letters");
  }

  if (!passwordConfirmation) {
    errors.passwordConfirmation = i18next.t("required_field");
  } else if (passwordConfirmation !== password) {
    errors.passwordConfirmation = i18next.t("no_match");
  }

  return errors;
};
