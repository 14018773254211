import { BrowserAuthError } from "@azure/msal-browser";
import i18next from "i18next";
import { toast } from "react-toastify";

export const handleAzureLoginError = error => {
  if (error instanceof BrowserAuthError) {
    console.error("BrowserAuthError:", error.errorCode, error.errorMessage);
    if (error.errorCode === "popup_window_error") {
      return toast.error(i18next.t("popup_window_error"));
    } else if (error.errorCode === "user_cancelled") {
      return toast.error(i18next.t("user_cancelled"));
    } else if (error.errorCode === "token_renewal_error") {
      return toast.error(i18next.t("token_renewal_error"));
    } else if (error.errorCode === "login_hint_error") {
      return toast.error(i18next.t("login_hint_error"));
    } else if (error.errorCode === "interaction_in_progress") {
      return toast.error(i18next.t("interaction_in_progress"));
    } else {
      return toast.error(i18next.t("browser_auth_error"));
    }
  } else {
    console.error("An unexpected error occurred:", error);
    return toast.error(i18next.t("unexpected_error_message"));
  }
};
