import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";

import * as dashboardService from "../../../../services/api/hr/dashboard.service";

import { Card } from "../../../../components";
import { useTranslation } from "react-i18next";

const SyncStyles = styled.div`
  width: 400px;
  order: 8;

  .syncContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 47px;

    .syncTitleText {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .syncTitle {
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }
  }
`;

const SyncWidget = ({ companyName, setSyncLoading }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    employees: 0,
    sync: 0
  });

  const handleFetchSyncedEmployees = async () => {
    const response = await dashboardService.fetchSyncedEmployees();

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_get_synced_employees")
      );
    }

    setValues(response);
    setSyncLoading(false);
  };

  useEffect(() => {
    handleFetchSyncedEmployees();
  }, []);

  return (
    <SyncStyles>
      <Card>
        <div>
          <div className="titleContainer">
            <h3 className="title">{companyName}</h3>
            <h3 className="title">{t("sync_widget")}</h3>
          </div>
          <div className="syncContainer">
            <div className="syncTitleText" style={{ marginRight: 40 }}>
              <h4 className="syncTitle">{t("sync_title")}</h4>
              <p>{values.sync}</p>
            </div>
            <div className="syncTitleText">
              <h4 className="syncTitle">{t("Employees")}</h4>
              <p>{values.employees}</p>
            </div>
          </div>
        </div>
      </Card>
    </SyncStyles>
  );
};

SyncWidget.propTypes = {
  companyName: PropTypes.string,
  setSyncLoading: PropTypes.func
};

export default SyncWidget;
