import i18next from "i18next";
import { isEmpty, removeSpacesAndHTML } from "../general.utils";
import { convertEditorStateToString } from "../textEditor.utils";

export const validateBenefitForm = ({
  title,
  // photo,
  expiration,
  tokenPrice,
  description,
  vouchers,
  summary,
  quantity,
  instalments,
  usageInstructions
}) => {
  const errors = {};

  if (!title.trim()) {
    errors.title = i18next.t("required_field");
  } else if (title.length > 30) {
    errors.title = i18next.t("name_too_long");
  } else if (!removeSpacesAndHTML(title)) {
    errors.title = i18next.t("html_tag_ban");
  }

  const descriptionString = convertEditorStateToString(description);
  const tagRegex = /<.+?>/g;
  const formattedDescription = descriptionString.replace(tagRegex, "").trim();
  if (formattedDescription.length > 1001) {
    errors.description = i18next.t("too_long");
  }

  if (!summary.trim()) {
    errors.summary = i18next.t("required_field");
  } else if (removeSpacesAndHTML(summary).length < 20) {
    errors.description = i18next.t("too_short");
  } else if (removeSpacesAndHTML(summary).length > 90) {
    errors.description = i18next.t("too_long");
  }

  if (!isEmpty(usageInstructions)) {
    const usageInstructionsString = convertEditorStateToString(
      usageInstructions
    );
    const usageTagRegex = /<.+?>/g;
    const formattedUsageInstructions = usageInstructionsString
      .replace(usageTagRegex, "")
      .trim();
    if (
      !isEmpty(formattedUsageInstructions) &&
      formattedUsageInstructions.length > 1001
    ) {
      errors.description = i18next.t("too_long");
    }
  }

  if (expiration === "") {
    errors.expiration = i18next.t("required_field");
  }

  if (expiration !== "voucher") {
    if (tokenPrice === "" || tokenPrice === null || tokenPrice === undefined) {
      errors.tokenPrice = i18next.t("required_field");
    }
  }

  if (expiration === "voucher") {
    if (vouchers.length === 0) {
      errors.vouchers = i18next.t("add_voucher_please");
    } else {
      vouchers.forEach(voucher => {
        if (voucher.name === "" || voucher.price === "") {
          errors.vouchers = i18next.t("please_fill_all_fields");
        }
      });
    }
  }

  if (!isEmpty(tokenPrice) && tokenPrice <= 0) {
    errors.tokenPrice = i18next.t("minimum_token_value");
  }

  if (
    expiration === "one time" &&
    !isEmpty(instalments) &&
    tokenPrice % instalments !== 0
  ) {
    errors.tokenPrice = i18next.t(
      "token_price_should_be_divisible_by_installments"
    );
  }

  if (!isEmpty(quantity) && quantity <= 0)
    errors.quantity = i18next.t("minimum_token_value");

  return errors;
};
