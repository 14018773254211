import React from "react";
import { toast } from "react-toastify";
import { City } from "../../../../../../models/domain";
import { cityValidations } from "../../../../../../services/validations/superAdmin/city.validation";
import styled from "styled-components";
import { useFormik } from "formik";
import Form from "../BenefitTags/Form";
import { apiService } from "../../../../../../services/api/api.service";
import { useDispatch } from "react-redux";
import { setCities } from "../../../../../../actions/app.actions";
import { useTranslation } from "react-i18next";

const AddCitiesStyles = styled.div`
  padding: 20px;
  width: 400px;

  .title {
    margin-bottom: 20px;
  }
`;

export const AddCity = ({ closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCreateCityError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${t(data.error.message)}: ${t(data.errors.name.keys[0])}`);
      }
    }
  };

  const createCity = async values => {
    try {
      const response = await apiService.post("/cities", {
        name: values.name
      });

      const citiesResponse = await apiService.get(
        "/cities?search=&page=1&limit=200"
      );

      const citiesData = citiesResponse.data?.cities || [];

      dispatch(setCities(citiesData));

      toast.success(t(response.data.message));
      closeModal();
    } catch (error) {
      handleCreateCityError(response);
    }
  };

  const formik = useFormik({
    initialValues: new City(),
    validate: cityValidations,
    onSubmit: createCity
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddCitiesStyles>
      <h2 className="title">{t("add_cities")}</h2>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Form
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          buttonText="Create"
        />
      </form>
    </AddCitiesStyles>
  );
};
