import React, { useCallback, useEffect, useState } from "react";
import { Header, openModalAnim } from "../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUserHistory } from "../../services/api/admin/users.service";
import ActivityStyles from "../SuperAdminPages/Activity/ActivityStyles";
import { EmptyHistory } from "./HistoryList/EmptyHistory";
import { ListOfHistoryItems } from "./HistoryList/ListOfHistoryItems";
import { LoadingHistoryItems } from "./HistoryList/LoadingHistoryItems";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import {
  employeeBenefitsSetInitialState,
  openBenefitGroupModal,
  setBenefitGroupType,
  setIsInitialStateSet,
  setIsSubscribedBenefitGroupsVisible,
  setUsedBenefitsRedux
} from "../../actions/employee/employeeBenefits.actions";
import {
  getAlreadyUsedBenefits,
  getBenefitGroups,
  getCategoriesForEmployeePlatform
} from "../../services/api/employee/employeeBenefits.services";
import { uniqueArrayOfObjects } from "../../services/general.utils";
import { useTranslation } from "react-i18next";

const UserHistory = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [historyList, setHistoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const userId = useSelector(state => state.app.user.id);

  const {
    appliedChosenCategoryIds,
    appliedChosenPriceRange,
    appliedCityIds,
    appliedRemoteFilter
  } = useSelector(state => state.employeeBenefitsPage.filters);

  const fetchUserHistory = async () => {
    if (!userId) return;
    try {
      setIsLoading(true);
      const response = await getUserHistory(userId);
      setHistoryList(response.data.items);
    } catch (error) {
      toast.error(t("failed_to_get_user_history"));
    } finally {
      setIsLoading(false);
      setShowList(true);
    }
  };

  useEffect(() => {
    fetchUserHistory();
  }, [userId]);

  const fetchBenefits = async type => {
    const [benefitGroupsResponse, categoriesResponse] = await Promise.all([
      getBenefitGroups({
        chosenCategories: appliedChosenCategoryIds,
        page: 1,
        priceRange: appliedChosenPriceRange,
        chosenCities: appliedCityIds,
        remoteFilter: appliedRemoteFilter,
        type: type
      }),
      getCategoriesForEmployeePlatform()
    ]);

    if (benefitGroupsResponse.hasError) {
      return toast.error(
        benefitGroupsResponse.errorMessage
          ? t(benefitGroupsResponse.errorMessage)
          : t("failed_to_get_benefit_groups")
      );
    }

    if (categoriesResponse.hasError) {
      return toast.error(
        categoriesResponse.errorMessage
          ? t(categoriesResponse.errorMessage)
          : t("failed_to_get_categories")
      );
    }

    dispatch(
      employeeBenefitsSetInitialState(
        benefitGroupsResponse.groups,
        benefitGroupsResponse.count,
        benefitGroupsResponse.pages,
        1,
        categoriesResponse.categories
      )
    );
    dispatch(setIsInitialStateSet(true));

    setTimeout(() => {
      const editEmployeeContentCard = document.getElementById(
        "benefitGroupList"
      );
      editEmployeeContentCard.style.opacity = 1;
    }, 100);
  };

  const navigateToProperTab = type => {
    history.push("/");
    dispatch(setIsSubscribedBenefitGroupsVisible(false));
    dispatch(setBenefitGroupType(type));
  };

  const setUsedBenefits = payload => {
    dispatch(setUsedBenefitsRedux(payload));
  };

  const fetchAlreadyUsedBenefits = async id => {
    try {
      const response = await getAlreadyUsedBenefits(id);
      setUsedBenefits(uniqueArrayOfObjects(response.benefits));
    } catch (error) {
      toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_get_used_benefits")
      );
    }
  };

  const openModalFunctionality = () => {
    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  const openModal = useCallback(async benefitGroup => {
    navigateToProperTab(benefitGroup.type);

    fetchAlreadyUsedBenefits(benefitGroup.id);

    dispatch(openBenefitGroupModal(benefitGroup));

    fetchBenefits(benefitGroup.type);

    openModalFunctionality();
  }, []);

  const ShownContent = () => {
    if (isLoading) {
      return <LoadingHistoryItems isLoading={isLoading} />;
    }
    if (historyList.length > 0) {
      return (
        <ListOfHistoryItems
          openModal={openModal}
          showList={showList}
          historyList={historyList}
        />
      );
    }
    return <EmptyHistory showList={showList} />;
  };

  return (
    <ActivityStyles>
      <div className="activityContainerHistory">
        <Header headerTitle={t("history")} />
        <div className="activityContent">
          <div className="activityHistoryWrapper">{ShownContent()}</div>
        </div>
      </div>
    </ActivityStyles>
  );
};

export default UserHistory;
