import React, { useRef, useEffect, useState } from "react";
import RefundModalStyles from "./RefundModalStyles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import createSupaBaseStorageFolder from "../../../../utils/supabaseUtils/createSupaBaseStorageFolder";
import supabaseClient from "../../../../utils/supabaseUtils/supabaseClient";
import { LeftModalSection } from "./LeftModalSection";
import { RightModalSection } from "./RightModalSection";
import { ShowRefundStatus } from "./ShowRefundStatus";
import { RefundModalActions } from "./RefundModalActions";
import PdfLogo from "../../../../assets/images/pdf-logo.jpg";
import { useTranslation } from "react-i18next";

export const RefundModal = ({ onClose, handleStatus }) => {
  const { t } = useTranslation();
  const { singleRefund } = useSelector(state => state.refundsReducer);
  const { companyId } = useSelector(state => state.app.user);

  const [image, setImage] = useState("");
  const modalRef = useRef(null);

  const downloadImage = async name => {
    const folderName = createSupaBaseStorageFolder(companyId);

    const folderRefundPending = `${folderName}_pending_refund`;
    const acceptedRefundFolder = folderName;

    try {
      const { data, error } = await supabaseClient.storage
        .from("refund-upload")
        .getPublicUrl(
          `${
            singleRefund.status ? acceptedRefundFolder : folderRefundPending
          }/${name}`
        );

      const url = data.publicUrl;
      setImage(url);

      if (error) throw new Error(error);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  useEffect(() => {
    downloadImage(singleRefund.File.name);
  }, [singleRefund.File.name]);

  useEffect(() => {
    modalRef.current.focus();
  }, []);

  const handleImage = image => {
    if (image.includes(".pdf")) return { logo: PdfLogo, imageSrc: image };

    return { logo: image, imageSrc: image };
  };

  return (
    <RefundModalStyles
      ref={modalRef}
      tabIndex={-1}
      onKeyDown={e => {
        if (e.key === "Escape") onClose();
      }}
    >
      <h2 id="refund-modal-title">{t("Single Refund")}</h2>
      <section role="dialog" aria-labelledby="refund-modal-title">
        <LeftModalSection singleRefund={singleRefund} />
        <RightModalSection image={handleImage(image)} />
      </section>
      <ShowRefundStatus
        handleStatus={handleStatus}
        status={singleRefund.status}
      />
      <RefundModalActions
        id={singleRefund.id}
        fileName={singleRefund.File.name}
        status={singleRefund.status}
      />
    </RefundModalStyles>
  );
};
