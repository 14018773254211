import validator from "validator";
import { removeSpacesAndHTML } from "../../general.utils";
import i18next from "i18next";

export const validateCompanyForm = ({
  name,
  pib,
  address,
  idNumber,
  contact,
  invoiceEmail,
  paymentDeadline,
  photo
}) => {
  const errors = {};

  if (!name.trim()) {
    errors.name = i18next.t("required_field");
  } else if (removeSpacesAndHTML(name).length > 20) {
    errors.name = i18next.t("too_long");
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = i18next.t("html_tag_ban");
  }

  if (!pib) {
    errors.pib = i18next.t("required_field");
  } else if (typeof pib === "number") {
    if (pib.toString().split("").length !== 9) {
      errors.pib = i18next.t("must_have_9_digits");
    }
  }

  if (!address.trim()) {
    errors.address = i18next.t("required_field");
  } else if (!removeSpacesAndHTML(address)) {
    errors.address = i18next.t("html_tag_ban");
  }

  if (!idNumber) {
    errors.idNumber = i18next.t("required_field");
  } else if (typeof idNumber === "string") {
    if (idNumber.length !== 8) {
      errors.idNumber = i18next.t("must_have_8_digits");
    }
  }

  if (!contact) {
    errors.contact = i18next.t("required_field");
  }

  if (!invoiceEmail) {
    errors.invoiceEmail = i18next.t("required_field");
  } else if (!validator.isEmail(invoiceEmail)) {
    errors.invoiceEmail = i18next.t("invalid_email");
  }

  if (!paymentDeadline) {
    errors.paymentDeadline = i18next.t("required_field");
  }

  if (!photo) {
    errors.photo = i18next.t("required_field");
  }
  // else if (photo.type !== 'image/png') errors.photo = 'Only PNG is acceptable'

  return errors;
};
