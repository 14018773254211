import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import swal from "sweetalert";
// components
import {
  Header,
  Card,
  Input,
  InputLabel,
  Button,
  MultiselectInput,
  TextAndLine
} from "../../../components";
// entity
import { Notification } from "./notifications.entity";
// services
import { validateNotificationForm } from "../../../services/validations/notificationFormValidation";
import * as notificationService from "../../../services/api/admin/notification.service";
import * as companiesService from "../../../services/api/admin/companies.service";
import * as actionCreators from "../../../actions/admin/companies.actions";
// styles
import NotificationsStyles from "./NotificationsStyles";
import { useTranslation } from "react-i18next";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertStringToEditorState } from "../../../services/textEditor.utils";

const Notifications = ({ companies, setCompanies }) => {
  const { t } = useTranslation();
  const [serverErrorMsg, setServerErrorMsg] = useState(null);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [totalCompaniesPages, setTotalCompaniesPages] = useState(1);
  // const { user } = useContext(UserContext)

  const getCompanies = async () => {
    const response = await companiesService.getCompanies({
      page: companiesPage
    });

    if (response.hasError) {
      return setServerErrorMsg(response.error.data.error.message);
    }

    setCompanies(response.companies);
    setTotalCompaniesPages(response.pages);
  };

  /**
   * Refetches paginated companies.
   * Fired from on scroll event from select input.
   * Sets new companies to state, along with page number.
   */
  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = (currentPage += 1);

    if (newPage > totalCompaniesPages) return;

    setCompaniesPage(newPage);
    const response = await companiesService.getCompanies({
      page: newPage
    });
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_get_companies")
      );
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companies, ...response.companies];
      setTimeout(() => {
        setCompanies(newCompanies);
      }, 100);
    }, 100);
  };

  const handleSendMail = async (values, { resetForm }) => {
    try {
      const { data } = await notificationService.sendMail(values);
      if (data) {
        toast.success(t(data.message));
        resetForm();
      }
    } catch (error) {
      if (error && error.response) {
        const { data } = error.response;
        if (data) {
          if (data.errors && data.error) {
            if (data.errors.subject) {
              toast.error(
                `${t(data.error.message)}: ${t(data.errors.subject.keys[0])}`
              );
            }
            if (data.errors.message) {
              toast.error(
                `${t(data.error.message)}: ${t(data.errors.message.keys[0])}`
              );
            }
          } else {
            toast.error(t(data.error.message));
          }
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  /**
   * Sends budget reminder emails to all employees.
   * @param {Event} event
   */
  const handleSendBudgetReminders = event => {
    event.preventDefault();
    swal({
      title: "Are you sure?",
      text:
        "Accepting this will send out budget reminder emails to all employees in the database.",
      buttons: true,
      dangerMode: true,
      icon: "warning"
    }).then(async value => {
      if (!value) return;
      const response = await notificationService.sendBudgetRemindersEmails();
      if (response.hasError) {
        return toast.error(
          response.errorMessage
            ? t(response.errorMessage)
            : "Sending emails failed."
        );
      }

      toast.success(t(response.data.message));
    });
  };

  /**
   * Sends usage instructions emails.
   * @param {Event} event
   */
  const handleSendUsageInstructions = event => {
    event.preventDefault();
    swal({
      title: "Are you sure?",
      text:
        "Accepting this will send out usage instructions emails to employees that have selected benefits that have usage instructions.",
      buttons: true,
      dangerMode: true,
      icon: "warning"
    }).then(async value => {
      if (!value) return;
      const response = await notificationService.handleSendUsageInstructions();
      if (response.hasError) {
        return toast.error(
          response.errorMessage
            ? t(response.errorMessage)
            : t("failed_to_send_emails")
        );
      }

      toast.success(t(response.data.message));
    });
  };

  const formik = useFormik({
    initialValues: new Notification(),
    validate: validateNotificationForm,
    onSubmit: handleSendMail
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  // const filteredCompanies = () => {
  //   const chosenIds = [];
  //   values.companies.forEach(company => {
  //     chosenIds.push(company.id);
  //   });
  //   return companies.filter(t => !chosenIds.includes(t.id));
  // };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <NotificationsStyles>
      <div className="notificationsContainer">
        <Header headerTitle={t("Notifications")} />
        <div className="notificationsContent">
          <Card padding="30px">
            <TextAndLine title={t("Reminders & Instructions")} />
            <div className="notificationActionsSection">
              <Button
                onClick={e => handleSendBudgetReminders(e)}
                margin="0"
                padding="10px 25px"
              >
                {t("send_budget_remainders")}
              </Button>
              <Button
                onClick={e => handleSendUsageInstructions(e)}
                margin="0"
                padding="10px 25px"
              >
                {t("send_usage_instructions")}
              </Button>
            </div>
            <TextAndLine title={t("custom_email_notif")} />
            <form
              style={{ marginTop: "20px" }}
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <h3 className="title">{t("send_message_to_other_usr")}</h3>
              <div className="serverErrorMsg">{serverErrorMsg}</div>
              <div className="inputContainer">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <InputLabel margin="0 0 5px 0" label={t("subject")} />
                  <div className="errorMsg">
                    {errors.subject && touched.subject ? errors.subject : ""}
                  </div>
                </div>
                <Input
                  name="subject"
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="descriptionContainer">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: 24
                  }}
                >
                  <InputLabel
                    customCssClass="labelStyles"
                    margin=" 0 0 5px 0"
                    label={t("message")}
                  />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.message && touched.message ? errors.message : ""}
                  </div>
                </div>
                <Editor
                  onBlur={() => {
                    setFieldTouched("message", true);
                  }}
                  editorState={convertStringToEditorState(values.message)}
                  editorStyle={{ fontSize: 14 }}
                  stripPastedStyles={true}
                  onEditorStateChange={editorState =>
                    setFieldValue("message", editorState)
                  }
                  toolbar={{
                    options: [
                      "inline",
                      "fontSize",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "link",
                      "emoji",
                      "remove",
                      "history"
                    ],
                    fontSize: { options: [10, 11, 12, 13, 14, 16, 18] }
                  }}
                />
              </div>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <InputLabel
                    margin="0 0 5px 0"
                    label={t("choose_comp_as_receivers")}
                  />
                  <div className="errorMsg">
                    {errors.companies && touched.companies
                      ? errors.companies
                      : ""}
                  </div>
                </div>
                <MultiselectInput
                  options={companies}
                  selectedValues={values.companies}
                  handleChange={option => setFieldValue("companies", option)}
                  onBlur={setFieldTouched}
                  shouldHaveFullWidth
                  fetchMoreData={refetchCompanies}
                />
              </div>
              <Button type="submit" margin="30px 0 0 0">
                {t("send_message")}
              </Button>
            </form>
          </Card>
        </div>
      </div>
    </NotificationsStyles>
  );
};

const mapStateToProps = state => {
  return {
    companies: state.companies.companies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCompanies: companies => dispatch(actionCreators.setCompanies(companies))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
