/* eslint-disable */
import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// assets
import { BenefitLogoIcon } from "../../assets/Icons";
import Unmasked from "../../assets/images/002-visibility.png";
import Masked from "../../assets/images/003-visibility-1.png";
// components
import { Card, Button } from "../../components";
// styles
import { SetPasswordStyles } from "./SetPassword.styles";
// services
import { setPasswordValidation } from "../../services/validations/publicPages/setPassword.validations";
import * as authService from "../../services/api/auth.service";
import { useTranslation } from "react-i18next";

const SetPassword = () => {
  const { t } = useTranslation();
  const [maskedNewPassword, setMaskedNewPassword] = useState(true);
  const [maskedNewPasswordConfirm, setMaskedNewPasswordConfirm] = useState(
    true
  );
  const location = useLocation();
  const history = useHistory();

  function getUrlParameter(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1])
          .replace("%2B", "+")
          .replace("%40", "@");
  }

  const handleResetPassword = async (values, { resetForm }) => {
    const response = await authService.resetPassword(values);

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? t(response.errorMessage)
          : t("failed_to_reset_password")
      );
    }

    resetForm();
    toast.success(t(response.message));
    history.push("/");
  };

  const formik = useFormik({
    initialValues: {
      email: getUrlParameter("email"),
      password: "",
      passwordConfirmation: "",
      hash: getUrlParameter("hash"),
      termsAndPolicy: false
    },
    validate: setPasswordValidation,
    onSubmit: handleResetPassword
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;
  return (
    <SetPasswordStyles>
      <Card>
        <div className="setPasswordContent">
          <div className="circleLogoContainer">
            <BenefitLogoIcon />
          </div>
          <h2 className="setPasswordTitle">Welcome to Benefiti!</h2>
          <p className="setPasswordText">
            Thank you for confirming your email.
          </p>
          <p className="setPasswordText">
            Please set your password in order to login to the platform!
          </p>

          <form onSubmit={handleSubmit} className="form" autoComplete="off">
            <label className="label" htmlFor="email">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                Email:
                <div className="errorMsg">
                  {errors.email && touched.email ? errors.email : ""}
                </div>
              </div>
              <input
                className="inputs"
                id="email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </label>
            <label className="label" htmlFor="password">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                New Password:
                <div className="errorMsg">
                  {errors.password && touched.password ? errors.password : ""}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  className="inputs"
                  id="password"
                  name="password"
                  type={maskedNewPassword ? "password" : "text"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <img
                  src={maskedNewPassword ? Masked : Unmasked}
                  onClick={() => setMaskedNewPassword(!maskedNewPassword)}
                  alt="masked"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: -30
                  }}
                />
              </div>
            </label>

            <label className="label" htmlFor="passwordConfirmation">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                Confirm Password:
                <div className="errorMsg">
                  {errors.passwordConfirmation && touched.passwordConfirmation
                    ? errors.passwordConfirmation
                    : ""}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  className="inputs"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type={maskedNewPasswordConfirm ? "password" : "text"}
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <img
                  src={maskedNewPasswordConfirm ? Masked : Unmasked}
                  onClick={() =>
                    setMaskedNewPasswordConfirm(!maskedNewPasswordConfirm)
                  }
                  alt="masked"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: -30
                  }}
                />
              </div>
            </label>

            <div className="buttonContainer">
              <div style={{ position: "relative" }}>
                <div className="termsContainer">
                  <input
                    className="termsCheckbox"
                    type="checkbox"
                    name="termsAndPolicy"
                    value={values.termsAndPolicy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <p className="terms">
                    I agree with{" "}
                    <a
                      href="https://benefiti.rs/politikavebsajta.html"
                      style={{ color: "#0000EE" }}
                    >
                      terms and conditions
                    </a>
                    <br />
                    and the{" "}
                    <a
                      href="https://benefiti.rs/obavestenjeoprivatnosti.html"
                      style={{ color: "#0000EE" }}
                    >
                      privacy policy
                    </a>
                  </p>
                </div>
                <div className="errorMsg termsErrors">
                  {errors.termsAndPolicy && touched.termsAndPolicy
                    ? errors.termsAndPolicy
                    : ""}
                </div>
              </div>

              <Button
                type="submit"
                margin="0"
                padding="10px 22px"
                mobileWidth="100%"
                mobileMargin="30px 0"
              >
                Set Password
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </SetPasswordStyles>
  );
};

export default SetPassword;
