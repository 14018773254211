import i18next from "i18next";
import { toast } from "react-toastify";

export const handleAddBonusError = ({ error }) => {
  const { data } = error;
  if (data.errors && data.error) {
    if (data.errors.companyId) {
      toast.error(
        `${i18next.t(data.error.message)}: ${i18next.t(
          data.errors.companyId.keys[0]
        )}`
      );
    }
    if (data.errors.employeeId) {
      toast.error(
        `${i18next.t(data.error.message)}: ${i18next.t(
          data.errors.employeeId.keys[0]
        )}`
      );
    }
    if (data.errors.tokens) {
      toast.error(
        `${i18next.t(data.error.message)}: ${i18next.t(
          data.errors.tokens.keys[0]
        )}`
      );
    }
    if (data.errors.subject) {
      toast.error(
        `${i18next.t(data.error.message)}: ${i18next.t(
          data.errors.subject.keys[0]
        )}`
      );
    }
    if (data.errors.message) {
      toast.error(
        `${i18next.t(data.error.message)}: ${i18next.t(
          data.errors.message.keys[0]
        )}`
      );
    }
  } else {
    toast.error(i18next.t(data.error.message));
  }
};
