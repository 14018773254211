import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { toast } from "react-toastify";
// components
import Form from "./Form";
// entity
import { TierForm } from "../../../models/domain";
// services
import * as actionCreators from "../../../actions/hr/settings.actions";
import * as settingsService from "../../../services/api/hr/settings.service";
import { tierFormValidation } from "../../../services/validations/hr/tierFormValidation";
import { useTranslation } from "react-i18next";

const EditTier = ({ tokenWorth, editTier, selectedTier, closeModal }) => {
  const { t } = useTranslation();
  const handleEditTierError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${t(data.error.message)}: ${t(data.errors.name.keys[0])}`);
      }
      if (data.errors.companyId) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.companyId.keys[0])}`
        );
      }
      if (data.errors.tokens) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.tokens.keys[0])}`
        );
      }
      if (data.errors.description) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.description.keys[0])}`
        );
      }
    } else {
      toast.error(t(data.error.message), {
        autoClose: 10000
      });
    }
  };

  const updateTierSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const response = await settingsService.editTier(selectedTier.id, values);

    if (response.hasError) {
      setSubmitting(false);
      return handleEditTierError(response);
    }

    editTier(selectedTier.id, response);
    toast.success(t("success_tier_edit"));
    setSubmitting(false);
    closeModal();
  };

  const updatedSelectedTier = {
    ...selectedTier,
    currency: selectedTier.tokens * tokenWorth.worth
  };

  const formik = useFormik({
    initialValues: new TierForm(updatedSelectedTier),
    validate: tierFormValidation,
    onSubmit: updateTierSubmit
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur
  } = formik;

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Form
        title="Edit Tier"
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        tokenWorth={tokenWorth}
        disableButton={isSubmitting}
        buttonText="Update"
      />
    </form>
  );
};

EditTier.propTypes = {
  tokenWorth: PropTypes.shape(),
  selectedTier: PropTypes.shape(),
  editTier: PropTypes.func,
  closeModal: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    editTier: (tierId, tier) => dispatch(actionCreators.editTier(tierId, tier))
  };
};

export default connect(undefined, mapDispatchToProps)(EditTier);
