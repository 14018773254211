import React, { useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// components
import BenefitForm from "../BenefitForm";
// entity
import { Benefit } from "../../../../../../models/domain";
// services
import AddBenefitStyles from "./AddBenefitStyles";
// services
import { validateBenefitForm } from "../../../../../../services/validations/benefitFormValidation";
import * as providersService from "../../../../../../services/api/admin/providers.service";
import * as actionCreators from "../../../../../../actions/admin/providers.actions";
import { useTranslation } from "react-i18next";

const AddBenefit = ({ providerId, benefitGroupId, closeModal, addBenefit }) => {
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(false);

  const handleCreateBenefitError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.restrict) {
        toast.error(`${t(data.errors.restrict.keys[0])}`);
      }
      if (data.errors.title) {
        toast.error(`${t(data.errors.title.keys[0])}`);
      }
      if (data.errors.photo) {
        toast.error(`${t(data.errors.photo.keys[0])}`);
      }
      if (data.errors.expiration) {
        toast.error(`${t(data.errors.expiration.keys[0])}`);
      }
      if (data.errors.tokenPrice) {
        toast.error(`${t(data.errors.tokenPrice.keys[0])}`);
      }
      if (data.errors.description) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.description.keys[0])}`
        );
      }
      if (data.errors.summary) {
        toast.error(
          `${t(data.error.message)}: ${t(data.errors.summary.keys[0])}`
        );
      }
      if (data.errors.benefitGroupId) {
        toast.error(`${t(data.errors.benefitGroupId.keys[0])}`);
      }
      if (data.errors.minimumContractPeriod) {
        toast.error(`${t(data.errors.minimumContractPeriod.keys[0])}`);
      }
      if (data.errors.vat) {
        toast.error(`${t(data.errors.vat.keys[0])}`);
      }
      if (data.errors.shortName) {
        toast.error(`${t(data.errors.shortName.keys[0])}`);
      }
      if (data.errors.isDraft) {
        toast.error(`${t(data.errors.isDraft.keys[0])}`);
      }
    } else {
      toast.error(t(data.error.message));
    }
  };

  const createBenefit = async values => {
    setDisableButton(true);

    const response = await providersService.addBenefit(benefitGroupId, values);

    if (response.hasError) {
      setDisableButton(false);
      return handleCreateBenefitError(response);
    }

    addBenefit(providerId, benefitGroupId, response);
    toast.success(t("success_add_benefit"));
    setTimeout(() => {
      setDisableButton(false);
    }, 550);
    closeModal();
  };

  const formik = useFormik({
    initialValues: new Benefit(),
    validate: validateBenefitForm,
    onSubmit: createBenefit
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddBenefitStyles>
      <h3 className="title">{t("add_benefit")}</h3>
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <BenefitForm
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleBlur={handleBlur}
          closeModal={closeModal}
          handleChange={handleChange}
          buttonText="Create"
          setDisableButton={setDisableButton}
          disableButton={disableButton}
        />
      </form>
    </AddBenefitStyles>
  );
};

AddBenefit.propTypes = {
  providerId: PropTypes.number,
  benefitGroupId: PropTypes.number,
  closeModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    addBenefit: (providerId, benefitGroupId, benefit) =>
      dispatch(actionCreators.addBenefit(providerId, benefitGroupId, benefit))
  };
};

export default connect(undefined, mapDispatchToProps)(AddBenefit);
